import {useState, useEffect, useRef} from 'react';
import {getAccount, getSpinList} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";
import { v4 as uuidv4 } from "uuid";

const useSpinList = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [spinList, setSpinList] = useState(null);
    const [loadingSpinList, setLoadingSpinList] = useState(true);
    const [errorSpinList, setErrorSpinList] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchSpinList();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchSpinList()
            }
        }


    }, [webApp?.initData]);

    const fetchSpinList = async () => {

        const {error, data} = await getSpinList( );

        if (error) {
            console.log('ERRROR!', error);
            setErrorSpinList(error);
            setLoadingSpinList(false);

        } else {

            const newSpinList = await Promise.all(data?.awards.map(async (item) => {

                return {
                    id: uuidv4(),
                    text: item
                };
            }));
            console.log(newSpinList, 'newSpinList')

            setSpinList(newSpinList);
            setLoadingSpinList(false);

        }

    };


    return { spinList, loadingSpinList, errorSpinList, fetchSpinList };
};

export default useSpinList;

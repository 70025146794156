export const ReplenishIcon = ({}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={9}
            height={9}
            fill="none"
        >
            <g filter="url(#t)">
                <circle cx={4.5} cy={4.5} r={4.5} fill="#EBEBEB" fillOpacity={0.4} />
            </g>
            <path stroke="#F8FFF3" strokeWidth={0.857} d="M4.5 6V3M2.985 4.5h3.03" />
            <defs>
                <filter
                    id="t"
                    width={12.429}
                    height={12.429}
                    x={-1.714}
                    y={-1.714}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.857} />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2001_727"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2001_727"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={1.367} />
                    <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect2_innerShadow_2001_727" />
                </filter>
            </defs>
        </svg>


    )

}

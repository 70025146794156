export const SpotIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={9}
            height={7}
            fill="none"
        >
            <path
                fill="#fff"
                d="M3.811.982c-.028.004-.149.016-.266.027-.445.04-1.269.209-1.43.292-.089.046-.263.23-.373.399a3.276 3.276 0 0 0-.368.776l-.085.256-.077-.109-.078-.108-.196-.001c-.674-.002-.96.374-.463.61.07.031.147.067.17.076.04.014.037.017-.046.069-.161.1-.29.36-.35.707-.068.388-.065 1.07.008 1.87.02.22.043.493.051.608.02.225.043.289.126.339.045.024.131.028.708.028H1.8l.042-.044c.041-.04.045-.055.045-.222v-.177H6.887v.166c0 .155.003.172.046.222l.046.055h.656c.574 0 .66-.004.705-.028.083-.05.106-.114.126-.339l.051-.608c.139-1.52.03-2.334-.34-2.574-.085-.055-.087-.058-.046-.072.305-.12.45-.254.415-.39-.045-.176-.334-.296-.71-.296l-.196.001-.078.108-.078.11-.076-.237a3.558 3.558 0 0 0-.374-.796c-.114-.168-.286-.353-.374-.399-.15-.076-.82-.222-1.333-.287C5.117.987 3.957.962 3.81.982Zm1.48.406c.353.039.562.07.9.136l.288.057.097.145c.142.215.3.506.406.754.13.298.13.303.078.292a34.46 34.46 0 0 0-1.405-.102 40.274 40.274 0 0 0-2.535.001c-.424.023-1.051.066-1.277.089a1.028 1.028 0 0 1-.148.009c-.011-.01.065-.213.154-.406.1-.222.228-.454.35-.638l.097-.144.287-.057c.448-.086.788-.13 1.317-.17.209-.016 1.148.007 1.391.034ZM1.383 4.377c.476.062.56.078.632.117.167.09.195.257.08.45l-.057.096-.475.005c-.41.003-.482 0-.531-.025a.78.78 0 0 1-.367-.448c-.027-.114.007-.204.092-.242a.892.892 0 0 1 .067-.026c.006 0 .255.032.559.073Zm6.63-.047c.171.071.129.334-.088.552-.168.168-.168.168-.714.163l-.475-.005-.057-.096c-.119-.199-.087-.364.089-.454.08-.039.165-.057.606-.115.615-.08.564-.076.64-.045ZM2.252 5.394a.14.14 0 0 1 .027.168c-.034.062-.114.071-.61.071-.516 0-.546-.005-.594-.09-.021-.04-.023-.055-.002-.105.013-.03.041-.063.06-.07.022-.01.272-.017.557-.017l.52-.001.042.044Zm5.387-.025c.064.03.096.115.062.174-.048.085-.078.09-.594.09-.496 0-.576-.009-.61-.07a.14.14 0 0 1 .027-.169l.043-.044h.514c.344 0 .528.007.558.02Z"
            />
        </svg>

    )

}

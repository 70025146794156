import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/car_market.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {ProfileIcon} from "../../components/icons/ProfileIcon";
import {RatingIcon} from "../../components/icons/RatingIcon";
import {FriendsIcon} from "../../components/icons/FriendsIcon";
import {P2PIcon} from "../../components/icons/P2PIcon";
import {CarDealershipIcon} from "../../components/icons/CarDealershipIcon";
import {GarageIcon} from "../../components/icons/GarageIcon";
import {CarMarketActiveIcon} from "../../components/icons/CarMarketActiveIcon";
import {ExhibitionIcon} from "../../components/icons/ExhibitionIcon";
import {DailyRewardIcon} from "../../components/icons/DailyRewardIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {GrayIcon} from "../../components/icons/GrayIcon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import {useNavigate, Link} from "react-router-dom";


function CarMarketQuickSellPage () {
    const { user } = useTelegram();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [activeStage, setActiveStage] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [showBuyCarModal, setShowBuyCarModal] = useState(false);





    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getHeightClassForFreeCar = () => {
        if (windowHeight <= 580) {
            return 'general_page_car_img1';
        }

    };



    useEffect(() => {
        console.log(user, 'user')
    }, []);


    const redirectToGaragePage = () => {
        window.location.href = '/garage';
    };

    const redirectToProfilePage = () => {
        window.location.href = '/profile';
    };
    const redirectToFriendsPage = () => {
        window.location.href = '/friends';
    };
    const redirectToCarDealerShipPage = () => {
        window.location.href = '/carDealerShip';
    };

    const redirectToRating = () => {
        window.location.href = '/rating';
    };
    const redirectToWalletPage = () => {
        window.location.href = '/wallet';
    };
    const redirectToBalancePage = () => {
        window.location.href = '/balanceNfs';
    };
    const redirectToGamePage = () => {
        window.location.href = '/game';
    };

    const handleButtonClick = (buttonNumber) => {
        if (buttonNumber == 1) {
            window.location.href = '/carMarketQuickSellCar';
        }
        if (buttonNumber == 3) {
            window.location.href = '/carMarketSellCar';
        }
        if (buttonNumber == 2) {
            window.location.href = '/carMarketBuyCar';
        }
        setActiveButton(buttonNumber);
    };
    const navigate = useNavigate();

    const redirectToGusUpPage = () => {
        window.location.href = '/gusUp';
    };
    const redirectToSettingsPage = () => {
        window.location.href = '/settings';
    };
    return (
        <>
            <div className="car_market_page_wrapper" id='car_market_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>

                            <button
                                className="general_page_item_top_child_icon2_wrapper"
                                onClick={() => {
                                    redirectToGusUpPage()
                                }}
                            >
                                <div className='general_page_item_top_child_icon2_wrapper_childs'>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>

                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>

                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>
                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>
                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1 general_page_item_top_child_icon2_child2'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolWhiteIcon/>
                                        </div>
                                        <button className='general_page_item_top_child_replenish_btn'>
                                            <ReplenishIcon/>
                                        </button>
                                    </div>

                                </div>
                            </button>

                        </div>

                    </div>
                    <div className="general_page_item">
                        <div
                            className="general_page_item_logo"
                        >
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <button
                                className="general_page_item_top_child_title_icon_wrapper"
                                onClick={() => {
                                    redirectToBalancePage()
                                }}
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>1 000 000</p>
                            </button>
                            <button
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                                onClick={() => {
                                    redirectToWalletPage()
                                }}
                            >
                                <WalletIcon/>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="general_page_items_wrapper">

                    <div className="general_page_item2" id='general_page_item_left'>

                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToProfilePage()
                            }}
                        >
                            <div className="general_page_item_child_icon">
                                <ProfileIcon/>
                            </div>
                            <p className='general_page_item_child_title'>Profile</p>
                        </button>
                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToRating()
                            }}
                        >
                            <div className="general_page_item_child_icon">
                                <RatingIcon/>
                                {/*<div className="general_page_item_child_icon_child">*/}
                                {/*    <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                            <p className='general_page_item_child_title'>Rating</p>
                        </button>
                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToFriendsPage()
                            }}
                        >
                            <div className="general_page_item_child_icon">
                                <FriendsIcon/>
                            </div>
                            <p className='general_page_item_child_title'>Frens</p>
                        </button>
                        {/*<div className="general_page_item_child">*/}
                        {/*    <div className="general_page_item_child_icon">*/}
                        {/*        /!*<LockIcon/>*!/*/}
                        {/*        <div className="general_page_item_child_icon_child">*/}
                        {/*            <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <p className='general_page_item_child_title'>P2P</p>*/}
                        {/*</div>*/}

                    </div>


                    <div className="general_page_item2" id='general_page_item_right'>
                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToCarDealerShipPage()
                            }}
                        >
                            <p className='general_page_item_child_title'>Car <br/> dealership</p>
                            <div className="general_page_item_child_icon">
                                <CarDealershipIcon/>
                            </div>
                        </button>
                        <button className="general_page_item_child " onClick={() => {redirectToGaragePage()}}>
                            <p className='general_page_item_child_title'>Garage</p>
                            <div className="general_page_item_child_icon">
                                <GarageIcon/>
                            </div>
                        </button>
                        <div className="general_page_item_child">
                            <p className='general_page_item_child_title'>Car <br/> market</p>
                            <div className="general_page_item_child_icon general_page_item_child_garage">
                                <CarMarketActiveIcon/>
                                {/*<div className="general_page_item_child_icon_child">*/}
                                {/*    <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="general_page_item_child">

                            <p className='general_page_item_child_title'>Exhibition</p>
                            <div className="general_page_item_child_icon">
                                {/*<LockIcon/>*/}
                                {/*<div className="general_page_item_child_icon_child">*/}
                                {/*    <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                                {/*</div>*/}
                                <ExhibitionIcon/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="car_market_main_wrapper">
                    <div className="car_market_quick_sell_popup">
                        <div className="car_market_quick_sell_popup_wrapper">
                            <button
                                className='car_market_quick_sell_popup_close_btn'
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                <CloseIcon/>
                            </button>
                            <p className='car_market_quick_sell_popup_title'>Do you want to make a quick sale of a car for 40% of the cost?</p>
                            <p className='car_market_quick_sell_popup_info'>Сan still come up with some detailed description</p>
                            <div className='car_market_quick_sell_popup_buttons_wrapper'>
                                <button className='car_market_quick_sell_popup_button_yes'>yes</button>
                                <button className='car_market_quick_sell_popup_button_no'>no</button>
                            </div>
                        </div>
                    </div>
                    <div className="car_market_main_img">
                        <img src={require('../../assets/images/car_market_auto_img.png')} alt=""/>
                    </div>
                    <div className="car_market_footer">
                        <div className='cars_market_buttons_main_wrapper'>
                            <div className='cars_market_buttons_main_wrapper_child'>
                                <button
                                    className="active_stage1"
                                    disabled={true}
                                >
                                    <p className="cars_market_buttons_main_wrapper_child_item_title">
                                        quick sell
                                    </p>
                                </button>
                                <button
                                    className={`cars_market_buttons_main_wrapper_child_item ${activeButton === 2 ? 'active_stage1' : ''}`}
                                    onClick={() => handleButtonClick(2)}
                                >
                                    <p className="cars_market_buttons_main_wrapper_child_item_title">
                                        Buy a car
                                    </p>
                                </button>
                                <button
                                    className='active_stage4'
                                >
                                    <p className='cars_market_buttons_main_wrapper_child_item_title2'>
                                        sell a car
                                    </p>
                                </button>
                            </div>
                            <div className='cars_market_buttons_main_wrapper_info_box'>
                                <p className="cars_market_buttons_main_wrapper_info">
                                    Buying and selling cars from other players
                                </p>
                            </div>

                        </div>
                        <button
                            className='car_market_footer_back_btn'
                            onClick={() => {
                                window.location.href = '/carMarket';
                            }}
                        >
                            back to home
                        </button>
                    </div>
                </div>



            </div>
        </>

    );
}

export default CarMarketQuickSellPage;


import React from 'react';
import {RoundIcon} from "../icons/RoundsIcon";
import {ModalCloseIcon} from "../icons/ModalCloseIcon";
import {BuyNfsIcon} from "../icons/BuyNfsIcon";
import '../../assets/css/garage.css'
import {useNavigate} from "react-router-dom";
import {useTonConnectModal, useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {useTelegram} from "../../TelegramContext";
import useTranslation from "../../hooks/useTranslation";

const TonConnectModal = ({isActive, onClose}) => {
    const navigate = useNavigate();
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const { user, webApp, triggerVibration } = useTelegram();


    const { open } = useTonConnectModal();
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();

    if (!isActive) {
        return null
    }



    return (
        <div className='buy_nfs_modal'>
            <div className='buy_nfs_modal_wrapper'>
                <button
                    className='buy_nfs_modal_close_btn'
                    onClick={() => {
                        triggerVibration('heavy')
                       navigate('/')
                    }}
                >
                    <ModalCloseIcon/>
                </button>
                <p className='buy_nfs_modal_title'>
                    {translation?.ton_connect_title}
                </p>
                <p className='buy_nfs_modal_info'>
                    {translation?.ton_connect_desc}
                </p>
                <div className='buy_nfs_modal_icon'>
                    <BuyNfsIcon/>
                </div>
                <button
                    className='buy_nfs_modal_btn'
                    onClick={() => {
                        // onClose()
                        triggerVibration('heavy')
                        open()
                    }}
                >
                    <p className='buy_nfs_modal_btn_text'>{translation?.ton_connect_btn}</p>
                </button>


            </div>

        </div>
    );
};

export default TonConnectModal;

import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="13"
            fill="none"
            viewBox="0 0 65 13"
        >
            <path
                fill="#fff"
                d="M27.815.02a760.6 760.6 0 00-2.945 5.852l-2.915 5.831 1.82-.007 1.826-.011 1.81-3.628c.997-1.992 1.832-3.624 1.85-3.624.023 0 .038.037.041.082 0 .048.015.219.038.382.051.441.081.727.24 2.355.03.315.082.816.108 1.113l.09.872c.04.37.136 1.32.203 2.002l.041.446h3.665l2.922-5.842L39.536 0 37.73.008c-.994.008-1.818.022-1.833.037-.015.019-.838 1.647-1.828 3.628-.99 1.977-1.822 3.598-1.848 3.598-.03 0-.051-.056-.055-.13a82.878 82.878 0 00-.16-1.67 29.854 29.854 0 01-.078-.741l-.07-.723c-.019-.164-.06-.572-.093-.91l-.24-2.466a5.698 5.698 0 00-.068-.512l-.026-.103L29.64.008c-.987-.003-1.806 0-1.825.011zM41.06.02c-.018.01-1.342 2.644-2.948 5.849l-2.916 5.834 1.825-.004h1.829l1.075-2.173c.839-1.691 1.095-2.177 1.161-2.203.049-.019.89-.034 1.873-.034h1.788l.371-.3.497-.397 2.415-1.933c.074-.059.174-.144.222-.185l.093-.074h-2.893c-2.407 0-2.893-.011-2.904-.052-.011-.056.6-1.306.69-1.41.052-.059.26-.063 3.36-.063l3.305-.003 1.762-1.41C52.633.687 53.43.042 53.438.027 53.445-.003 41.1-.01 41.06.019zM53.5 1.51c-1.038.831-1.983 1.584-2.095 1.673l-.879.708-.671.542-.111.27c-.2.483-.23.769-.127 1.232.09.408.486.928.865 1.139.408.222.282.215 3.22.215 1.653 0 2.714.015 2.748.037.048.03-.004.152-.308.76l-.364.724H45.474l-1.606 1.283-1.75 1.399-.205.163c-.048.037 1.377.044 7.081.044 6.873.004 7.151 0 7.46-.067a3.672 3.672 0 002.18-1.394c.13-.17.456-.787.99-1.855.728-1.457.802-1.62.839-1.88a1.813 1.813 0 00-1.528-2.07c-.167-.022-1.235-.037-2.816-.037-1.494.004-2.548-.011-2.555-.03-.004-.018.148-.352.345-.742.27-.537.374-.708.438-.727.044-.01 1.546-.022 3.341-.022h3.257l.19-.16c.222-.181.21-.17.6-.478.163-.126.304-.24.315-.256.012-.01.178-.144.371-.296l.427-.338c.104-.089.875-.701 1.294-1.031.193-.152.353-.286.356-.297 0-.011-2.047-.018-4.55-.018h-4.556l-1.891 1.51z"
            ></path>
            <path
                fill="url(#paint0_linear_3781_474)"
                d="M4.034 5.29C-.216 12.7-.163 12.613.1 12.437c.14-.07 1.317-.843 2.617-1.703l2.37-1.546L6.845 6.08l1.773-3.108 2.248-.053c2.459-.053 2.354-.088 1.95.913-.088.193.684-.228 2.037-1.124 1.194-.79 2.247-1.51 2.335-1.598.088-.07-.052-.334-.298-.58l-.457-.456H7.02L4.034 5.29z"
            ></path>
            <path
                fill="url(#paint1_linear_3781_474)"
                d="M14.22 4.517c-3.478 2.336-6.761 4.548-7.288 4.9l-.966.667H13.06l1.685-2.95c1.669-2.95 1.686-2.968 3.776-4.882C20.402.566 20.752.215 20.594.267c-.035 0-2.897 1.914-6.374 4.25z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_3781_474"
                    x1="0"
                    x2="17.214"
                    y1="6.28"
                    y2="6.28"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6E076"></stop>
                    <stop offset="0.5" stopColor="#7EC93F"></stop>
                    <stop offset="1" stopColor="#A6E076"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3781_474"
                    x1="5.966"
                    x2="20.628"
                    y1="5.173"
                    y2="5.173"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6E076"></stop>
                    <stop offset="0.5" stopColor="#7EC93F"></stop>
                    <stop offset="1" stopColor="#A6E076"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default Icon;

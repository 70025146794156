import {useState, useEffect, useRef} from 'react';
import {buySpin, bySpace, getAccount} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useBySpace = () => {

    const { user, triggerVibration, webApp  } = useTelegram();

    const [bySpaceAnswer, setBySpaceAnswer] = useState(null);
    const [loadingBySpace, setLoadingBySpace] = useState(true);
    const [errorBySpace, setErrorBySpace] = useState(null);

    const isMounted = useRef(false);


    const fetchBuySpace = async (amount) => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await bySpace({
            initData:init_data,
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorBySpace(error);
            setLoadingBySpace(false);

        } else {
            setBySpaceAnswer(data)
            setErrorBySpace(null);
            setLoadingBySpace(false);
            console.log(data, 'data')

        }

    };


    return {bySpaceAnswer,  loadingBySpace, errorBySpace, fetchBuySpace };
};

export default useBySpace;

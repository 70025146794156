import React, {useEffect, useState,} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/missions.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import MissionModal from "../../components/MissionModal";
import UpgradeStageModal from "../../components/UpgradeStageModal";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useMissionList from "../../hooks/useMissionList";
import useTranslation from "../../hooks/useTranslation";


function MissionsPage () {
    const { user } = useTelegram();
    const {translation} = useTranslation();

    const { nfsBalance, userData, loading, error } = useUserData();
    const { missionList, missionListLoading, missionListError, fetchGetMissionList, clickMission, clickMissionLoading, clickMissionError, fetchClickMission } = useMissionList();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [showMissionModal, setShowMissionModal] = useState(false);
    const [subscriptionList, setSubscriptionList] = useState([
        {
            id: 1,
            channel_name: 'youtube',
            channel_icon: require('../../assets/images/youtube_img.png'),
            reward: '10.000 NFS',
        },
        {
            id: 2,
            channel_name: 'telegram',
            channel_icon: require('../../assets/images/telegram_img.png'),
            reward: '10.000 NFS',
        },
        {
            id: 3,
            channel_name: 'twitter',
            channel_icon: require('../../assets/images/twitter_img.png'),
            reward: '10.000 NFS',
        },
    ]);



    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        if (clickMission) {
            fetchGetMissionList();
        }
        console.log(user, 'user')
    }, [clickMission]);

    const navigate = useNavigate();

    const openMissionModal = () => {
        setShowMissionModal(true)
    }

    const titleMission = (item) => {
        switch (item?.icon) {
            case '/media/youtube':
                console.log()
                return 'YOUTUBE'
                break
            case '/media/telegram':
                console.log()
                return 'TELEGRAM'
                break
            case '/media/twitter':
                console.log()
                return 'TWITTER'
                break
        }
    }

    const clickToMission = async (item) => {
        await fetchClickMission(item?.id);
        window.location.href = item?.link
    }


    return (
        <>
            <div className="missions_page_wrapper" id='missions_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>

                        </div>

                    </div>
                    <div className="general_page_item">
                        <Link to={'/'} className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="missions_page_main_wrapper">
                   <button
                       className='missions_page_close_btn'
                       onClick={() => {
                           navigate(-1)
                       }}
                   >
                       <CloseIcon/>
                   </button>
                    <div className='missions_page_title_box'>
                        <p className='missions_page_title'>missions</p>
                    </div>
                    <div className='missions_page_main_wrapper_item1_subscriptions_wrapper'>
                        <div className='missions_page_main_wrapper_item1'>
                            <h1 className='missions_page_main_wrapper_item1_title'>{translation?.missions}</h1>
                            <p className='missions_page_main_wrapper_item1_info'>
                                {translation?.text23}
                            </p>
                        </div>
                        <div className='missions_page_subscription_buttons_wrapper'>
                            {missionList?.missions.map((item, index) => {
                                return (
                                    <button
                                        className={`missions_page_subscription_button ${item?.complete ? 'active' : ''}`} key={index}
                                        onClick={() => {
                                            // openMissionModal()
                                            clickToMission(item);
                                        }}
                                    >
                                        <div className='missions_page_subscription_button_icon'>
                                            <img src={`${process.env.REACT_APP_IMG_DOMAIN}/${item?.icon}`} alt=""/>
                                        </div>
                                        <p className="missions_page_subscription_button_name">
                                            {/*{item.channel_name}*/}
                                            {titleMission(item)}
                                        </p>
                                        <div className='missions_page_subscription_button_info_wrapper'>
                                            <p className='missions_page_subscription_button_info_wrapper_title'>{item?.description}</p>
                                            <div className='missions_page_subscription_button_info_wrapper_reward_info_box'>
                                                <p className='missions_page_subscription_button_info_wrapper_reward_info1'>Reward:</p>
                                                <p className='missions_page_subscription_button_info_wrapper_reward_info2'>{item.nfs_reward}</p>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>

                    <Link
                        to={'/'}
                        className='missions_page_back_to_home_btn'
                    >
                        back to home
                    </Link>
                </div>

                <MissionModal
                    isActive={showMissionModal}
                    onClose={() => {
                        setShowMissionModal(false)
                    }}
                />


            </div>
        </>

    );
}

export default MissionsPage;


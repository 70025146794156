import {useState, useEffect, useRef} from 'react';
import {getAccount} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useUserData = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [userData, setUserData] = useState(null);
    const [nfsBalance, setNfsBalance] = useState(null);
    const [activeCarId, setActiveCarId] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchUserData();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchUserData()
            }
        }


    }, [webApp?.initData]);


    function formatLargeNumber(num) {
        // Форматируем миллиарды
        if (num >= 1000000000) {
            return `${(num / 1000000000).toFixed(3)}B`.replace(/\.?0+B$/, 'B');
        }
        // // Форматируем миллионы
        // else if (num >= 1000000) {
        //     return `${(num / 1000000).toFixed(3)}M`.replace(/\.?0+M$/, 'M');
        // }
        // Возвращаем число как есть, если оно меньше миллиона
        return num.toLocaleString();
    }


    const fetchUserData = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await getAccount({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setError(error);
            setLoading(false);

        } else {

            setActiveCarId(data?.car?.id)
            setUserData(data);

            setNfsBalance(formatLargeNumber(data?.nfsBalance));
            setLoading(false);

            console.log(data, 'data')

        }

    };


    return {activeCarId, nfsBalance, userData, loading, error, fetchUserData };
};

export default useUserData;

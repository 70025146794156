import {useState, useEffect, useRef} from 'react';
import {apiSelectCar, buyPackage, buySpin, getAccount} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";
import {useNavigate} from "react-router-dom";

const useSelectCar = () => {
    const navigate = useNavigate();

    const { user, triggerVibration, webApp  } = useTelegram();

    const [selectCarData, setSelectCarData] = useState(null);
    const [loadingSelectCarData, setLoadingSelectCarData] = useState(true);
    const [errorSelectCarData, setErrorSelectCarData] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        // if (process.env.REACT_APP_DEBUG == 'true') {
        //     if (!isMounted.current) {
        //         fetchUserData();
        //         isMounted.current = true;
        //     }
        // } else {
        //
        //     if (webApp?.initData && webApp?.initData != '') {
        //         fetchUserData()
        //     }
        // }


    }, [webApp?.initData]);

    const fetchSelectCar = async (car_id) => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await apiSelectCar({
            initData:init_data,
            car_id:car_id
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorSelectCarData(error);
            setLoadingSelectCarData(false);

        } else {
            setSelectCarData(data)
            setErrorSelectCarData(null);
            setLoadingSelectCarData(false);
        }

    };


    return {selectCarData,  loadingSelectCarData, errorSelectCarData, fetchSelectCar };
};

export default useSelectCar;

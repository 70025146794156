import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/settings.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {ProfileIcon} from "../../components/icons/ProfileIcon";
import {RatingIcon} from "../../components/icons/RatingIcon";
import {FriendsIcon} from "../../components/icons/FriendsIcon";
import {P2PIcon} from "../../components/icons/P2PIcon";
import {CarDealershipIcon} from "../../components/icons/CarDealershipIcon";
import {GarageIcon} from "../../components/icons/GarageIcon";
import {CarMarketIcon} from "../../components/icons/CarMarketIcon";
import {ExhibitionIcon} from "../../components/icons/ExhibitionIcon";
import {DailyRewardIcon} from "../../components/icons/DailyRewardIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseIcon3} from "../../components/icons/CloseIcon3";
import {OnIcon} from "../../components/icons/OnIcon";
import {OnIcon2} from "../../components/icons/OnIcon2";
import {OffIcon} from "../../components/icons/OffIcon";
import {OffIcon2} from "../../components/icons/OffIcon2";
import {SupportIcon} from "../../components/icons/SupportIcon";
import {PresentationIcon} from "../../components/icons/ProjectPresentationIcon";
import Switch from '../../components/Switch/switch';
import {SettingsLockIcon} from "../../components/icons/SettingsLockIcon";
import {Link, useNavigate} from "react-router-dom";
import useUserData from "../../hooks/useUserData";
import LoaderComponent from "../../components/Loader";
import GazAmount from "../../components/GazAmount";
import useSettings from "../../hooks/useSettings";
import {stringToBase64} from "../../utils/format";
import {getChatLink, getGarageInfo, sendPresentation} from "../../utils/userApi";
import {useAudio} from "../../context/AudioContext";
import GetPresntationModal from "../../components/GetPresntationModal";
import PayTypeModal from "../../components/PayTypeModal";
import useTranslation from "../../hooks/useTranslation";



function SettingsPage () {
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const { user, webApp, triggerVibration } = useTelegram();
    const { nfsBalance, userData, loading, error } = useUserData();
    const { settings, loadingSettings, errorSettings, fetchGetSettings, fetchUpdateSettings } = useSettings();
    const { playing, setPlaying, audio } = useAudio();


    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [showCarType, setShowCarType] = useState('free');
    const [carImgUrl, setCarImgUrl] = useState('');
    const [carClass, setCarClass] = useState('');
    const [isSwitchOneOn, setIsSwitchOneOn] = useState(false);
    const [isSwitchTwoOn, setIsSwitchTwoOn] = useState(false);
    const [currentLng, setCurrentLng] = useState(false);

    const [presentationModal, setPresentationModal] = useState(false);

    const handleToggleSwitchOne = () => {
        if(!isSwitchOneOn == false ) {
            audio.pause()
        } else {
            audio.play()
        }

        fetchUpdateSettings(!isSwitchOneOn, isSwitchTwoOn, settings?.language);
        setIsSwitchOneOn(!isSwitchOneOn);
    };

    const handleToggleSwitchTwo = () => {
        if (!isSwitchTwoOn) {
            triggerVibration('heavy', false)
        }
        fetchUpdateSettings(isSwitchOneOn, !isSwitchTwoOn, settings?.language);
        setIsSwitchTwoOn(!isSwitchTwoOn);

    };

    useEffect(() => {
       if (settings) {
           setIsSwitchOneOn(settings?.sound);
           setIsSwitchTwoOn(settings?.vibration);
           setCurrentLng(settings?.language)
           localStorage.setItem('vibration', JSON.stringify(settings?.vibration))

       }
    }, [settings]);


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getHeightClassForFreeCar = () => {
        if (windowHeight <= 580) {
            return 'general_page_car_img1';
        }

    };

    const getHeightClassForRound3Car = () => {
        if (windowHeight >= 550 && windowHeight <= 630) {
            return 'general_page_car_img1';
        } else if (windowHeight <= 550) {
            return 'general_page_car_img2';
        }
    };
    const getHeightClassForRound4Car = () => {
        if (windowHeight >= 500 && windowHeight <= 600) {
            return 'general_page_car_img1';
        } else if (windowHeight <= 500) {
            return 'general_page_car_img2';
        }
    };
    const getHeightClassForRound1Car = () => {
        if (windowHeight >= 550 && windowHeight <= 630) {
            return 'general_page_car_img1';
        } else if (windowHeight <= 550) {
                return 'general_page_car_img2';
        }
    };
    const getHeightClassForRound2Car = () => {
        if (windowHeight <= 570) {
            return 'general_page_car_img1';
        }

    };


    const redirectToProfilePage = () => {
        window.location.href = '/profile';
    };
    const redirectToFriendsPage = () => {
        window.location.href = '/friends';
    };
    const redirectToCarDealerShipPage = () => {
        window.location.href = '/carDealerShip';
    };
    const redirectToInfoAboutProject = () => {
        window.location.href = '/infoAboutProject';
    };
    const redirectToDailyReward = () => {
        window.location.href = '/dailyReward';
    };
    const redirectToRating = () => {
        window.location.href = '/rating';
    };

    const redirectToGamePage = () => {
        window.location.href = '/game';
    };


    const handleOpenChat = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getChatLink({
            initData:init_data
        });

        if (error) {
            console.log('ERRROR!', error);

        } else {
            console.log(data, 'data3333')
            window.location = data?.link;
        }

    };

    const handleSendPresentation = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await sendPresentation({
            initData:init_data
        });

        if (error) {
            console.log('ERRROR!', error);

        } else {

            console.log(data, 'data3333')
        }

    };


    const navigate = useNavigate();

    return (

        <>
            {( loading || loadingSettings ) && (
                <LoaderComponent/>
            )}

            <div className="general_page_wrapper settings_wrapper" id='general_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <div className="general_page_item_top_child_icon" id='general_page_item_top_child_icon1'>
                                <SettingsIcon/>
                            </div>
                            <GazAmount
                                userData={userData}
                            />

                        </div>

                    </div>

                    <div className="general_page_item">
                        <Link

                            onClick={()=>{
                                triggerVibration('heavy');
                            }}
                            to={'/'}
                            className="general_page_item_logo"
                        >
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>
                    </div>

                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy');
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy');
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="general_page_items_wrapper">

                    <div className="general_page_item2" id='general_page_item_left'>

                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToProfilePage()
                            }}
                        >
                            <div className="general_page_item_child_icon">
                                <ProfileIcon/>
                            </div>
                            <p className='general_page_item_child_title'>Profile</p>
                        </button>
                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToRating()
                            }}
                        >
                            <div className="general_page_item_child_icon">
                                <RatingIcon/>
                                {/*<div className="general_page_item_child_icon_child">*/}
                                {/*    <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                            <p className='general_page_item_child_title'>Rating</p>
                        </button>
                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToFriendsPage()
                            }}
                        >
                            <div className="general_page_item_child_icon">
                                <FriendsIcon/>
                            </div>
                            <p className='general_page_item_child_title'>Frens</p>
                        </button>
                        <div className="general_page_item_child">
                            <div className="general_page_item_child_icon">
                                {/*<LockIcon/>*/}
                                <div className="general_page_item_child_icon_child">
                                    <img src={require('../../assets/images/lock_img.png')} alt=""/>
                                </div>
                            </div>
                            <p className='general_page_item_child_title'>P2P</p>
                        </div>
                        <div className='general_page_item_help_btn_wrapper'>
                            <button
                                className='general_page_item_help_btn'
                                onClick={() => {
                                    redirectToInfoAboutProject()
                                }}
                            >
                                <span className='general_page_item_help_btn_text'>H</span>
                                <span className='general_page_item_help_btn_text'>E</span>
                                <span className='general_page_item_help_btn_text'>L</span>
                                <span className='general_page_item_help_btn_text'>P</span>

                            </button>
                        </div>

                    </div>


                    <div className="general_page_item2" id='general_page_item_right'>
                        <button
                            className="general_page_item_child"
                            onClick={() => {
                                redirectToCarDealerShipPage()
                            }}
                        >
                            <p className='general_page_item_child_title'>Car <br/> dealership</p>
                            <div className="general_page_item_child_icon">
                                <CarDealershipIcon/>
                            </div>
                        </button>
                        <Link to={'/garage'} className="general_page_item_child ">
                            <p className='general_page_item_child_title'>Garage</p>
                            <div className="general_page_item_child_icon">
                                <GarageIcon/>
                            </div>
                        </Link>
                        <Link
                            to={'/carMarket'}
                            className="general_page_item_child"
                        >
                            <p className='general_page_item_child_title'>Car <br/> market</p>
                            <div className="general_page_item_child_icon">
                                <CarMarketIcon/>
                                {/*<div className="general_page_item_child_icon_child">*/}
                                {/*    <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                        </Link>
                        <Link
                            to={'/carExhibition'}
                            className="general_page_item_child"

                        >
                            <p className='general_page_item_child_title'>Exhibition</p>
                            <div className="general_page_item_child_icon">
                                <ExhibitionIcon/>
                                {/*<LockIcon/>*/}
                                {/*<div className="general_page_item_child_icon_child">*/}
                                {/*    <img src={require('../../assets/images/lock_img.png')} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                        </Link>
                        <div className="general_page_item_child_wheel_icon_wrapper">
                            <Link
                                to={'/roulette'}
                                className='general_page_item_child_wheel_icon'

                            >
                                <img src={require('../../assets/images/wheel_icon3.png')} alt=""/>
                            </Link>
                        </div>
                        <Link
                            to={'/dailyReward'}
                            className='general_page_item_child_daily_reward_icon'
                        >
                            <DailyRewardIcon/>
                        </Link>

                    </div>
                </div>

                <div className={
                    carClass === 'general_page_car_img_free'
                        ? `${carClass} ${getHeightClassForFreeCar()}`
                        : carClass === 'general_page_car_img_round1'
                            ? `${carClass} ${getHeightClassForRound1Car()}`
                            : carClass === 'general_page_car_img_round2'
                                ? `${carClass} ${getHeightClassForRound2Car()}`
                            : carClass === 'general_page_car_img_round3'
                                ? `${carClass} ${getHeightClassForRound3Car()}`
                            : carClass === 'general_page_car_img_round4'
                                ? `${carClass} ${getHeightClassForRound4Car()}`
                            : ''
                }>
                    <img src={carImgUrl} alt=""/>
                </div>

                <div className='general_page_footer'>
                    <Link
                        to={'/game'}
                        className='general_page_footer_btn'
                        onClick={() => {
                            redirectToGamePage()
                        }}
                    >
                        Play
                        {/*<img src={require('../../assets/images/play_button.png')} alt=""/>*/}
                    </Link>
                </div>
                <div className='settings_main_wrapper_parent'>
                    <div className="settings_main_wrapper">
                        <button
                            className='settings_close_btn'
                            onClick={() => {
                                navigate('/')
                            }}
                        >
                            <CloseIcon3/>
                        </button>
                        <div className='switch_items_main_wrapper'>
                            <div className='switch_item'>
                                <Switch translation={translation} isOn={isSwitchOneOn} handleToggle={handleToggleSwitchOne} OnIcon={OnIcon} OffIcon={OffIcon2} />
                            </div>
                            <div className='switch_item'>
                                <Switch translation={translation} isOn={isSwitchTwoOn} handleToggle={handleToggleSwitchTwo} OnIcon={OnIcon2} OffIcon={OffIcon} />
                            </div>

                        </div>
                        <div className='setting_links_wrapper'>
                            <div onClick={handleOpenChat} className='setting_support_link'>
                                <p className='setting_support_link_title'>{translation?.support}</p>
                                <div className='setting_support_link_icon'>
                                    <SupportIcon/>
                                </div>
                            </div>
                            <div
                                onClick={()=>{
                                    handleSendPresentation();
                                    setPresentationModal(true)
                                }}
                                className='setting_presentation_link'
                            >
                                <p className='setting_presentation_link_title'>
                                    {translation?.project_presentation}
                                </p>
                                <div className='setting_presentation_link_icon'>
                                    <PresentationIcon/>
                                </div>
                            </div>
                        </div>
                        <div className="settings_languages_wrapper">
                            <div className="settings_languages_title_box">
                                <p className='settings_languages_title'>{translation?.language}</p>
                            </div>
                            <button
                                onClick={()=>{
                                    fetchUpdateSettings(isSwitchOneOn, isSwitchTwoOn, 'ENG');
                                    handleLanguageChange('ENG')
                                }}
                                className={settings?.language == "ENG" ? `settings_language_select_btn` : `settings_language`}
                            >
                                {/*{settings?.language}*/}
                                ENG
                            </button>
                            <button
                                onClick={()=>{
                                    fetchUpdateSettings(isSwitchOneOn, isSwitchTwoOn, 'RU');
                                    handleLanguageChange('RU')
                                }}
                                className={settings?.language == "RU" ? `settings_language_select_btn` : `settings_language`}
                            >
                                RU
                            </button>
                        </div>

                    </div>

                </div>


            </div>

            {presentationModal &&
                <GetPresntationModal
                    isActive={true}
                    onClose={() => {
                        setPresentationModal(false)
                    }}
                />
            }
            {/*<GetPresntationModal/>*/}
        </>

    );
}

export default SettingsPage;


import {useState, useEffect, useRef} from 'react';
import {getAccount, getSpinList, getVictoriousSpin} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useVictoriousSpin = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [victoriousSpin, setVictoriousSpin] = useState(null);
    const [loadingVictoriousSpin, setLoadingVictoriousSpin] = useState(true);
    const [errorVictoriousSpin, setErrorVictoriousSpin] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                // fetchVictoriousSpin();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                // fetchVictoriousSpin()
            }
        }


    }, [webApp?.initData]);

    const fetchVictoriousSpin = async () => {
        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        const {error, data} = await getVictoriousSpin({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setErrorVictoriousSpin(error);
            setLoadingVictoriousSpin(false);

        } else {

            setVictoriousSpin(data);
            setLoadingVictoriousSpin(false);
            console.log(data, 'fetchVictoriousSpin')
        }

    };


    return {victoriousSpin , loadingVictoriousSpin, errorVictoriousSpin, fetchVictoriousSpin };
};

export default useVictoriousSpin;

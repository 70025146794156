import React, {useEffect, useState,} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/info_about_project.css'
import {ModalCloseIcon} from "../../components/icons/ModalCloseIcon";
import {InfoProfileIcon} from "../../components/icons/InfoProfileIcon";
import {InfoRatingIcon} from "../../components/icons/InfoRatingIcon";
import {InfoFrensIcon} from "../../components/icons/InfoFrensIcon";
import {InfoSettingsIcon} from "../../components/icons/InfoSettingsIcon";
import {InfoCarDealerShipIcon} from "../../components/icons/InfoCarDealerShipIcon";
import {InfoGarageIcon} from "../../components/icons/InfoGarageIcon";
import {InfoCarMarketIcon} from "../../components/icons/InfoCarMarketIcon";
import {InfoExhibitionIcon} from "../../components/icons/InfoExhibitionIcon";
import {stringToBase64} from "../../utils/format";
import {sendPresentation} from "../../utils/userApi";
import useTranslation from "../../hooks/useTranslation";
import GetPresntationModal from "../../components/GetPresntationModal";


function InfoAboutProjectPage () {
    const { user, webApp } = useTelegram();
    const {translation} = useTranslation();
    const [presentationModal, setPresentationModal] = useState(false);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [infoList, setInfoList] = useState([
        {
            id: 1,
            icon: <InfoProfileIcon/>,
            name: 'Profile',
            info: 'You can go to the player\'s profile, where they can view statistics on their races, how much money they have in their TON and NFS accounts, how much they have invested, how many races they have had, how many of them they won, and their win percentage.\n' +
                '- They can also go to the Wheel of Fortune here, win for inviting friends or buying spins: tokens, 3 lots with different quantities and different probabilities of winning; Stage 1-4 for upgrades; 1 out of 10 exclusive cars (one each), available to win only in the wheel. If they invite 100 players at once, they simply see the result after the spin, how much they won in total, so they don\'t have to spin 100 times, and at their discretion, they can click to spin the wheel or use all chances. They can buy a spin for 0.1 TON\n' +
                '- Go to P2P to buy/sell tokens from other players.',
        },
        {
            id: 2,
            icon: <InfoRatingIcon/>,
            name: 'Rating',
            info: 'Pressing the Ranking button opens a list of the top 100 participants with their balances, number of races, and win percentage. You can go to the player\'s profile',
        },
        {
            id: 3,
            icon: <InfoFrensIcon/>,
            name: 'FRENS',
            info: 'When you go to this section, you\'ll see the player\'s Photo, which is pulled from Telegram (you can click and change it directly in the game); Name (you can also change it); number of invited players, how much they have earned from the Partner Network; distribution of players across the lines (5 are displayed, but you can expand to 15); earnings percentage from each line; a "More" button, which opens a Pop-Up window with a detailed description of what bonuses are given for the number of invitations, earnings from top-ups, income from income, leadership bonuses, and also bonuses for the Wheel of Fortune'
        },
        {
            id: 4,
            icon: <InfoSettingsIcon/>,
            name: 'Settings',
            info: 'Above Profile - expands sections on the main page'
        },
        {
            id: 5,
            icon: <InfoCarDealerShipIcon/>,
            name: 'Car dealership',
            info: 'The in-game store where players buy cars at pre-sale prices, like from a showroom\n'
        },
        {
            id: 6,
            icon: <InfoGarageIcon/>,
            name: 'Garage',
            info: 'Going to this section, players can choose a car they have in stock, buy another slot for a new car, buy Stages to improve the car\'s performance, with visual changes to the exterior'
        },
        {
            id: 7,
            icon: <InfoCarMarketIcon/>,
            name: 'Car market',
            info: 'This is the car market, where players can buy their desired car from other players. By clicking the "Buy a car" button, the player is presented with a list of all cars for sale, they choose the desired model and then see a list of orders, starting with the floor. Similarly with the "Sell a car" tab. Quick sell - quick sale of a car for 40% of its value, the car is credited to the main game account (what should we call it?)'
        },
        {
            id: 8,
            icon: <InfoExhibitionIcon/>,
            name: 'Exhibition',
            info: 'This section helps players earn passive income by putting their Stage 4-level car on display. They lock the sports car for the chosen time, and depending on the time, they receive a certain percentage of NFS tokens'
        },

    ]);



    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const navigate = useNavigate();

    const handleSendPresentation = async () => {
        setPresentationModal(true)

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await sendPresentation({
            initData:init_data
        });

        if (error) {
            console.log('ERRROR!', error);

        } else {
            console.log(data, 'data3333')
        }

    };


    const printName = (name) => {
        switch (name) {
            case 'Profile':
                return translation?.profile
                break
            case 'Rating':
                return translation?.rating
                break
            case 'FRENS':
                return translation?.frens
                break
            case 'Settings':
                return translation?.settings
                break

            case 'Car dealership':
                return translation?.car_dealership
                break

            case 'Garage':
                return translation?.garage
                break
            case 'Car market':
                return translation?.car_market
                break
            case 'Exhibition':
                return translation?.exhibition
                break

        }
    }
    const printInfo = (name) => {
        switch (name) {
            case 'Profile':
                return translation?.info_about_profile_text
                break
            case 'Rating':
                return translation?.info_about_reyting_text
                break
            case 'FRENS':
                return translation?.info_about_frens_text
                break
            case 'Settings':
                return translation?.info_about_settings_text
                break

            case 'Car dealership':
                return translation?.info_about_diller_text
                break

            case 'Garage':
                return translation?.info_about_garage_text
                break
            case 'Car market':
                return translation?.info_about_autorinok_text
                break
            case 'Exhibition':
                return translation?.info_about_expo_text
                break

        }
    }

    return (
        <>
            <div className="info_about_project_general_page_wrapper" id='info_about_project' style={{overflow: 'scroll'}}>
                <div className='info_about_project_main' style={{overflow: 'scroll'}}>

                    <div className="info_about_project_main_img_box">
                        <img src={require('../../assets/images/info_img1.png')} alt=""/>
                    </div>
                    <div className='info_about_project_items_wrapper'>
                        {infoList.map((item, index) => {
                            return (
                                <div key={index} className='info_about_project_item'>
                                    <div className="info_about_project_item_icon_title_wrapper">
                                        <div className="info_about_project_item_icon">
                                            {item.icon}
                                        </div>
                                        <p className="info_about_project_item_title">
                                            {printName(item.name)}
                                            {/*{item.name}*/}
                                        </p>
                                    </div>
                                    <p className="info_about_project_item_info">
                                        {/*{item.info}*/}

                                        {translation &&
                                            <>{printInfo(item?.name)}</>
                                        }

                                    </p>
                                </div>
                            )
                        })}
                    </div>
                    <div className='info_about_project_info_texts_wrapper'>
                        <p className='info_about_project_info1'>
                            {translation?.info_about_text1}
                            {/*PLAY and <span>RACE, DRIFT, RING RACE, OFF-ROAD, ONLINE</span> - these are different types of races, only when you press PLAY, you choose the type of race, then a timer with the time to find an opponent appears, after the selection, a window appears with the race, where two players are opposite each other, you are given a choice, either continue searching or start the race. This window also shows how much gasoline the player has, each race uses 1 unit of gasoline, 1 unit recharges in 1 hour, a total of 5 hours, you can refuel for 0.5 TON*/}
                        </p>
                        <p className='info_about_project_info2'>
                            {/*The races differ in that there is a regular race, and there are paid modes that players unlock for TON, the probability of winning depends on the power of the car and the characteristics depending on what race is needed,*/}
                            {translation?.info_about_text2}

                        </p>
                    </div>
                    <div className='info_about_project_buttons_wrapper'>
                        {/*<button*/}
                        {/*    onClick={() => {*/}
                        {/*        window.location.href = 'https://t.me/nfs_ton_support'*/}
                        {/*    }}*/}
                        {/*    className='info_about_project_button'*/}
                        {/*>*/}
                        {/*    <img src={require('../../assets/images/support_btn.png')} alt=""/>*/}
                        {/*</button>*/}
                        {/*<button onClick={handleSendPresentation} className='info_about_project_button'>*/}
                        {/*    <img src={require('../../assets/images/project_presentation_btn.png')} alt=""/>*/}
                        {/*</button>*/}
                        {/*<button onClick={() => {*/}
                        {/*    navigate(-1)*/}
                        {/*}}*/}
                        {/*        className='info_about_project_button'*/}
                        {/*>*/}
                        {/*    <img src={require('../../assets/images/back_to_play_btn.png')} alt=""/>*/}
                        {/*</button>*/}



                        <button
                            onClick={() => {
                                window.location.href = 'https://t.me/nfs_ton_support'
                            }}
                            className='custom-btn1'
                        >
                            {translation?.support}
                        </button>


                        <button
                            onClick={handleSendPresentation}
                            className='custom-btn2'
                        >
                            {translation?.project_presentation}
                        </button>


                        <button onClick={()=> { navigate(-1)}} className='custom-btn3'>
                            {translation?.back_to_home}
                        </button>

                    </div>
                </div>
            </div>

            {presentationModal &&
                <GetPresntationModal
                    isActive={true}
                    onClose={() => {
                        setPresentationModal(false)
                    }}
                />
            }
        </>

    );
}

export default InfoAboutProjectPage;


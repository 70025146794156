import React from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import PresPdf from "../../assets/svg/presSvg";
import useTranslation from "../../hooks/useTranslation";

const GetPresntationModal = ({isActive, onClose}) => {

    const { user, webApp, triggerVibration } = useTelegram();
    const {translation} = useTranslation();

    if (!isActive) {
        return null
    }

    return (
        <div className='car_dealership_modal' style={{position:'fixed'}}>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        onClose()
                    }}
                >
                    <ModalCloseIcon3/>
                </button>

                <p className='car_dealership_modal_title NoGasModal download-presentation'>
                    {translation?.text13}
                    <span style={{color:'#0088CC'}}> {translation?.telegram_chat}</span>
                </p>

                <p className={'no-gas-text download-presentation'}>
                    {translation?.text14}
                </p>

                <div style={{display:'flex', justifyContent: 'center', marginBottom:30}}>
                    <PresPdf/>
                </div>
                <div className='car_dealership_modal_buttons_wrapper'>


                    <button
                        style={{width: '100%'}}
                        onClick={() => {

                        }}
                        className='car_dealership_modal_apply_button'
                    >

                        <div
                            onClick={() => {
                                triggerVibration('heavy')
                                onClose()
                            }}
                            className='car_dealership_modal_apply_button_child'>
                            <p className='car_dealership_modal_apply_button_text download-presentation'>
                                {translation?.OKAY}
                            </p>
                        </div>

                    </button>
                </div>
            </div>

        </div>

    );
};

export default GetPresntationModal;

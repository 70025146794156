import {useState, useEffect, useRef} from 'react';
import {
    buyPackage,
    buySpin,
    deleteP2pOrder,
    getAccount, getOrderInfo, getP2pHistory,
    getP2pOrdersAll,
    getP2pOrdersMy,
    getTransactionData, p2pValidate, sellOrderApi, unblockTransaction
} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";
import {useNavigate} from "react-router-dom";
import {useTonConnectUI} from "@tonconnect/ui-react";

const useP2POrders = () => {
    const navigate = useNavigate();

    const { user, triggerVibration, webApp  } = useTelegram();

    const [p2pMainData, setP2pMainData] = useState(null);

    const [allOrders, setAllOrders] = useState(null);
    const [loadingAllOrders, setLoadingAllOrders] = useState(true);
    const [errorAllOrders, setErrorAllOrders] = useState(null);

    const [myOrders, setMyOrders] = useState(null);
    const [loadingMyOrders, setLoadingMyOrders] = useState(false);
    const [errorMyOrders, setErrorMyOrders] = useState(null);

    const [deleteMyOrder, setDeleteMyOrder] = useState(null);
    const [loadingDeleteMyOrder, setLoadingDeleteMyOrder] = useState(false);
    const [errorDeleteMyOrder, setErrorDeleteMyOrders] = useState(null);

    const [transactionData, setTransactionData] = useState(null);
    const [loadingTransactionData, setLoadingTransactionData] = useState(false);
    const [errorTransactionData, setErrorTransactionData] = useState(null);

    const [sellOrderData, setSellOrderData] = useState(null);
    const [loadingSellOrderData, setLoadingSellOrderData] = useState(false);
    const [errorSellOrderData, setErrorSellOrderData] = useState(null);

    const [orderInfo, setOrderInfo] = useState(null);
    const [loadingOrderInfo, setLoadingOrderInfo] = useState(false);
    const [errorOrderInfo, setErrorOrderInfo] = useState(null);

    const [p2PHistory, setP2PHistory] = useState(null);
    const [loadingP2PHistory, setLoadingP2PHistory] = useState(false);
    const [errorP2PHistory, setErrorP2PHistory] = useState(null);

    const [p2PValidate, setP2PValidate] = useState(null);
    const [loadingP2PValidate, setLoadingP2PValidate] = useState(false);
    const [errorP2PValidate, setErrorP2PValidate] = useState(null);

    const [unblockData, setUnblockData] = useState(null);
    const [loadingUnblockData, setLoadingUnblockData] = useState(false);
    const [errorUnblockData, setErrorUnblockData] = useState(null);

    const isMounted = useRef(false);
    const [tonConnectUI] = useTonConnectUI();

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchGetAllOrders();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchGetAllOrders()
            }
        }

    }, [webApp?.initData]);

    const fetchGetAllOrders = async (sortBy = 'asc', offset= 0, limit = 100) => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getP2pOrdersAll({
            initData:init_data,
            sortBy:sortBy,
            offset:offset,
            limit:limit
        });

        console.log(data?.orders, 'ffff')
        if (error) {
            console.log('ERRROR!', error);
            setErrorAllOrders(error);
            setLoadingAllOrders(false);

        } else {
            setP2pMainData(data);
            setAllOrders(data?.orders)
            setErrorAllOrders(null);
            setLoadingAllOrders(false);
            console.log(data, 'fetchGetAllOrders')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;


        }

    };

    const fetchGetMyOrders = async (sortBy = 'asc', offset= 1, limit = 100) => {

        setLoadingMyOrders(true);
        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getP2pOrdersMy({
            initData:init_data,
            sortBy:sortBy,
            offset:offset,
            limit:limit
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorMyOrders(error);
            setLoadingMyOrders(false);

        } else {
            setP2pMainData(data);

            setMyOrders(data?.orders);
            setErrorMyOrders(null);
            setLoadingMyOrders(false);
            console.log(data, 'fetchBuyPackage')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;

        }

    };


    const fetchDeleteOrder =  async (orderId) => {
        // deleteP2pOrder

        setLoadingDeleteMyOrder(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await deleteP2pOrder({
            initData:init_data,
            orderId:orderId,
        });

        if (error) {

            console.log('ERRROR!', error);
             setErrorDeleteMyOrders(error);
            setLoadingDeleteMyOrder(false);

        } else {
            setDeleteMyOrder(data);
            setErrorDeleteMyOrders(null);
            setLoadingDeleteMyOrder(false);

            console.log(data, 'fetchDeleteOrder')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;

        }
    }


    const fetchGetTransaction =  async (orderId) => {

        setLoadingTransactionData(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getTransactionData({
            initData:init_data,
            orderId:orderId,
        });

        if (error) {

            console.log('ERRROR!', error);
            setErrorTransactionData(error);
            setLoadingTransactionData(false);

        } else {
            setTransactionData(data);
            setErrorTransactionData(null);
            setLoadingTransactionData(false);

            console.log(data, 'fetchGetTransaction')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;

        }
    }


    const fetchUnblockTransaction =  async (orderId) => {

        setLoadingUnblockData(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await unblockTransaction({
            initData:init_data,
            orderId:orderId,
        });

        if (error) {

            console.log('ERRROR!', error);
            setErrorUnblockData(error);
            setLoadingUnblockData(false);

        } else {
            setUnblockData(data);
            setErrorUnblockData(null);
            setLoadingUnblockData(false);

            console.log(data, 'fetchGetTransaction')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;

        }
    }


    const fetchGetOrderInfo =  async (orderId) => {
        // deleteP2pOrder

        setLoadingOrderInfo(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getOrderInfo({
            initData:init_data,
            orderId:orderId,
        });

        if (error) {

            console.log('ERRROR!', error);
            setErrorOrderInfo(error);
            setLoadingOrderInfo(false);

        } else {
            setOrderInfo(data);
            setErrorOrderInfo(null);
            setLoadingOrderInfo(false);

            console.log(data, 'fetchGetTransaction')

        }
    }




    const fetchSellOrder =  async (tonAmount, nfsAmount) => {

        setLoadingSellOrderData(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await sellOrderApi({
            initData:init_data,
            tonAmount:tonAmount,
            nfsAmount:nfsAmount,
        });

        if (error) {

            console.log('ERRROR!', error);
            setErrorSellOrderData(error);
            setLoadingSellOrderData(false);

        } else {
            setSellOrderData(data);
            setErrorSellOrderData(null);
            setLoadingSellOrderData(false);

            console.log(data, 'fetchSellOrder')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;

        }
    }


    const fetchP2PHistory =  async () => {

        setLoadingP2PHistory(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getP2pHistory({
            initData:init_data
        });

        if (error) {

            console.log('ERRROR!', error);
            setErrorP2PHistory(error);
            setLoadingP2PHistory(false);

        } else {
            setP2PHistory(data);
            setErrorP2PHistory(null);
            setLoadingP2PHistory(false);

            console.log(data, 'fetchSellOrder')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;

        }
    }


    const fetchP2PValidate =  async (nfsAmount, convertedTon) => {

        setLoadingP2PValidate(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await p2pValidate({
            initData:init_data,
            nfsAmount:nfsAmount,
            convertedTon: convertedTon
        });

        if (error) {

            console.log('ERRROR!', error);
            setErrorP2PValidate(error);
            setLoadingP2PValidate(false);

        } else {
            setP2PValidate(data);
            setErrorP2PValidate(null);
            setLoadingP2PValidate(false);

            console.log(data, 'fetchSellOrder')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;

        }
    }


    return {p2pMainData, allOrders,  loadingAllOrders, errorAllOrders, fetchGetAllOrders,
            myOrders, loadingMyOrders, errorMyOrders, fetchGetMyOrders,
            deleteMyOrder, loadingDeleteMyOrder, errorDeleteMyOrder, fetchDeleteOrder,
            transactionData, loadingTransactionData, errorTransactionData, fetchGetTransaction,
            sellOrderData, loadingSellOrderData, errorSellOrderData, fetchSellOrder,
            orderInfo, loadingOrderInfo, errorOrderInfo, fetchGetOrderInfo,
            p2PHistory, loadingP2PHistory, errorP2PHistory, fetchP2PHistory,
            p2PValidate, loadingP2PValidate, errorP2PValidate, fetchP2PValidate,
            unblockData, loadingUnblockData, errorUnblockData, fetchUnblockTransaction
    };
};

export default useP2POrders;

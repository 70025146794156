export const GarageActiveIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={45}
            height={54}
            fill="none"
        >
            <path
                fill="#fff"
                d="M21.776 15.565c-4.254 2.418-10.409 6.117-10.578 6.334C11 22.162 11 22.2 11 30.172v8l.226.179c.32.263 1.77.263 2.09 0l.225-.18.047-6.766.047-6.758h18.73l.047 6.758.047 6.767.226.179c.32.263 1.77.263 2.09 0l.225-.18v-8c0-7.971 0-8.009-.198-8.272-.122-.16-1.986-1.308-4.894-3.012C23.668 15.226 23.273 15 23 15c-.132 0-.678.254-1.224.565Z"
            />
            <path
                fill="url(#x)"
                d="M19.122 26.106c-.81.245-1.346.819-1.816 1.967-.32.762-.377.856-.555.8-.104-.038-.358-.066-.565-.066-.499 0-.81.348-.81.885 0 .376.236.715.688.979.14.084.14.14-.047.753-.17.564-.198.969-.236 2.945-.047 2.466-.019 2.692.424 3.031.31.245 1.412.264 1.77.038.328-.217.507-.612.507-1.12v-.424h9.036v.424c0 .489.178.903.49 1.12.32.225 1.43.207 1.768-.038.462-.33.49-.536.452-2.983-.037-1.902-.075-2.344-.245-2.946-.178-.65-.178-.716-.037-.8.452-.273.677-.603.677-.98 0-.536-.31-.884-.809-.884-.207 0-.461.028-.574.066-.17.056-.235-.038-.593-.885-.32-.753-.48-1.016-.819-1.308-.734-.659-.837-.668-4.866-.659-1.976 0-3.698.038-3.84.085Zm7.52.762c.226.113.47.292.546.405.283.433 1.177 2.739 1.177 3.04 0 .584-.047.593-5.346.593-3.228 0-4.79-.038-4.998-.104-.546-.188-.546-.414.028-1.844.65-1.6.782-1.817 1.243-2.071.357-.207.423-.216 3.651-.216 3.163 0 3.304.009 3.7.197Zm-6.54 5.798c.13.113.169.292.169.715 0 .876-.066.913-1.516.913-1.139 0-1.214-.01-1.346-.207-.094-.122-.15-.405-.15-.687 0-.282.056-.565.15-.687.132-.198.207-.207 1.328-.207.978 0 1.223.028 1.364.16Zm8.395-.01c.103.104.15.33.15.744 0 .866-.047.894-1.421.894-.621 0-1.214-.047-1.318-.094-.15-.085-.179-.207-.179-.8 0-.565.029-.715.17-.79.084-.057.677-.104 1.308-.104.913 0 1.177.028 1.29.15Z"
            />
            <path
                fill="url(#i)"
                d="M0 0h45v54H0z"
                opacity={0.7}
                style={{
                    mixBlendMode: "hard-light",
                }}
            />
            <defs>
                <linearGradient
                    id="x"
                    x1={15.377}
                    x2={30.623}
                    y1={31.808}
                    y2={31.808}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={0}
                    x2={45}
                    y1={27}
                    y2={27}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>
    )

}


export const BlockCar = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="7"
            fill="none"
            viewBox="0 0 6 7"
        >
            <path
                fill="#fff"
                d="M2.311.009A1.907 1.907 0 00.747 1.286c-.08.242-.09.318-.097.802l-.008.455-.143.008c-.126.007-.154.014-.242.061a.475.475 0 00-.154.13c-.11.144-.106.035-.101 1.83l.004 1.615.034.069a.585.585 0 00.25.243l.086.042H4.7l.086-.042a.585.585 0 00.249-.243l.034-.07.004-1.615c.005-1.8.011-1.691-.103-1.836a.509.509 0 00-.155-.124.427.427 0 00-.24-.06l-.142-.008-.007-.455c-.006-.347-.014-.484-.034-.571C4.22.749 3.658.187 2.904.033A2.397 2.397 0 002.31.01zm.487.886c.274.07.529.279.655.537.094.19.103.25.111.703l.008.413H1.507V2.21c0-.483.029-.648.155-.857.07-.113.232-.276.352-.348.23-.139.516-.179.784-.11zM2.74 3.623c.087.042.186.14.23.231a.39.39 0 01.036.202c0 .112-.006.141-.042.207a.69.69 0 01-.103.139l-.063.06v.42c0 .468-.003.486-.097.57a.242.242 0 01-.329 0c-.094-.084-.097-.102-.097-.57v-.42l-.063-.06a.479.479 0 01-.128-.494.538.538 0 01.293-.305.572.572 0 01.363.02z"
            ></path>
        </svg>
    )

}

export const DailyRewardIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={36}
            height={39}
            fill="none"
        >
            <path
                fill="url(#a)"
                d="M7.363.08C6.73.26 6.452.79 6.452 1.806v.695H8.97v-.764C8.97.8 8.822.46 8.275.175c-.417-.209-.512-.217-.912-.096Z"
            />
            <path
                fill="url(#b)"
                d="M27.734.175c-.556.286-.704.616-.704 1.563v.764h2.519V1.79c0-1.103-.33-1.615-1.13-1.763-.225-.035-.399 0-.685.148Z"
            />
            <path
                fill="url(#c)"
                d="M5.15 2.719a6.267 6.267 0 0 0-3.274 1.806C.626 5.827.139 7.035.052 9.03L0 10.23h36l-.043-1.199c-.044-1.042-.079-1.293-.322-2.005-.338-1.016-.738-1.685-1.458-2.45a6.41 6.41 0 0 0-2.197-1.519c-.565-.225-1.633-.468-2.093-.468h-.252v1.233c0 1.701-.139 2.127-.816 2.474-.33.165-.99.113-1.294-.113-.477-.347-.581-.73-.581-2.231V2.589H9.056v1.363c0 1.502-.104 1.884-.581 2.231-.304.226-.964.278-1.294.113-.677-.347-.816-.773-.816-2.474V2.589l-.322.008c-.182 0-.581.061-.894.122Z"
            />
            <path
                fill="url(#d)"
                d="M.669 34.846c-.634-1.337-.59-.512-.626-11.835L.017 12.835h35.966l-.026 10.176c-.035 11.323.008 10.498-.626 11.835a6.354 6.354 0 0 1-2.96 2.996c-1.433.694-.383.651-14.371.651s-12.938.043-14.37-.651a6.355 6.355 0 0 1-2.961-2.996Z"
            />
            <path
                fill="#091400"
                d="M8.756 24.226c-6.297 10.98-6.22 10.85-5.829 10.59.208-.104 1.952-1.249 3.877-2.524l3.513-2.29 2.602-4.605 2.628-4.606 3.33-.078c3.643-.078 3.487-.13 2.889 1.353-.13.287 1.015-.338 3.018-1.665 1.77-1.17 3.33-2.238 3.461-2.368.13-.104-.078-.494-.442-.859l-.677-.676H13.18l-4.423 7.728ZM23.848 23.081c-5.152 3.461-10.018 6.74-10.799 7.26l-1.431.989H22.13l2.498-4.372c2.472-4.371 2.498-4.397 5.595-7.233 2.784-2.498 3.304-3.019 3.07-2.94-.052 0-4.293 2.835-9.445 6.296Z"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1={6.452}
                    x2={8.97}
                    y1={1.251}
                    y2={1.251}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6E076"/>
                    <stop offset={0.5} stopColor="#7EC93F"/>
                    <stop offset={1} stopColor="#A6E076"/>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={27.03}
                    x2={29.549}
                    y1={1.258}
                    y2={1.258}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6E076"/>
                    <stop offset={0.5} stopColor="#7EC93F"/>
                    <stop offset={1} stopColor="#A6E076"/>
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={0}
                    x2={36}
                    y1={6.409}
                    y2={6.409}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6E076"/>
                    <stop offset={0.5} stopColor="#7EC93F"/>
                    <stop offset={1} stopColor="#A6E076"/>
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={0.017}
                    x2={35.983}
                    y1={25.664}
                    y2={25.664}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6E076"/>
                    <stop offset={0.5} stopColor="#7EC93F"/>
                    <stop offset={1} stopColor="#A6E076"/>
                </linearGradient>
            </defs>
        </svg>
    )
}


export const MoneyIcon = ({}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={13}
            fill="none"
        >
            <path
                fill="#fff"
                stroke="url(#v)"
                strokeWidth={0.52}
                d="M4.368 5.485C2.19 9.28 1.115 11.157.617 12.063l.24-.157c.487-.317 1.122-.736 1.79-1.178L5.03 9.174 6.8 6.04v-.001l1.821-3.192.073-.127.147-.004 2.308-.054.265-.006c.498-.01.887-.02 1.181-.015.173.003.32.01.444.025.12.014.234.038.331.084.104.05.198.13.245.255a.57.57 0 0 1 .014.32 1.214 1.214 0 0 1-.028.106c.35-.198.861-.516 1.504-.942.612-.405 1.188-.792 1.622-1.089a39.12 39.12 0 0 0 .657-.459 1.396 1.396 0 0 0-.228-.287L16.765.26H7.358l-2.99 5.225Z"
            />
            <path
                fill="#fff"
                stroke="url(#w)"
                strokeWidth={0.52}
                d="m18.845 2.042.054-.049c-1.133.757-2.582 1.729-4.154 2.785-1.837 1.234-3.623 2.436-4.997 3.36l-2.485 1.67-.304.21H13.26l1.656-2.897c.853-1.508 1.296-2.29 1.797-2.937.502-.648 1.059-1.158 2.119-2.13l.012-.01.002-.002Z"
            />
            <defs>
                <linearGradient
                    id="v"
                    x1={0}
                    x2={17.675}
                    y1={6.372}
                    y2={6.372}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={6.126}
                    x2={21.181}
                    y1={5.236}
                    y2={5.236}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>



    )

}

import * as React from "react";

export const InfoCarDealerShipIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={22}
            fill="none"
        >
            <path
                fill="#fff"
                d="M6.266.078a3.245 3.245 0 0 0-2.038 1.409c-.14.217-.531 1.344-.878 2.504s-.64 2.125-.661 2.147c-.022.032-.228-.054-.456-.185-.52-.292-.813-.303-1.106-.01-.151.151-.206.325-.184.618.033.346.109.444.607.77.304.194.564.444.564.541 0 .109-.217.336-.477.532-.596.412-1.193 1.203-1.442 1.918-.173.467-.195.955-.195 3.686 0 2.97.01 3.177.228 3.643.694 1.507 2.287 2.244 3.86 1.789.997-.282 1.929-1.29 2.146-2.288l.065-.325 3.024-.032 3.036-.022.01-.846c.012-1.084.185-1.832.695-2.894 1.832-3.849 6.97-5.063 10.288-2.44.596.477.65.423.39-.336-.227-.64-.824-1.43-1.387-1.843-.694-.498-.705-.66-.066-1.062.662-.412.857-.78.63-1.182-.25-.455-.64-.53-1.182-.25-.25.131-.467.229-.477.207-.022-.022-.304-.976-.65-2.136-.348-1.16-.738-2.287-.88-2.504-.313-.51-.845-.943-1.463-1.236-.477-.217-.65-.228-6.016-.25-3.036-.01-5.735.033-5.985.077ZM17.91 1.812c.195.109.422.315.51.455.13.206 1.69 5.117 1.69 5.334 0 .033-3.653.065-8.13.065-4.467 0-8.131-.032-8.131-.065 0-.217 1.56-5.128 1.69-5.334.088-.14.315-.346.51-.455.336-.206.586-.217 5.93-.217 5.345 0 5.595.011 5.93.217ZM7.34 11.894c.26.271.314.39.249.63-.152.617-.38.726-1.626.726-1.247 0-1.475-.109-1.627-.727-.065-.238-.01-.358.25-.629.325-.314.358-.325 1.377-.325 1.019 0 1.051.011 1.376.325Z"
            />
            <path
                fill="url(#u)"
                d="M17.227 11.84c-2.039.716-2.245.824-2.537 1.301-.217.358-.228.477-.195 2.353.032 1.94.043 1.994.347 2.634.477.954 1.127 1.583 2.656 2.537.748.466 1.441.878 1.528.9.25.086.716-.141 2.06-1.02 2.44-1.582 2.992-2.655 2.905-5.615-.032-1.518-.184-1.94-.802-2.298-.542-.315-3.642-1.388-4-1.377-.174 0-1.063.26-1.962.585Z"
            />
            <path
                fill="#fff"
                d="M20.94 14.62a1.427 1.427 0 0 0-2.321 1.566c-.54.472-1.048.978-1.522 1.515a.321.321 0 0 0-.075.236c.01.135.016.267.021.396a.19.19 0 0 0 .185.184c.129.005.26.012.395.022a.323.323 0 0 0 .237-.076l.089-.079a.126.126 0 0 0 .04-.077c.01-.073.02-.146.028-.22.013-.114.116-.189.236-.167.22.04.393-.128.353-.347v-.002c-.022-.12.053-.222.169-.233l.221-.023a.126.126 0 0 0 .08-.04c.1-.11.2-.22.299-.333a1.427 1.427 0 0 0 1.566-2.322Zm-.37.928a.395.395 0 1 1-.558-.56.395.395 0 0 1 .559.56Z"
            />
            <defs>
                <linearGradient
                    id="u"
                    x1={14.481}
                    x2={24}
                    y1={16.418}
                    y2={16.418}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>
    )

}



export const PetrolWhiteIcon = ({}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={11}
            height={16}
            fill="none"
        >
            <path
                fill="#fff"
                d="M5.827.06c-.06.041-.527.493-1.035 1l-.922.926H2.166c-1.52 0-1.712.008-1.798.06C.153 2.19.165 1.75.165 8.89c0 6.016.003 6.58.06 6.678.147.24-.248.226 5.357.226 4.643 0 5.094-.004 5.192-.06.24-.143.226.094.226-3.902V8.205l-.083-.105c-.109-.14-.286-.207-.553-.207-.918 0-2.13-.564-2.867-1.34-.511-.537-.794-1.08-.899-1.722l-.03-.192.967-.979c1.03-1.038 1.057-1.072.982-1.339-.027-.094-.312-.406-1.125-1.215L6.305.019 6.124.004c-.14-.012-.21.003-.297.056Zm.098 7.98.146.146v2.668c0 2.652 0 2.671-.079 2.773-.165.21-.154.21-1.896.21s-1.73 0-1.896-.21c-.079-.102-.079-.12-.079-2.773V8.186l.147-.146.146-.147h3.364l.147.147Z"
            />
            <path fill="#fff" d="M3.137 10.865v1.957h1.956V8.909H3.137v1.956Z" />
        </svg>

    )

}

import {useState, useEffect, useRef} from 'react';
import {getDealerCars, getNFSPackages} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useNFSPackages = () => {

    const { user, triggerVibration, webApp  } = useTelegram();

    const [NFSPackages, setNFSPackages] = useState(null);
    const [loadingNFSPackages, setLoadingNFSPackages] = useState(true);
    const [errorNFSPackages, setErrorNFSPackages] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchNFSPackages();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchNFSPackages()
            }
        }

    }, [webApp?.initData]);

    const fetchNFSPackages = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getNFSPackages( );

        console.log(data, '----d-d-d-d-d-')
        if (error) {
            console.log('ERRROR!', error);
            setErrorNFSPackages(error);
            setLoadingNFSPackages(false);

        } else {

            // setTimeout(()=>{
                setNFSPackages(data);
                setLoadingNFSPackages(false);
            // }, 1000)

            console.log(data, 'data')

        }

    };


    return { NFSPackages, loadingNFSPackages, errorNFSPackages, fetchNFSPackages };
};

export default useNFSPackages;

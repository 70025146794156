import * as React from "react";

export const ModalCloseIcon3 = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26}
            height={26}
            fill="none"
        >
            <g filter="url(#a)">
                <circle
                    cx={12.531}
                    cy={12.531}
                    r={12.531}
                    fill="#EBEBEB"
                    fillOpacity={0.4}
                />
            </g>
            <path
                stroke="#F8FFF3"
                strokeWidth={2.387}
                d="m9.548 9.547 5.967 5.967M15.514 9.547l-5.967 5.967"
            />
            <defs>
                <filter
                    id="a"
                    width={34.609}
                    height={34.609}
                    x={-4.774}
                    y={-4.774}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation={2.387} />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_816_663"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_816_663"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={3.806} />
                    <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect2_innerShadow_816_663" />
                </filter>
            </defs>
        </svg>

    )

}

import React from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";

const CarDealerShipModal = ({isActive, onClose, onSuccessClose,  carData}) => {

    const { user, webApp, triggerVibration } = useTelegram();

    const handleGetCurrencies = async () => {
        triggerVibration('heavy')
        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        const {error, data} = await byDealerCar({initData:init_data, carTypeId: carData?.carTypeId} );

        console.log(data, '----d-d-d-d-d-')
        if (error) {
            console.log('ERRROR!', error);
            onClose(true);
        } else {
            onSuccessClose()
            console.log(data, 'data')
        }
    }

    if (!isActive) {
        return null
    }

    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        triggerVibration('heavy')
                        onClose()
                    }}
                >
                    <ModalCloseIcon3/>
                </button>
                <p className='car_dealership_modal_title'>Do you really want to buy this car?</p>
                <h1 className='car_dealership_modal_car_name'>{carData.car_name}</h1>
                <div className='car_dealership_modal_buttons_wrapper'>

                    <button
                        onClick={handleGetCurrencies}
                        className='car_dealership_modal_apply_button'
                    >

                        <div className='car_dealership_modal_apply_button_child'>
                            <p className='car_dealership_modal_apply_button_text'>apply</p>
                        </div>

                    </button>
                    <button
                        className='car_dealership_modal_no_button'
                        onClick={() => {
                            triggerVibration('heavy')
                            onClose()
                        }}
                    >
                        <div className="car_dealership_modal_no_button_child">
                            <p className='car_dealership_modal_no_button_text'>no</p>
                        </div>
                    </button>
                </div>
            </div>

        </div>

    );
};

export default CarDealerShipModal;

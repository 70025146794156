import {useState, useEffect, useRef} from 'react';
import {buyPackage, buySpin, getAccount} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";
import {useNavigate} from "react-router-dom";
import {useTonConnectUI} from "@tonconnect/ui-react";

const useBuyPackage = () => {
    const navigate = useNavigate();

    const { user, triggerVibration, webApp  } = useTelegram();
    const [buyPackageData, setBuyPackage] = useState(null);
    const [loadingBuyPackage, setLoadingBuyPackage] = useState(true);
    const [errorBuyPackage, setErrorBuyPackage] = useState(null);
    const isMounted = useRef(false);
    const [tonConnectUI] = useTonConnectUI();

    useEffect(() => {

        // if (process.env.REACT_APP_DEBUG == 'true') {
        //     if (!isMounted.current) {
        //         fetchUserData();
        //         isMounted.current = true;
        //     }
        // } else {
        //
        //     if (webApp?.initData && webApp?.initData != '') {
        //         fetchUserData()
        //     }
        // }


    }, [webApp?.initData]);

    const fetchBuyPackage = async (packageId) => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await buyPackage({
            initData:init_data,
            packageId:packageId
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorBuyPackage(error);
            setLoadingBuyPackage(false);

        } else {
            setBuyPackage(data)
            setErrorBuyPackage(null);
            setLoadingBuyPackage(false);
            console.log(data, 'fetchBuyPackage')

            // const myTransaction = {
            //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            //     messages: [
            //         {
            //             address: data?.tonAddress,
            //             amount: data?.amount,
            //             payload: data?.payload
            //         }
            //     ]
            // }
            //
            // tonConnectUI.sendTransaction(myTransaction)
            // window.location = data?.link;


        }

    };


    return {buyPackageData,  loadingBuyPackage, errorBuyPackage, fetchBuyPackage };
};

export default useBuyPackage;

import {useState, useEffect, useRef} from 'react';
import {clickToMission, getAccount, getMissionList} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useMissionList = () => {

    const { user, triggerVibration, webApp  } = useTelegram();

    const [missionList, setMissionList] = useState(null);
    const [missionListLoading, setMissionListLoading] = useState(true);
    const [missionListError, setMissionListError] = useState(null);

    const [clickMission, setClickMission] = useState(null);
    const [clickMissionLoading, setClickMissionLoading] = useState(true);
    const [clickMissionError, setClickMissionError] = useState(null);

    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchGetMissionList();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchGetMissionList()
            }
        }


    }, [webApp?.initData]);

    const fetchGetMissionList = async () => {

        setMissionListLoading(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getMissionList({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setMissionListError(error);
            setMissionListLoading(false);

        } else {

            setMissionList(data);
            setMissionListLoading(false);

            console.log(data, 'data')

        }

    };

    const fetchClickMission = async (missionId) => {

        setClickMissionLoading(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await clickToMission({initData:init_data, missionId:missionId} );

        if (error) {

            console.log('ERRROR!', error);
            setClickMissionError(error);
            setClickMissionLoading(false);

        } else {

            setClickMission(data);
            setClickMissionLoading(false);

            console.log(data, 'data')

        }

    };


    return { missionList, missionListLoading, missionListError, fetchGetMissionList,
            clickMission, clickMissionLoading, clickMissionError, fetchClickMission};
};

export default useMissionList;

import {useState, useEffect, useRef} from 'react';
import {buySpin, garageUpstageApi, getAccount} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useGarageUpstage = () => {

    const { user, triggerVibration, webApp  } = useTelegram();

    const [garageUpstage, setGarageUpstage] = useState(null);
    const [loadingGarageUpstage, setLoadingGarageUpstage] = useState(true);
    const [errorGarageUpstage, setErrorGarageUpstage] = useState(null);


    useEffect(() => {

        // if (process.env.REACT_APP_DEBUG == 'true') {
        //     if (!isMounted.current) {
        //         fetchUserData();
        //         isMounted.current = true;
        //     }
        // } else {
        //
        //     if (webApp?.initData && webApp?.initData != '') {
        //         fetchUserData()
        //     }
        // }


    }, [webApp?.initData]);

    const fetchGarageUpstage = async (stageNumber, carId) => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await garageUpstageApi({
            initData:init_data,
            stageNumber:stageNumber,
            carId:carId,
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorGarageUpstage(error);
            setLoadingGarageUpstage(false);

        } else {
            setGarageUpstage(data)
            setErrorGarageUpstage(null);
            setLoadingGarageUpstage(false);
            console.log(data, 'data')

        }

    };


    return {garageUpstage,  loadingGarageUpstage, errorGarageUpstage, fetchGarageUpstage };
};

export default useGarageUpstage;

import React, {useEffect} from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import useTranslation from "../../hooks/useTranslation";
import useBySpace from "../../hooks/useBySpace";

const BySpaceModal = ({isActive, onClose, onCloseSuccess, bySpaceModalType, noNfs}) => {
    const {translation} = useTranslation();

    const { user, webApp, triggerVibration } = useTelegram();
    const { bySpaceAnswer,  loadingBySpace, errorBySpace, fetchBuySpace } = useBySpace();

    useEffect(() => {
        if (errorBySpace && errorBySpace?.msg == "You don't have enough nfs") {
            console.warn(errorBySpace, 'errorBySpace')
            noNfs()
        } else {
            if (bySpaceAnswer) {
                onCloseSuccess();
                console.log(bySpaceAnswer)
            }
        }


    }, [bySpaceAnswer, errorBySpace]);


    if (!isActive) {
        return null
    }


    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        onClose()
                        triggerVibration('heavy');

                    }}
                >
                    <ModalCloseIcon3/>
                </button>

                {/*<NoGasSvg style={{margin: '10px auto ', display: 'block'}}/>*/}
                <p className={'space-modal-title'} style={{maxWidth: 276, textAlign:'center', margin: "0 auto 35px auto", textTransform: 'unset'}}>
                    {translation?.buy_garage_space}
                </p>

                <img className={'spaceGreenAuto'} src={require('../../assets/spaceGreenAuto.png')} alt=""/>

                <div className='car_dealership_modal_buttons_wrapper'>

                    <button
                        onClick={()=>{
                            triggerVibration('heavy');
                        }}
                        className='car_dealership_modal_apply_button'
                    >

                        <div
                            onClick={() => {
                                triggerVibration('heavy')
                                fetchBuySpace()
                            }}
                            className='car_dealership_modal_apply_button_child'>
                            <p className='car_dealership_modal_apply_button_text'>{translation?.yes}</p>
                        </div>

                    </button>
                    <button
                        className='car_dealership_modal_no_button '
                        onClick={() => {
                            triggerVibration('heavy');
                            onClose()
                        }}
                    >
                        <div className="car_dealership_modal_no_button_child no-gas-by-gas red">
                            <p className='car_dealership_modal_no_button_text'>{translation?.no}</p>
                        </div>
                    </button>
                </div>
            </div>

        </div>

    );
};

export default BySpaceModal;

export const RightIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={28}
            fill="none"
        >
            <path stroke="url(#a)" strokeWidth={3.5} d="m2 2 12 12L2 26"/>
            <defs>
                <linearGradient
                    id="a"
                    x1={14}
                    x2={2}
                    y1={14}
                    y2={14}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539"/>
                    <stop offset={1} stopColor="#A6E076"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

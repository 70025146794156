import React, {useEffect, useRef, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/general.css'

import {useNavigate, Link} from "react-router-dom";
// import Loader from "../../components/Loader";
import LoaderComponent from "../../components/Loader";
import {useAudio} from "../../context/AudioContext";
import useSettings from "../../hooks/useSettings";
import SliderComponent from "../../components/SliderComponent";
import useTranslation from "../../hooks/useTranslation";


function GeneralPage () {
    const {translation} = useTranslation();

    const { user, triggerVibration} = useTelegram();
    const { setPlaying,  audio } = useAudio();
    const { settings, loadingSettings, errorSettings, fetchGetSettings, fetchUpdateSettings } = useSettings();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [showCarType, setShowCarType] = useState('free');
    const [carImgUrl, setCarImgUrl] = useState('');
    const [carClass, setCarClass] = useState('general_page_car_img_free');

    const [showSlider, setShowSlider] = useState(true);


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{

        let slider_ = localStorage.getItem('slider')
        if (slider_) {
            setShowSlider(false)
        } else {
            setShowSlider(true)
        }

    }, [])


    useEffect(()=>{

        if (settings) {
            localStorage.setItem('vibration', JSON.stringify(settings?.vibration))
            localStorage.setItem('language', settings?.language)
            console.log(settings, 'settings')
        }

    }, [settings])


    const navigate = useNavigate();


    if (showSlider) {
        return (
            <SliderComponent
                onClose={()=>{
                    setShowSlider(false)
                }}
            />
        )
    }

    return (
        <>

            {/*{( loading ) && (*/}
            {/*    <LoaderComponent/>*/}
            {/*)}*/}

            <div className="general_page_wrapper general_page_wrapper-start" id='general_page'>

                <div className={'start-top-img-wrapper'}>
                    <img src={require('../../assets/images/nfsstart.png')} alt=""/>
                </div>

                <Link
                    className={'start-page-btn'}
                    to={'/general'}
                    onClick={() => {
                        triggerVibration('heavy')
                        if (settings?.sound) {
                            setPlaying(true)
                            // audio.play();
                        }
                    }}
                >
                    <img src={require('../../assets/images/start.webp')} alt=""/>
                </Link>


                <div className={'start-bottom-text'}>{translation?.CLICK_TO_START_PLAYING}</div>

            </div>
        </>

    );
}

export default GeneralPage;


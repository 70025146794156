import React, {useEffect, useState,} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/balance_nfs.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseBalanceIcon} from "../../components/icons/CloseBalanceIcon";
import {BalanceMainIcon} from "../../components/icons/BalanceMainIcon";
import {BalancePetrol} from "../../components/icons/BalancePetrol";
import  BuyPackageModal from "../../components/BuyPackageModal";
import useNFSPackages from "../../hooks/useNFSPackages";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import {InputNfsIcon2} from "../../components/icons/InputNfsIcon2";

import PayTypeModal from "../../components/PayTypeModal";
import useTranslation from "../../hooks/useTranslation";

function BalanceNfsPage () {
    const { user, triggerVibration } = useTelegram();
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const {nfsBalance, userData, loading, error } = useUserData();
    const { NFSPackages, loadingNFSPackages, errorNFSPackages, fetchNFSPackages } = useNFSPackages();

    const [showBuyPackageModal, setShowBuyPackageModal] = useState(false);
    const [buyPackageData, setBuyPackageData] = useState(null);
    const [balanceList, setBalanceList] = useState([
        {
            id: 0,
            balance_icon: require('../../assets/images/balance_img1.png'),
            nfs_info: '100.000',
            ton_info: '1',
            extra_info: '',
            petrol_info: '',
            garage_number: '',
        },
        {
            id: 1,
            balance_icon: require('../../assets/images/balance_img2.png'),
            nfs_info: '300.000',
            ton_info: '2.99',
            extra_info: '+25 000',
            petrol_info: '+5',
            garage_number: '',
        },
        {
            id: 2,
            balance_icon: require('../../assets/images/balance_img3.png'),
            nfs_info: '500.000',
            ton_info: '4.99',
            extra_info: '+50 000',
            petrol_info: '+10',
            garage_number: '',
        },
        {
            id: 3,
            balance_icon: require('../../assets/images/balance_img4.png'),
            nfs_info: '1.000.000',
            ton_info: '9.99',
            extra_info: '+100 000',
            petrol_info: '',
            garage_number: '1',
        },
        {
            id: 4,
            balance_icon: require('../../assets/images/balance_img5.png'),
            nfs_info: '5.000.000',
            ton_info: '49.9',
            extra_info: '+200 000',
            petrol_info: '',
            garage_number: '2',
        },
        {
            id: 5,
            balance_icon: require('../../assets/images/balance_img6.png'),
            nfs_info: '10.000.000',
            ton_info: '99',
            extra_info: '+300 000',
            petrol_info: '',
            garage_number: '3',
        },
    ]);

    const [payData, setPayData] = useState(null);
    const [showPayTypeModal, setShowPayTypeModal] = useState(false);



    useEffect(() => {
        console.log(NFSPackages, 'NFSPackages')
    }, [NFSPackages]);

    const navigate = useNavigate();

    const showPackageModal = (item, balanceList_) => {
        setShowBuyPackageModal(true)
        setBuyPackageData({
            id: item?.id,
            modal_icon: item?.balance_icon,
            modal_title: item?.nfsAmount,
            modal_info: item?.tonAmount,
            modal_extra_info: balanceList_?.extra_info,
            modal_petrol_info: balanceList_?.petrol_info,
            modal_garage_info: balanceList_?.garage_number,
            balance_icon: balanceList_?.balance_icon,
        })
    }



    return (
        <>
            {( loading || loadingNFSPackages ) && (
                <LoaderComponent/>
            )}
            <div className="balance_nfs_page_wrapper" id='balance_nfs_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <div className="general_page_item">
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/'}
                            className="general_page_item_logo"

                        >
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <div className="general_page_item_top_child_title_icon_wrapper">
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </div>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="balance_main_wrapper">
                    <div className="balance_main_wrapper_child">
                        <div className='balance_main_wrapper_child_title_box'>
                            <p className='balance_main_wrapper_child_title'>
                                {translation?.recharge}

                            </p>
                        </div>
                        <button
                            className="balance_main_wrapper_child_close_btn"
                            onClick={() => {
                                triggerVibration('heavy')
                                navigate('/')
                            }}
                        >
                            <CloseBalanceIcon/>
                        </button>
                        <div className='balance_main_wrapper_child_main_title_icon_wrapper'>
                            <h1 className='balance_main_wrapper_child_main_title'>{translation?.balance}</h1>
                            <div className='balance_main_wrapper_child_main_icon'>
                                <div className={'balance-nfs_2'}>
                                    <BalanceMainIcon/>
                                    {/*<InputNfsIcon2/>*/}
                                    {nfsBalance}
                                </div>
                            </div>
                        </div>
                        <div className='balance_list_items_wrapper'>
                            {NFSPackages?.nfsPackages?.map((item, index) => {
                                return (
                                    <button
                                        key={index} className='balance_list_item_parent'
                                        onClick={() => {
                                            console.log(item, 'item')
                                            showPackageModal(item,balanceList[index])
                                        }}
                                    >
                                        <div className="balance_list_item_child"></div>
                                        <div className='balance_list_item'>
                                            <div className="balance_list_item_extra_info_petrol_info_garage_info_wrapper">


                                                {balanceList[index]?.extra_info != '' &&
                                                    <div className='balance_list_item_extra_info_wrapper'>
                                                        <p className='balance_list_item_extra_info'>{translation?.extra} {balanceList[index]?.extra_info}</p>
                                                    </div>
                                                }
                                                {balanceList[index]?.petrol_info != '' &&
                                                    <div className='balance_list_item_petrol_info_icon_wrapper'>
                                                        <p className="balance_list_item_petrol_info">{balanceList[index]?.petrol_info}</p>
                                                        <div className='balance_list_item_petrol_icon'>
                                                            <BalancePetrol/>
                                                        </div>
                                                    </div>
                                                }
                                                {balanceList[index]?.garage_number != '' &&
                                                    <div className='balance_list_item_garage_info_wrapper'>
                                                        <p className='balance_list_item_garage_info'>{balanceList[index]?.garage_number} <br/> garage</p>
                                                    </div>
                                                }

                                            </div>
                                            <div className="balance_list_item_icon">
                                                <img src={balanceList[index].balance_icon} alt=""/>
                                            </div>
                                            <p className="balance_list_item_nfs_info">
                                                {item?.nfsAmount}
                                                <span className='balance_list_item_nfs_info_span'>nfs</span>
                                            </p>
                                            <div className='balance_list_item_ton_info_wrapper'>
                                                <p className="balance_list_item_ton_info">
                                                    {parseFloat(item?.tonAmount) / 1000000000}
                                                </p>
                                                <div className="balance_list_item_ton_info_img">
                                                    <img src={require('../../assets/images/input_top_img.png')} alt=""/>
                                                </div>
                                                <span className='balance_list_item_ton_info_span'>ton</span>

                                            </div>

                                        </div>
                                    </button>

                                )
                            })}
                        </div>

                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/general'}
                        className='balance_btn'
                    >
                        {translation?.back_to_home}
                        {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                    </Link>
                </div>

                <BuyPackageModal
                    isActive={showBuyPackageModal}
                    onClose={() => {
                        setShowBuyPackageModal(false)
                    }}
                    payClose={(data)=>{
                        setPayData(data)
                        setShowBuyPackageModal(false)
                        setShowPayTypeModal(true)
                    }}
                    packageData={buyPackageData}
                />


                {showPayTypeModal &&
                    <PayTypeModal
                        data={payData}
                        isActive={true}
                        onClose={() => {
                            setShowPayTypeModal(false)
                        }}
                    />
                }
            </div>
        </>

    );
}

export default BalanceNfsPage;


import React, { useEffect, useState } from 'react';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/car_exhibition.css';
import { ModalCloseIcon } from '../../components/icons/ModalCloseIcon';
import { CarMarketSellCarIcon } from '../../components/icons/carMarketSellCarIcon';
import {Link, useNavigate} from 'react-router-dom';
import { SettingsIcon } from '../../components/icons/SettingsIcon';
import { PetrolRedIcon } from '../../components/icons/PetrolRedIcon';
import { PetrolWhiteIcon } from '../../components/icons/PetrolWhiteIcon';
import { ReplenishIcon } from '../../components/icons/ReplenishIcon';
import { LogoIcon } from '../../components/icons/LogoIcon';
import { MoneyIcon } from '../../components/icons/MoneyIcon';
import { WalletIcon } from '../../components/icons/WalletIcon';
import { LeftIcon } from '../../components/icons/LeftIcon';
import { RightIcon } from '../../components/icons/RightIcon';
import ExhibitionCarModal from '../../components/ExhibitionCarModal';
import useTranslation from "../../hooks/useTranslation";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useExpoList from "../../hooks/useExpoList";
import RedNfsbalanceModal from "../../components/RedNfsbalanceModal";

function CarExhibitionPage() {
    const { user, triggerVibration } = useTelegram();

    const { language, translation, handleLanguageChange } = useTranslation();
    const {nfsBalance, userData, loading, error } = useUserData();

    const {expoList, errorExpoList, loadingExpoList, fetchGetExpoList,
            removeExpoList, loadingRemoveExpoList, errorRemoveExpoList, fetchRemoveCarFromExpo

    } = useExpoList();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [exhibitionCarsList, setExhibitionCarsList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [showExhibitionModal, setShowExhibitionModal] = useState(false);
    const [exhibitionModalInfo, setExhibitionModalInfo] = useState(null);

    const [showRedBalanceModal, setShowRedBalanceModal] = useState(false);

    useEffect(() => {
        if (removeExpoList) {
            fetchGetExpoList()
        }
    }, [removeExpoList]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {

        let newExhibitionCarsList = [];

        if (expoList) {

            for (let i = 0; i < expoList?.cars.length; i++) {

                if (expoList?.cars[i].expo) {
                    newExhibitionCarsList.push(expoList?.cars[i])
                }
            }

            newExhibitionCarsList.push({
                type:'noauto'
            })
        }

        setExhibitionCarsList(newExhibitionCarsList)
        console.log(newExhibitionCarsList, 'newExhibitionCarsList');
    }, [expoList]);


    const navigate = useNavigate();

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? exhibitionCarsList.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === exhibitionCarsList.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleChooseCar = () => {
        // setExhibitionModalInfo({ car_info: exhibitionCarsList[currentIndex].info });
        // setShowExhibitionModal(true);
        navigate('/exhibitionCarListPage')
    };


    return (
        <>
            <div className="car_exhibition_page_wrapper" id='car_exhibition_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon />
                            </Link>
                            <GazAmount
                                userData={userData}
                            />
                        </div>
                    </div>
                    <div className="general_page_item">
                        <Link to={'/'} className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon />
                            </div>
                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt="" />
                            </div>
                        </Link>
                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">

                            <Link
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                            >
                                <MoneyIcon />
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>

                            <Link
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                            >
                                <WalletIcon />
                            </Link>

                        </div>
                    </div>
                </div>
                <div className="car_exhibition_main_wrapper">
                    <div className="car_slider">
                        <div className="car_slide">

                                {exhibitionCarsList && exhibitionCarsList.length > 0 &&
                                    <div className='car_slide_img_box'>

                                        {exhibitionCarsList[currentIndex]?.type == 'noauto' ?

                                            <>
                                                <img className={'sss'} src={require('../../assets/noauto.png')} />
                                            </>
                                            :
                                            <img
                                                 src={`${process.env.REACT_APP_IMG_DOMAIN}${exhibitionCarsList[currentIndex].imagePath}`}
                                                 alt=""/>
                                        }
                                    </div>

                                }

                            {/*<p className='car_slide_info'>{exhibitionCarsList[currentIndex].info}</p>*/}
                            <div className='general_page_car_slider_buttons_wrapper'>
                                <button className="general_page_car_slider_left_btn prev_button" onClick={handlePrev}>
                                    <LeftIcon />
                                </button>
                                <button className="general_page_car_slider_right_btn next_button" onClick={handleNext}>
                                    <RightIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="car_exhibition_footer">

                        <div className={'car_exhibition_footer_data'}>

                            {exhibitionCarsList[currentIndex]?.type != 'noauto' ?

                                <span className={'car_exhibition_footer_data_per_day'}>
                                     {exhibitionCarsList[currentIndex]?.expoAward} NFS (~ {exhibitionCarsList[currentIndex]?.expoTonAward} TON) {translation?.per_day}
                                </span>
                                :
                                <span className={'car_exhibition_footer_data_per_day'}>

                                </span>
                            }


                            <div
                                onClick={()=>{
                                    setShowRedBalanceModal(true)
                                }}
                                className={'car_exhibition_footer_data_red_balance'}
                            >
                                <img src={require('../../assets/images/lock/lock.png')} alt=""/>
                                <span>{expoList?.redNfs}</span>
                                <img src={require('../../assets/images/lock/redNfs.png')} alt=""/>
                            </div>
                        </div>


                        {exhibitionCarsList[currentIndex]?.type == 'noauto' ?
                            <button
                                className='car_exhibition_footer_choose_car_btn'
                                onClick={handleChooseCar}
                            >
                                {translation?.choose_car}
                            </button>

                            :

                            <button
                                style={{background: "#6D6D6D"}}
                                className='car_exhibition_footer_choose_car_btn'
                                onClick={()=>{
                                    if (exhibitionCarsList[currentIndex]?.expoDays == 0) {

                                        fetchRemoveCarFromExpo(exhibitionCarsList[currentIndex]?.id);

                                    }
                                }}
                            >
                                {exhibitionCarsList[currentIndex]?.expoDays == 0 ?

                                    <>{translation?.remove_car_from_expo}</>
                                    :
                                    <>
                                        {translation?.you_can_change_car} {exhibitionCarsList[currentIndex]?.expoDays} {translation?.days}
                                    </>

                                }

                            </button>

                        }

                        <button
                            className='car_exhibition_footer_back_to_home_btn'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            {translation?.back_to_home}
                        </button>
                    </div>
                </div>

                <ExhibitionCarModal
                    isActive={showExhibitionModal}
                    onClose={() => {
                        setShowExhibitionModal(false);
                    }}
                    carData={exhibitionModalInfo}
                />

                <RedNfsbalanceModal
                    isActive={showRedBalanceModal}
                    onClose={() => {
                        setShowRedBalanceModal(false);
                    }}
                />
            </div>
        </>
    );
}

export default CarExhibitionPage;

import React, {useEffect} from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import useTranslation from "../../hooks/useTranslation";
import useBySpace from "../../hooks/useBySpace";
import {Stage1PrizIcon} from "../icons/Stage1PrizIcon";
import {Stage2PrizIcon} from "../icons/Stage2PrizIcon";
import {Stage3PrizIcon} from "../icons/Stage3PrizIcon";
import {Stage4PrizIcon} from "../icons/Stage4PrizIcon";
import useGarageUpstage from "../../hooks/useGarageUpstage";

const InventoryModal = ({isActive, onClose,  garageInfo, currentCar, onCloseAfterUpstage}) => {
    const {translation} = useTranslation();

    const { user, webApp, triggerVibration } = useTelegram();
    const {garageUpstage, errorGarageUpstage, loadingGarageUpstage, fetchGarageUpstage } = useGarageUpstage();

    useEffect(() => {
        if (garageUpstage && !errorGarageUpstage) {
            console.log(garageUpstage, 'garageUpstage')
            onCloseAfterUpstage()
            // console.log(currentCar, 'currentCar')
        }
    }, [garageUpstage, errorGarageUpstage]);

    useEffect(() => {
        if (currentCar) {
            console.log(currentCar, 'currentCar')
        }
    }, [currentCar]);

    if (!isActive) {
        return null
    }

    const handleFetchGarageUpstage = (stage) => {
        console.log(currentCar?.id, stage)
        fetchGarageUpstage(stage, currentCar?.id)
    }

    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        onClose()
                        triggerVibration('heavy');

                    }}
                >
                    <ModalCloseIcon3/>
                </button>

                {/*<NoGasSvg style={{margin: '10px auto ', display: 'block'}}/>*/}
                <p className={'space-modal-title'} style={{maxWidth: 276, textAlign:'center', margin: "0 auto 35px auto", textTransform: 'unset'}}>
                    STAGES
                </p>


                <div className={'invertar-stages-container'}>


                        <div
                            onClick={() => {
                                if (currentCar?.stage < 2) {
                                    // handleFetchGarageUpstage(2)
                                }
                            }}
                            className={`invertar-stages-item ${currentCar?.stage < 2 ? '' : 'disable'}`}
                        >
                            <div className={'invertar-item-left'}>
                                <Stage2PrizIcon width={'29'}/>
                            </div>
                            <span>Stage 2</span>
                            <div className="invertar-stages-item-count">
                                {garageInfo?.stage2Amount}
                            </div>
                        </div>


                        <div
                            onClick={() => {
                                if (currentCar?.stage < 3) {
                                    // handleFetchGarageUpstage(3)
                                }
                            }}
                            className={`invertar-stages-item ${currentCar?.stage < 3 ? '' : 'disable'}`}
                        >
                            <div className={'invertar-item-left'}>
                                <Stage3PrizIcon width={'34'}/>
                            </div>
                            <span>Stage 3</span>
                            <div className="invertar-stages-item-count">
                                {garageInfo?.stage3Amount}
                            </div>
                        </div>


                    {currentCar?.stage < 4 &&
                        <div
                            onClick={() => {
                                if (currentCar?.stage < 4) {
                                    // handleFetchGarageUpstage(4)
                                }
                            }}
                            className={`invertar-stages-item red ${currentCar?.stage < 4 ? '' : 'disable'}`}
                        >
                            <div className={'invertar-item-left'}>
                                <Stage4PrizIcon width={'34'}/>
                            </div>
                            <span>Stage 4</span>
                            <div className="invertar-stages-item-count red">
                                {garageInfo?.stage4Amount}
                            </div>
                        </div>
                    }


                </div>

            </div>

        </div>

    );
};

export default InventoryModal;

import {useState, useEffect, useRef} from 'react';
import {getAccount, getFriends, getSettings, updateSettings} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";
import useTranslation from "./useTranslation";

const useSettings = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [settings, setSettings] = useState(null);
    const [loadingSettings, setLoadingSettings] = useState(true);
    const [errorSettings, setErrorSettings] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchGetSettings();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchGetSettings()
            }
        }


    }, [webApp?.initData]);

    const fetchGetSettings = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getSettings({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setErrorSettings(error);
            setLoadingSettings(false);

        } else {

            setSettings(data);
            setLoadingSettings(false);

            console.log(data, 'data')

        }

    };
     const fetchUpdateSettings = async (sound, vibration,  language) => {

        // setLoadingSettings(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        const {error, data} = await updateSettings({
            initData:init_data,
            vibration: vibration,
            sound: sound,
            language: language
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorSettings(error);
            setLoadingSettings(false);
        } else {

            setSettings(data);
            setLoadingSettings(false);

            localStorage.setItem('language', language)
            console.log(data, 'data')

        }

    };


    return { settings, loadingSettings, errorSettings, fetchGetSettings, fetchUpdateSettings };
};

export default useSettings;

import React, {useEffect, useState} from 'react';
import {RoundIcon} from "../icons/RoundsIcon";
import {ModalCloseIcon} from "../icons/ModalCloseIcon";
import '../../assets/css/balance_nfs.css'
import {CloseBalanceIcon} from "../icons/CloseBalanceIcon";
import {BalanceMainIcon} from "../icons/BalanceMainIcon";
import {BalancePetrol} from "../icons/BalancePetrol";
import useNFSPackages from "../../hooks/useNFSPackages";
import useBuyPackage from "../../hooks/useBuyPackage";
import {useNavigate} from "react-router-dom";
import {useTelegram} from "../../TelegramContext";
import useTranslation from "../../hooks/useTranslation";

const DeleteP2POrderModal = ({isActive, onClose, sellClose}) => {
    const { user, triggerVibration } = useTelegram();
    const {translation} = useTranslation();

    if (!isActive) {
        return null
    }

    return (
        <div className='buy_package_modal'>
            <div className='buy_package_modal_wrapper'>
                <button
                    className='buy_package_modal_close_btn'
                    onClick={() => {
                        triggerVibration('heavy')
                        onClose()
                    }}
                >
                    <ModalCloseIcon/>
                </button>

                <p className='buy_package_modal_title p2p_delete_order_text'>
                    {translation?.confirm_sell_coins}
                </p>
                <div className='buy_package_modal_buttons_wrapper'>

                    <button
                        onClick={()=>{
                            triggerVibration('heavy')
                            sellClose()
                        }}
                        className='buy_package_modal_apply_button'
                    >
                        <div className='buy_package_modal_apply_button_child'>
                            <p className='buy_package_modal_apply_button_text'>{translation?.yes}</p>
                        </div>

                    </button>
                    <button
                        className='buy_package_modal_no_button'
                        onClick={() => {
                            triggerVibration('heavy')
                            onClose();
                        }}
                    >
                        <div className="buy_package_modal_no_button_child">
                            <p className='buy_package_modal_no_button_text'>{translation?.no}</p>
                        </div>
                    </button>
                </div>
            </div>


        </div>

    );
};

export default DeleteP2POrderModal;

export const PersonIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={11}
            fill="none"
        >
            <path fill="#2A4C0D" d="M2.783 2.227h6.124v6.124H2.783z" />
            <path
                fill="url(#f)"
                d="M3.502.035a1.688 1.688 0 0 0-.84.451c-.19.185-.25.282-.988 1.53C-.04 4.911.068 4.713.01 5.1c-.032.225.006.54.089.762.072.188 2.3 3.959 2.434 4.12.23.272.543.468.89.554.198.05.305.052 2.576.052 2.256 0 2.379-.002 2.571-.05.35-.088.655-.273.877-.534.166-.199 2.386-3.954 2.456-4.158.128-.37.128-.74 0-1.111-.067-.199-2.29-3.962-2.443-4.14a1.85 1.85 0 0 0-.842-.54C8.483.012 8.315.007 6.081.002 4.044-.002 3.662 0 3.5.035Zm2.827 2.45a1.3 1.3 0 0 1 .98 1.41c-.04.315-.232.654-.472.842a1.41 1.41 0 0 1-1.029.272c-.9-.149-1.384-1.057-.984-1.848.166-.331.474-.58.832-.673.19-.05.483-.052.673-.003Zm.874 3.01c.47.15.775.443.957.913.064.164.067.21.08.78l.01.608H3.778l.01-.608c.011-.439.025-.637.054-.723.19-.561.628-.932 1.208-1.018.075-.013.55-.018 1.058-.015.86.005.933.01 1.096.062Z"
            />
            <defs>
                <linearGradient
                    id="f"
                    x1={0}
                    x2={12}
                    y1={5.294}
                    y2={5.294}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>
    )

}



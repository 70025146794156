import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/car_dealership.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {ParticipantsIcon} from "../../components/icons/ParticipantsIcon";
import {ParticipantsIcon2} from "../../components/icons/Participants2Icon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import CarDealerShipModal from "../../components/CarDealerShipModal";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import useDealerCars from "../../hooks/useDealerCars";
import useGarageInfo from "../../hooks/useGarageInfo";
import useTranslation from "../../hooks/useTranslation";
// import Loader from "../../components/Loader";


function CarListPage () {
    const { user } = useTelegram();
    const {translation} = useTranslation();

    const {nfsBalance, userData, loading, error } = useUserData();
    const { dealerCars, loadingDealerCars, errorDealerCars, fetchDealerCars } = useDealerCars();
    const {garageInfo, loadingGarageInfo, errorGarageInfo, fetchGetGarageInfo} = useGarageInfo(false);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [showCarBuyModal, setShowCarBuyModal] = useState(false);
    const [carInfo, setCarInfo] = useState(null);


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const navigate = useNavigate();
    const openModal = (item) => {
        setShowCarBuyModal(true)
        setCarInfo({
            car_name: item.name,
            carTypeId: item?.typeId
        })
    }
    const redirectToWalletPage = () => {
        window.location.href = '/wallet';
    };

    const redirectToBalancePage = () => {
        window.location.href = '/balanceNfs';
    };
    return (
        <>
            {loading &&
                <LoaderComponent/>
            }

            <div className="car_dealership_page_wrapper" id='car_list'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>

                            <GazAmount userData={userData}/>
                        </div>

                    </div>
                    <div className="general_page_item">
                        <Link to={'/'} className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="car_dealership_page_items_wrapper blur-bg">
                    <div className="car_dealership_page_item carlist">
                        <div className='car_dealership_title_wrapper'>
                            <div className='car_dealership_title_wrapper_child'>
                                <h1 className='car_dealership_title'>{translation?.list_of_cars}</h1>
                            </div>

                        </div>
                        {/*<button*/}
                        {/*    className='car_dealership_page_close_btn'*/}
                        {/*    onClick={() => {*/}
                        {/*        navigate(-1)*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <CloseIcon/>*/}
                        {/*</button>*/}
                        <div className='car_dealership_details_items_wrapper scrl-list'>
                            {garageInfo && garageInfo?.cars.map((item, index) => {
                                return (
                                    <div
                                        key={index} className={`${item.purchased ? 'car_dealership_details_item_purchased' : 'car_dealership_details_item'}`}
                                    >
                                        <div className="car_dealership_details_item_img new-image-type-wrapper">
                                            <img className={'new-image-type-wrapper-bg'} src={require('../../assets/images/profile/fav_auto.png')} alt=""/>

                                            <img className={'new-image-type-img-absolute'} src={`${process.env.REACT_APP_IMG_DOMAIN}${item?.imagePath}`} alt=""/>
                                        </div>

                                        <div className='car_dealership_details_item_info_main_box' style={{paddingBottom: 7}}>
                                            <div className="car_dealership_details_item_name_hp_info_wrapper">
                                                <h1 className="car_dealership_details_item_name">{item.name}</h1>
                                                <div className='car_dealership_details_item_hp_info_box'>
                                                    <div className='car_dealership_details_item_hp_info_box_child'>
                                                        <p className='car_dealership_details_item_hp_info'>{item.horsePower} HP</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*{item?.stage != 0 &&*/}
                                                <div className='car_dealership_details_item_btn_nfs_info_wrapper'>
                                                    <button
                                                        className={`car_dealership_details_item_btn ${item?.stage == 4 ? 'car_dealership_details_item_btn_red' : ''}`}
                                                    >
                                                        {item?.stage != 0 ? `Stage ${item?.stage}` : 'Free'}

                                                    </button>
                                                </div>
                                            {/*}*/}

                                        </div>


                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    <div className="car_dealership_page_footer">
                        <button
                            className="car_dealership_page_footer_back_btn"
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            {/*<img src={require('../../assets/images/play_button2.png')} alt=""/>*/}
                            {translation?.back_to_garage}
                        </button>
                    </div>
                    <CarDealerShipModal
                        isActive={showCarBuyModal}
                        onClose={() => {
                            setShowCarBuyModal(false)
                        }}
                        onSuccessClose={() => {
                            fetchDealerCars()
                            setShowCarBuyModal(false)
                        }}
                        carData={carInfo}
                    />
                </div>


            </div>
        </>

    );
}

export default CarListPage;


import React, {useEffect, useState,} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/wallet.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {ChangeIcon} from "../../components/icons/ChangeIcon";
import {InputNfsIcon} from "../../components/icons/InputNfsIcon";
import TonConnectModal from "../../components/TonConnectModal";
import PayTypeModal from "../../components/PayTypeModal";
import useUserData from "../../hooks/useUserData";
import useDealerCars from "../../hooks/useDealerCars";
import useNFSTONCurrencies from "../../hooks/useNFSTONCurrencies";
import GazAmount from "../../components/GazAmount";

import {useTonConnectModal, useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {stringToBase64, convertNfsToTon} from "../../utils/format";
import {buyNFS, byDealerCar, updateTonWallet} from "../../utils/userApi";
import LoaderComponent from "../../components/Loader";
import {CloseIcon} from "../../components/icons/CloseIcon";
import useGetProfStat from "../../hooks/useGetProfStat";
import useTranslation from "../../hooks/useTranslation";


function WalletPage () {
    const navigate = useNavigate();
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const { user, webApp, triggerVibration } = useTelegram();
    const { nfsBalance, userData, loading, error } = useUserData();
    const { NFSTONCurrencies, loadingNFSTONCurrencies, errorNFSTONCurrencies, fetchNFSTONCurrencies } = useNFSTONCurrencies();
    const { profStat, loadingProfStat, errorProfStat, fetchProfStat } = useGetProfStat();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [nfsValue, setNfsValue] = useState('');
    const [convertedTon, setConvertedTon] = useState('');

    const [payData, setPayData] = useState(null);
    const [showPayTypeModal, setShowPayTypeModal] = useState(false);

    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();



    // const [tonConnectUI, setOptions] = useTonConnectUI();

    // const onLanguageChange = (lang) => {
    //     setOptions({ language: lang  });
    // };


    useEffect(()=>{

        if (userFriendlyAddress) {
            console.log('userFriendlyAddress', userFriendlyAddress);
            handleUpdateTonWallet();
        }

    }, [userFriendlyAddress])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        console.log(NFSTONCurrencies, 'NFSTONCurrencies')
    }, [NFSTONCurrencies]);

    const handleUpdateTonWallet = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        const {error, data} = await updateTonWallet({initData:init_data, tonAddress: userFriendlyAddress} );

        if (error) {
            console.log('ERRROR!', error);
        } else {
            console.log(data, 'handleUpdateTonWallet')
        }
    }


    const handleBuyNFS = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        const {error, data} = await buyNFS({initData:init_data, amount: nfsValue} );

        if (error) {
            console.log('ERRROR!', error);
        } else {
            console.log(data, 'handleBuyNFS')

            setPayData(data)
            setShowPayTypeModal(true)
            // const body = beginCell()
            //     .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
            //     .storeStringTail(data?.payload) // write our text comment
            //     .endCell();


            // navigate(data?.link)
            // window.location = data?.link;
        }

    }

    return (
        <>
            {( loading || loadingNFSTONCurrencies || loadingProfStat ) && (
                <LoaderComponent/>
            )}
            <div className="wallet_page_wrapper" id='wallet_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                                // onClick={() => {
                                //     redirectToSettingsPage()
                                // }}
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>
                        </div>
                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item">
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>
                    </Link>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <div className='general_page_item_top_child_icon' id='general_page_item_top_child_icon2'>
                                <WalletIcon/>
                            </div>
                        </div>
                    </div>

                </div>


                {userFriendlyAddress ?
                    <div className="wallet_main_wrapper">
                        <div className="wallet_change_info_btn_wrapper ton">
                            <span className={'ton-balance-title'}>
                                {translation?.balance2}:
                            </span>

                            <div className={'ton-balance-right'}>
                                <span>{profStat?.tonBalance}</span>
                                <img src={require('../../assets/images/tonIcon.png')} alt=""/>
                            </div>
                        </div>
                        <div className="wallet_change_info_btn_wrapper">
                            <p className="wallet_change_info">{userFriendlyAddress}</p>
                            <button
                                onClick={async () => {
                                    triggerVibration('heavy')
                                    if (userFriendlyAddress) {
                                        await tonConnectUI.disconnect();
                                    }
                                }}
                                className="wallet_change_btn"
                            >
                                <ChangeIcon/>
                            </button>
                        </div>
                        <div className="wallet_input_fields_wrapper">

                            <div className='wallet_input_fields_wrapper_title_box'>
                                <p className='wallet_input_fields_wrapper_title'>{translation?.wallet}</p>
                            </div>

                            <button
                                className='gus_up_page_main_item2_close_btn'
                                onClick={() => {
                                    triggerVibration('heavy')
                                    navigate('/')
                                }}
                            >
                                <CloseIcon/>
                            </button>

                            <div className="wallet_input_field_box">
                                <div className="wallet_input_field_title_icon_wrapper">
                                    <div className="wallet_input_field_icon">
                                        <InputNfsIcon/>
                                    </div>
                                </div>
                                <div className='wallet_input_field_box_info_item'>
                                    <input
                                        type={'number'}
                                        placeholder={'0'}
                                        value={nfsValue}
                                        className='wallet_input_field_info1 input'
                                        onChange={(e) => {
                                            setNfsValue(e.target.value)
                                            let convertNfsToTon_ = convertNfsToTon(e.target.value, NFSTONCurrencies?.nfsIn1Ton);
                                            setConvertedTon(convertNfsToTon_);
                                        }}
                                    />
                                    <span className='wallet_input_field_name'>~{NFSTONCurrencies?.nfsIn1Ton} TON</span>
                                </div>

                            </div>

                            <div className="wallet_input_field_box">
                                <div className="wallet_input_field_title_icon_wrapper">
                                    <div className="wallet_input_field_img">
                                        <img src={require('../../assets/images/input_top_img.png')} alt=""/>
                                    </div>
                                    <p className="wallet_input_field_title">ton</p>
                                </div>
                                <div className='wallet_input_field_box_info_item'>
                                    <p className='wallet_input_field_info2'>{convertedTon}</p>
                                </div>
                            </div>

                        </div>
                        {/*<button onClick={() => tonConnectUI.sendTransaction(myTransaction)}>*/}
                        {/*    Send transaction*/}
                        {/*</button>*/}

                        <button
                            className='buy_nfs_button'
                            onClick={() => {
                                if (nfsValue > 0) {
                                    triggerVibration('heavy')
                                    handleBuyNFS()
                                }
                            }}
                        >
                            <div className='buy_nfs_button_child'>
                                <p className='buy_nfs_button_title'>{translation?.buy_nfs}</p>
                            </div>
                        </button>
                    </div>

                    :

                    <TonConnectModal
                        isActive={true}
                        onClose={() => {

                        }}
                    />
                }

                {showPayTypeModal &&
                    <PayTypeModal
                        data={payData}
                        isActive={true}
                        onClose={() => {
                            setShowPayTypeModal(false)
                        }}
                    />
                }

            </div>
        </>

    );
}

export default WalletPage;


export const ParticipantsIcon2 = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={11}
            fill="none"
        >
            <path fill="#2A4C0D" d="M2.783 2.639h6.124v6.124H2.783z" />
            <path
                fill="url(#v)"
                d="M3.502.446a1.688 1.688 0 0 0-.84.452c-.19.185-.25.282-.988 1.53C-.04 5.323.068 5.125.01 5.511c-.032.224.006.54.089.762.072.188 2.3 3.959 2.434 4.12.23.272.543.468.89.554.198.05.305.052 2.576.052 2.256 0 2.379-.003 2.571-.05.35-.088.655-.274.877-.535.166-.198 2.386-3.953 2.456-4.157.128-.37.128-.74 0-1.112-.067-.198-2.29-3.961-2.443-4.138a1.85 1.85 0 0 0-.842-.54C8.483.422 8.315.417 6.081.412 4.044.41 3.662.413 3.5.446Zm2.827 2.45a1.3 1.3 0 0 1 .98 1.41c-.04.316-.232.655-.472.843a1.41 1.41 0 0 1-1.029.271c-.9-.148-1.384-1.056-.984-1.847.166-.332.474-.58.832-.673.19-.05.483-.053.673-.003Zm.874 3.01c.47.151.775.444.957.913.064.165.067.212.08.78l.01.609H3.778l.01-.608c.011-.439.025-.637.054-.723.19-.561.628-.932 1.208-1.018.075-.013.55-.018 1.058-.016.86.005.933.01 1.096.063Z"
            />
            <defs>
                <linearGradient
                    id="v"
                    x1={0}
                    x2={12}
                    y1={5.706}
                    y2={5.706}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>
    )

}


import {useState, useEffect, useRef} from 'react';
import {buySpin, getAccount} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useBuySpin = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [buySpinAnswer, setBuySpinAnswer] = useState(null);
    const [loadingBuySpin, setLoadingBuySpin] = useState(true);
    const [errorBuySpin, setErrorBuySpin] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        // if (process.env.REACT_APP_DEBUG == 'true') {
        //     if (!isMounted.current) {
        //         fetchUserData();
        //         isMounted.current = true;
        //     }
        // } else {
        //
        //     if (webApp?.initData && webApp?.initData != '') {
        //         fetchUserData()
        //     }
        // }


    }, [webApp?.initData]);

    const fetchBuySpin = async (amount) => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await buySpin({
            initData:init_data,
            amount:amount
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorBuySpin(error);
            setLoadingBuySpin(false);

        } else {
            setBuySpinAnswer(data)
            setErrorBuySpin(null);
            setLoadingBuySpin(false);
            console.log(data, 'data')

        }

    };


    return {buySpinAnswer,  loadingBuySpin, errorBuySpin, fetchBuySpin };
};

export default useBuySpin;

export const InputNfsIcon2 = ({}) => {

    return (
        <svg width="64" height="13" viewBox="0 0 64 13" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path
                d="M4.00276 5.24854C-0.214234 12.6021 -0.161957 12.515 0.0994273 12.3408C0.238832 12.2711 1.40635 11.5043 2.69584 10.6505L5.0483 9.11703L6.79086 6.03269L8.55085 2.94836L10.7813 2.89608C13.2209 2.84381 13.1164 2.80896 12.7156 3.80222C12.6284 3.9939 13.3952 3.57568 14.7369 2.68698C15.9219 1.90283 16.9674 1.18838 17.0545 1.10125C17.1417 1.03154 17.0023 0.770161 16.7583 0.526202L16.3052 0.0731363H11.6352H6.96512L4.00276 5.24854Z"
                fill="url(#paint0_linear_490_4466)"/>
            <path
                d="M14.1097 4.4817C10.6594 6.7993 7.40085 8.99493 6.87809 9.34344L5.91968 10.0056H9.43965H12.9596L14.6325 7.07811C16.2879 4.15061 16.3053 4.13319 18.379 2.2338C20.2435 0.560937 20.592 0.212424 20.4352 0.264702C20.4004 0.264702 17.56 2.16409 14.1097 4.4817Z"
                fill="url(#paint1_linear_490_4466)"/>
            <defs>
                <linearGradient id="paint0_linear_490_4466" x1="0" y1="6.2309" x2="17.0806" y2="6.2309"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint1_linear_490_4466" x1="5.91968" y1="5.13272" x2="20.4686" y2="5.13272"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
            </defs>
        </svg>

    )

}

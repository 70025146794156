const translations = {
    ENG: {
        play: "PLAY",
        profile: "Profile",
        rating: "Rating",
        frens: "Frens",
        p2p: "P2P",
        car_dealership: "Car\ndealership",
        garage: "Garage",
        car_market: "Car\nmarket",
        exhibition: "Exhibition",
        balance: "BALANCE NFS",
        back_to_home: "BACK TO HOME",
        support: "SUPPORT",
        project_presentation: "PROJECT PRESENTATION",
        language: "LANGUAGE",
        gus_up: "Gus Up",
        gus_up_desc: "Each race consumes 1 unit of gasoline. Replenish your gasoline supply to participate in the races",
        gus_up_title: "You can buy gasoline",
        gus_up_subtitle: "NFS for 1 gasoline",
        buy_gas: "buy gas",
        ton_connect_title: 'Your wallet is not connected to TON Space',
        ton_connect_desc: 'Connect your wallet and start using all the features of the crypto industry',
        ton_connect_btn: 'Connect',
        buy_nfs: "BUY NFS",
        wallet: "WALLET",
        balance2: "Balance",
        general_statistics: "General Statistics",
        win_percentage: "Win Percentage:",
        achievements: "Achievements",
        TON_balance: "TON Balance",
        total_nfs_earned: "Total NFS Earned",
        game_Rating: "Game Rating",
        spent_on_cars: "Spent on Cars",
        total_races_wins: "Total Races/Wins",
        favorite_car: "Favorite Car",
        power: "Power",
        tuning: "tuning",
        the_most_popular_mode: "the most popular mode",
        reward: "Reward",
        line: "Line",
        partners: "Partners",
        data_not_found: "Data not found",
        invite_frens: "invite frens",
        rurchased: "Purchased",
        buy: "Buy",
        you_dont_have_enough: "You don't have enough",
        to_buy_this_car: "to buy this car",
        you_can_earn_NFS_in_the_game_or_buy_it_in_the_store: "You can earn NFS in the game or buy it in the store",
        CLICK_TO_START_PLAYING: "CLICK TO START PLAYING",
        list_of_cars: "list of cars",
        settings: "Settings",
        back_to_garage: "BACK TO GARAGE",
        do_you_want_to_buy_this_package: "Do you want to buy this package?",
        apply: "apply",
        no:"no",
        yes:"yes",
        day: "DAY",
        daily_reward: "daily reward",
        daily_reward_desc: "Collect coins for daily entry into the game without skipping. The pick up button must be pressed daily, otherwise the day counter will start over",
        come_back_tomorrow: "Come back tomorrow",
        get_reward: "Get reward",
        you_have_spins: "you have spins",
        SPIN: "SPIN",
        text1: "Buy more spins or share the link with your friends to win more prizes",
        text2: "AND A SECRET EXCLUSIVE CAR OF 10 PIECES",
        text3: "Share your invitation link and get your spins for it",
        you_can_buy_spin: "You can buy spin",
        NFS_for_1_spin: "NFS for 1 spin",
        buy_spin: "Buy Spin",
        text4: "Congratulations! You wonna prize in the wheel of fortune",
        text5: "Do you really wanna upgrade your car and spend",
        text6: "nfs on it",
        text7: "Do you really want to put a car on the exhibition and receive a passive income",
        text8: "After being placed at the exhibition, you will not be able to drive this car for the specified period!",
        text9: "You can't buy",
        text10: "for more than 5",
        continue: "continue",
        text11: "Congratulations! You bought",
        text12: "You can continue to compete with other players",
        text13: "your presentation has been added to the",
        telegram_chat: "telegram chat",
        text14: "After clicking the button, you can download the presentation in the telegram welcome window",
        OKAY: "OKAY",
        text15: "You have completed all the missions",
        text16: "Come back tomorrow to check if there are any new missions",
        to_make_the_race: "to make the race",
        text17: "You can wait until the gasoline is restored or buy it at the store",
        choose_a_payment_method: "choose a payment method",
        text18: "Select the payment method you want to use",
        before_you_play: "Before You Play",
        text19: "Follow our Telegram channel for the",
        text20: "latest game news and updates.",
        subscribe: "subscribe",
        text21: "Congratulations! You won a prize in the wheel of fortune",
        text22: "Having started the drift, do you get ? Coins and 10 ratings, if defeated - 10 ratings",
        you_won: "you won",
        you_have_won: " you have won",
        you_lose: "you lose",
        you_have_lose: "you have lose",
        search_for_player: "search for player",
        fight: "fight",
        missions: "missions",
        text23: "Complete missions every day and get rewards for it",
        game_mode: "GAME MODE",
        text24: "Complete missions every day and get rewards for it",
        play_online: "play online",
        text25: "Drift races focus on maintaining control while drifting behind other racers for extra points",
        choose_car: "CHOOSE A CAR",
        per_day: "per day",
        back_to_expo: "BACK TO EXHIBITION",
        you_can_change_car: "You can change your car in",
        days: "days",
        text_add_to_expo_modal: "Do you really want to put a car on the exhibition and receive a passive income of ",
        text_add_to_expo_modal2: "After being placed at the exhibition, you will not be able to drive this car for a 10-day period!",


        red_nfs_modal_text1: "Unlock the opportunity to earn NFS tokens every 8 hours automatically by participating in our exhibition! Enjoy a daily yield of 2.1% in NFS coins, enhancing your gaming experience without active engagement.",
        red_nfs_modal_text2: "Minimum commitment? Just 10 days to display your car and start reaping the benefits!",
        on: "ON",
        off: "OFF",

        info_about_profile_text: `You can go to the player's profile, where they can view statistics on their races, how much money they have in their TON and NFS accounts, how much they have invested, how many races they have had, how many of them they won, and their win percentage. - They can also go to the Wheel of Fortune here, win for inviting friends or buying spins: tokens, 3 lots with different quantities and different probabilities of winning; Stage 1-4 for upgrades; 1 out of 10 exclusive cars (one each), available to win only in the wheel. If they invite 100 players at once, they simply see the result after the spin, how much they won in total, so they don't have to spin 100 times, and at their discretion, they can click to spin the wheel or use all chances. They can buy a spin for 0.1 TON - Go to P2P to buy/sell tokens from other players.`,
        info_about_reyting_text: `Pressing the Ranking button opens a list of the top 100 participants with their balances, number of races, and win percentage. You can go to the player's profile`,
        info_about_frens_text: `When you go to this section, you'll see the player's Photo, which is pulled from Telegram (you can click and change it directly in the game); Name (you can also change it); number of invited players, how much they have earned from the Partner Network; distribution of players across the lines (5 are displayed, but you can expand to 15); earnings percentage from each line; a "More" button, which opens a Pop-Up window with a detailed description of what bonuses are given for the number of invitations, earnings from top-ups, income from income, leadership bonuses, and also bonuses for the Wheel of Fortune`,
        info_about_settings_text: `Above Profile - expands sections on the main page`,
        info_about_diller_text: `The in-game store where players buy cars at pre-sale prices, like from a showroom`,
        info_about_garage_text: `Going to this section, players can choose a car they have in stock, buy another slot for a new car, buy Stages to improve the car's performance, with visual changes to the exterior`,
        info_about_autorinok_text: `This is the car market, where players can buy their desired car from other players. By clicking the "Buy a car" button, the player is presented with a list of all cars for sale, they choose the desired model and then see a list of orders, starting with the floor. Similarly with the "Sell a car" tab. Quick sell - quick sale of a car for 40% of its value, the car is credited to the main game account (what should we call it?)`,
        info_about_expo_text: `This section helps players earn passive income by putting their Stage 4-level car on display. They lock the sports car for the chosen time, and depending on the time, they receive a certain percentage of NFS tokens`,
        info_about_text1: `PLAY and RACE, DRIFT, RING RACE, OFF-ROAD, ONLINE  - these are different types of races, only when you press PLAY, you choose the type of race, then a timer with the time to find an opponent appears, after the selection, a window appears with the race, where two players are opposite each other, you are given a choice, either continue searching or start the race. This window also shows how much gasoline the player has, each race uses 1 unit of gasoline, 1 unit recharges in 1 hour, a total of 5 hours, you can refuel for 0.5 TON`,
        info_about_text2: `The races differ in that there is a regular race, and there are paid modes that players unlock for TON, the probability of winning depends on the power of the car and the characteristics depending on what race is needed,`,

        remove_car_from_expo: "REMOVE CAR",
        number_of_victories:"Number of victories",
        recharge: "recharge",
        participants: "Participants",
        extra: "Extra",
        spots_left: "spots left",
        come_back_tommorow: "Your reward is received, come back tomorrow",
        сar_spots: "Car spots",
        count: "PC",
        buy_garage_space: "Do you really want to buy a garage space?",
        inventory: "Inventory",
        not_slot_modal: "You don't have any slots in the garage so you can buy this car",
        car_priz_modal: "Congratulations! You won a secret prize in the wheel of fortune",
        go_back: "Go Back",
        sell: "SELL",
        you_have_coins: "You have coins",
        minimum_sale_amount: "Minimum sale amount:",
        sales_commission: "Sales commission:",
        current_minimum_price: "Current minimum price",
        price_of_you: "Price of your",
        price: "PRICE",
        gaz_fee: "Gaz Fee",
        total: "Total",
        buy_commission: "Purchase commission",
        confirm_delete_order: "Do you really want to delete the order from the p2p platform?",

        airdrop_balance: "Airdrop balance",
        available_for_withdrawal: "Available for withdrawal",
        p2p_balance: "Balance",

        sale: "Sale",
        profit: "Profit",

        all: "ALL",
        my_trades: "MY TRADES",
        confirm_sell_coins: "Are you sure you want to sell the coins?",

        withdrawal_amount_error: "Сумма вывода не может быть \n меньше:",
        price_per_nfs_error: "Цена за 1 NFS не может быть \n меньше: 0.00001 TON"
    },
    RU: {
        play: "Играть",
        profile: "Профиль",
        rating: "Рейтинг",
        frens: "Frens",
        p2p: "P2P",
        car_dealership: "Дилерский\nцентр",
        garage: "Гараж",
        car_market: "Авто\nрынок",
        exhibition: "Выставка",
        balance: "БАЛАНС NFS",
        back_to_home: "ВЕРНУТЬСЯ НА ГЛАВНУЮ",
        support: "ТЕХ. ПОДДЕРЖКА",
        project_presentation: "ПРЕЗЕНТАЦИЯ ПРОЕКТА",
        language: "ЯЗЫК",
        gus_up: "Заправиться",
        gus_up_desc: "Каждая гонка потребляет 1 единицу бензина. Пополняйте запас бензина, чтобы участвовать в гонках.",
        gus_up_title: "Вы можете купить бензин",
        gus_up_subtitle: "NFS за 1 бензин",
        buy_gas: "Купить газ",
        ton_connect_title: "Ваш кошелек не подключен к TON Space",
        ton_connect_desc: "Подключите свой кошелек и начните использовать все возможности криптоиндустрии",
        ton_connect_btn: "Подключить",
        buy_nfs: "КУПИТЬ NFS",
        wallet: "Кошелек",
        balance2: "Баланс",
        general_statistics: "Статистика",
        win_percentage: "Процент побед:",
        achievements: "Достижения",
        TON_balance: "Баланс TON",
        total_nfs_earned: "Всего заработано NFS",
        game_Rating: "Рейтинг игры",
        spent_on_cars: "Потрачено на автомобили",
        total_races_wins: "Всего гонок/побед",
        favorite_car: "Любимая машина",
        power: "Мощность",
        tuning: "Тюнинг",
        the_most_popular_mode: "самый популярный режим",
        reward: "Награда",
        line: "Линия",
        partners: "Партнеры",
        data_not_found: "Нет данных",
        invite_frens: "Пригласить друзей",
        rurchased: "Куплено",
        buy: "Купить",
        you_dont_have_enough: "У вас недостаточно",
        to_buy_this_car: "чтобы купить эту машину",
        you_can_earn_NFS_in_the_game_or_buy_it_in_the_store: "Вы можете заработать NFS в игре или купить его в магазине.",
        CLICK_TO_START_PLAYING: "НАЖМИТЕ ЧТО БЫ НАЧАТЬ ИГРУ",
        list_of_cars: "Список машин",
        settings: "Настройки",
        back_to_garage: "Назад в гараж",
        do_you_want_to_buy_this_package: "Хотите купить этот пакет?",
        apply: "подтвердить",
        no: "Нет",
        yes: "Да",
        day: "ДЕНЬ",
        daily_reward: "ежедневное вознаграждение",
        daily_reward_desc: "Собирайте монеты за ежедневный вход в игру без пропусков. Кнопку получения необходимо нажимать ежедневно, иначе счетчик дней начнется заново",
        come_back_tomorrow: "Возвращайтесь завтра",
        get_reward: "Получите награду",
        you_have_spins: "Доступных спинов",
        SPIN: "СПИН",
        text1: "Купите больше вращений или поделитесь ссылкой с друзьями, чтобы выиграть больше призов",
        text2: "И СЕКРЕТНЫЙ ЭКСКЛЮЗИВНЫЙ АВТОМОБИЛЬ ИЗ 10 ШТУК",
        text3: "Поделитесь ссылкой-приглашением и получите за нее спины",
        you_can_buy_spin: "Вы можете купить спин",
        NFS_for_1_spin: "NFS за 1 спин",
        buy_spin: "Купить спин",
        text4: "Поздравляем! Вы выиграли приз в колесе фортуны",
        text5: "Вы действительно хотите купить STAGE за",
        text6: "NFS для улучшения своей машины?",
        text7: "Вы действительно хотите разместить машину на выставку и получать пассивный доход?",
        text8: "После размещения на выставке вы не сможете ездить на этой машине в течение указанного периода!",
        text9: "Вы не можете купить",
        text10: "больше чем 5",
        continue: "Продолжить",
        text11: "Поздравляем! Вы купили",
        text12: "Вы можете продолжать соревноваться с другими игроками",
        text13: "Ваша презентация была отправлена в",
        telegram_chat: "чат телеграмм",
        text14: "После нажатия кнопки вы сможете загрузить презентацию в приветственном окне Telegram",
        OKAY: "ОК",
        text15: "Вы выполнили все миссии",
        text16: "Возвращайтесь завтра, чтобы проверить, есть ли новые миссии",
        to_make_the_race: "устроить гонку",
        text17: "Вы можете подождать, пока бензин восстановится, скорость - 1 единица в час, или купить его в магазине",
        choose_a_payment_method: "выберите метод платежа",
        text18: "Выберите способ оплаты, который вы хотите использовать",
        before_you_play: "Прежде чем начать играть",
        text19: "Подпишитесь на наш канал Telegram, чтобы узнать последние новости и обновления игры",
        text20: "последние новости и обновления игры",
        subscribe: "подписаться",
        text21: "Поздравляем! Вы выиграли приз в колесе фортуны",
        text22: "За победу в гонке вы получаете 10.000 NFS + 10 XP, при поражении вы получите только 10 XP",
        you_won: "выигрыш",
        you_have_won: "вы выиграли",
        you_lose: "проигрыш",
        you_have_lose: "вы проиграли",
        search_for_player: "поиск игрока",
        fight: "Бой",
        missions: "Задачи",
        text23: "Выполняйте миссии каждый день и получайте за это награды",
        game_mode: "Режимы игры",
        text24: "Выполняйте миссии каждый день и получайте за это награды",
        play_online: "играть онлайн",
        text25: "В дрифт-гонках основное внимание уделяется сохранению контроля над ситуацией во время дрифта за другими гонками для получения дополнительных очков.",
        choose_car: "ВЫБРАТЬ МАШИНУ",
        per_day: "каждый день",
        back_to_expo: "НАЗАД В ВЫСТАВКУ",
        you_can_change_car: "Вы можете поменять вашу машину через",
        days: "дней",
        text_add_to_expo_modal: "Вы действительно хотите разместить машину на выставку и получать пассивный доход в размере ",
        text_add_to_expo_modal2: "После размещения на выставке вы не сможете управлять этим автомобилем в течение 10 дней!",
        red_nfs_modal_text1: "Выставка даёт вам возможность получать начисления токенов NFS каждые 8 часов, без вашего участия в игре. Доходность выставки составляет 2.1% в монетах NFS ежедневно.",
        red_nfs_modal_text2: "Минимальный срок размещения вашего автомобиля на выставке 10 дней!",
        on: "ВКЛ",
        off: "ВЫКЛ",

        info_about_profile_text: `Вы можете зайти в профиль игрока, где он может посмотреть статистику по своим гонкам, сколько денег у него на счетах TON и NFS, сколько он инвестировал, сколько у него было гонок, сколько из них он выиграл и процент побед. - Здесь же они могут зайти в Колесо Фортуны, выиграть за приглашение друзей или покупку вращений: жетоны, 3 лота с разным количеством и разной вероятностью выигрыша; Этап 1-4 для улучшений; 1 из 10 эксклюзивных автомобилей (по одному на каждую), доступных для выигрыша только в колесе. Если они приглашают 100 игроков сразу, они просто видят результат после вращения, сколько они выиграли в общей сложности, поэтому им не нужно вращать 100 раз, и по своему усмотрению они могут нажать, чтобы вращать колесо или использовать все шансы. Они могут купить спин за 0,1 TON - Перейдите в P2P, чтобы купить/продать токены у других игроков.`,
        info_about_reyting_text: `Нажатие кнопки «Рейтинг» открывает список 100 лучших участников с их балансами, количеством гонок и процентом побед. Вы можете перейти в профиль игрока`,
        info_about_frens_text: `При переходе в этот раздел вы увидите фотографию игрока, которая загружается из Telegram (вы можете нажать и изменить ее прямо в игре); Имя (вы также можете изменить его); количество приглашенных игроков, сколько они заработали в Партнерской сети; распределение игроков по линиям (отображается 5, но вы можете расширить до 15); процент заработка с каждой линии; кнопка «Еще», которая открывает всплывающее окно с подробным описанием того, какие бонусы даются за количество приглашений, заработок от пополнений, доход от дохода, лидерские бонусы, а также бонусы за Колесо Фортуны`,
        info_about_settings_text: `Над Профилем - раскрывает разделы на главной странице`,
        info_about_diller_text: `Внутриигровой магазин, где игроки покупают автомобили по предпродажным ценам, как в автосалоне`,
        info_about_garage_text: `Перейдя в этот раздел, игроки могут выбрать автомобиль, который у них есть в наличии, купить еще один слот для нового автомобиля, купить этапы для улучшения характеристик автомобиля, с визуальными изменениями внешнего вида`,
        info_about_autorinok_text: `Это рынок автомобилей, где игроки могут купить желаемый автомобиль у других игроков. Нажав кнопку «Купить автомобиль», игроку предоставляется список всех автомобилей на продажу, он выбирает нужную модель и далее видит список заказов, начиная с этажа. Аналогично с вкладкой «Продать машину». Быстрая продажа — быстрая продажа машины за 40% от ее стоимости, машина зачисляется на основной игровой счет (как бы это назвать?)`,
        info_about_expo_text: `Этот раздел помогает игрокам получать пассивный доход, выставляя свою машину уровня Stage 4 на обозрение. Они блокируют спорткар на выбранное время, и в зависимости от времени получают определенный процент токенов NFS`,
        info_about_text1: `ИГРАТЬ и ГОНКА, ДРИФТ, КОЛЬЦО, БЕЗДОРОЖЬЕ, ОНЛАЙН — это разные типы гонок, только при нажатии ИГРАТЬ вы выбираете тип гонки, затем появляется таймер со временем на поиск соперника, после выбора появляется окно с гонкой, где два игрока находятся друг напротив друга, вам предоставляется выбор, либо продолжить поиск, либо начать гонку. В этом окне также отображается, сколько бензина у игрока, на каждую гонку расходуется 1 единица бензина, 1 единица заряжается за 1 час, всего 5 часов, заправиться можно за 0,5 TON`,
        info_about_text2: `Гонки отличаются тем, что есть обычная гонка, а есть платные режимы, которые игроки разблокируют за TON, вероятность победы зависит от мощности автомобиля и характеристик в зависимости от того, какая гонка нужна`,
        remove_car_from_expo: "УДАЛИТЬ МАШИНУ С ВЫСТАВКИ",
        number_of_victories:"Количество побед",
        recharge: "Пополнить",
        participants: "Участники",
        extra: "Доп.",
        spots_left: "свободных мест",
        come_back_tommorow: "Ваша награда получена, возвращайтесь завтра",
        сar_spots: "АВТО СЛОТЫ",
        count: "ШТ",
        buy_garage_space: "Вы действительно хотите купить гаражное место?",
        inventory: "Инвертарь",
        not_slot_modal: "У вас нет слотов в гараже, поэтому вы не можете купить эту машину",
        car_priz_modal: "Поздравляю! Вы выиграли секретный приз в колесе фортуны",
        go_back: "ВЕРНУТЬСЯ НАЗАД ",
        sell: "ПРОДАТЬ",
        you_have_coins: "У вас монет",
        minimum_sale_amount: "Минимальная сумма продажи:",
        sales_commission: "Комиссия за продажу:",
        buy_commission: "Комиссия за покупку:",
        current_minimum_price: "Текущая минимальная цена",
        price_of_you: "цена вашего",
        price: "ЦЕНА",
        gaz_fee: "КОМИСИЯ",
        total: "Итог",
        confirm_delete_order: "Вы действительно хотите удалить заказ с p2p платформы?",

        airdrop_balance: "Airdrop баланс",
        available_for_withdrawal: "Доступно для вывода",
        p2p_balance: "Баланс",

        sale: "Продажа",
        profit: "Прибыль",

        all: "ВСЕ",
        my_trades: "МОИ СДЕЛКИ",
        confirm_sell_coins: "Вы уверены, что хотите продать монеты?",
        withdrawal_amount_error: "Withdrawal amount cannot be \n less than:",
        price_per_nfs_error: "Price per 1 NFS cannot be \n less than: 0.00001 TON"
    }
};

export default {translations}

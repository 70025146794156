export function stringToBase64(str = '') {
    if (str != '') {
        return btoa(unescape(encodeURIComponent(str)));
    } else {
        return btoa(unescape(encodeURIComponent(`query_id=AAGvD3EXAAAAAK8PcRfDF_oE&user=%7B%22id%22%3A393285551%2C%22first_name%22%3A%22Andrey%22%2C%22last_name%22%3A%22Artemev%22%2C%22username%22%3A%22limjn%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1723390831&hash=754f03c582050508444fef9d27fc2c41e0d217e2a48ab6cca0862a05c3b56e22`)));
    }
}


/**
 * Конвертирует NFS в TON.
 * @param {number} nfsAmount - Количество NFS для конвертации.
 * @returns {number} - Количество TON.
 */
export function convertNfsToTon(nfsAmount, conversionRate = 1000000) {
    if (nfsAmount < 0) {
        throw new Error("Количество NFS не может быть отрицательным.");
    }
    const tonAmount = nfsAmount / conversionRate;
    return tonAmount;
}


/**
 * Конвертирует SPIN в Nfs.
 * @param {number} spinAmount - Количество SPIN для конвертации.
 * @returns {number} - Количество TON.
 */
export function convertSpinToNfs(spinAmount, conversionRate = 1000000) {
    if (spinAmount < 0) {
        throw new Error("Количество NFS не может быть отрицательным.");
    }
    const nfsAmount = spinAmount * conversionRate;
    return nfsAmount;
}



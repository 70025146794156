export const AddPetrolIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
        >
            <g filter="url(#g)">
                <circle cx={7.5} cy={7.5} r={7.5} fill="#EBEBEB" fillOpacity={0.48} />
            </g>
            <path stroke="#F8FFF3" strokeWidth={1.428} d="M7.5 10V5M4.974 7.499h5.05" />
            <defs>
                <filter
                    id="g"
                    width={52.2}
                    height={52.2}
                    x={-18.6}
                    y={-18.6}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation={9.3} />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_522_87"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_522_87"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={2.278} />
                    <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect2_innerShadow_522_87" />
                </filter>
            </defs>
        </svg>
    )

}

export const SettingsLockIcon = () => {

    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={17}
            fill="none"
        >
            <path
                fill="#fff"
                d="M5.955.022C4.092.26 2.502 1.556 1.923 3.312c-.206.623-.23.821-.25 2.066l-.02 1.173-.368.02c-.325.02-.396.036-.622.159a1.224 1.224 0 0 0-.397.333c-.285.372-.273.09-.261 4.713l.012 4.162.087.178c.123.246.376.496.642.627l.222.107h11.14l.221-.107c.266-.131.52-.38.642-.627l.087-.178.012-4.162c.012-4.638.028-4.357-.265-4.73a1.31 1.31 0 0 0-.4-.32c-.219-.12-.298-.14-.619-.155l-.365-.02-.02-1.173c-.015-.892-.035-1.245-.087-1.47-.44-1.979-1.89-3.426-3.833-3.822-.385-.08-1.15-.111-1.526-.064Zm1.252 2.284c.706.178 1.364.717 1.69 1.383.241.492.265.642.285 1.812l.02 1.062h-5.32v-.868c0-1.245.075-1.669.4-2.208.178-.29.599-.71.908-.896a2.665 2.665 0 0 1 2.017-.285Zm-.146 7.028c.226.107.48.36.594.595.072.146.092.25.092.519 0 .29-.016.365-.107.535-.056.107-.179.27-.266.357l-.163.154v1.083c0 1.205-.007 1.252-.25 1.466a.624.624 0 0 1-.848 0c-.241-.214-.25-.261-.25-1.466v-1.083l-.162-.154a1.234 1.234 0 0 1-.329-1.273c.095-.313.46-.693.753-.785.27-.087.702-.063.936.052Z"
            />
        </svg>
    )

}

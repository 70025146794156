import React from 'react';
import {RoundIcon} from "../icons/RoundsIcon";
import {ModalCloseIcon} from "../icons/ModalCloseIcon";
import {BuyNfsIcon} from "../icons/BuyNfsIcon";
import '../../assets/css/garage.css'
import {Link, useNavigate} from "react-router-dom";
import {PetrolRedIcon} from "../icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../icons/PetrolWhiteIcon";
import {ReplenishIcon} from "../icons/ReplenishIcon";
import {useTelegram} from "../../TelegramContext";

const GazAmount = ({userData}) => {
    const navigate = useNavigate();
    const { user, triggerVibration } = useTelegram();

    return (
        <Link
            onClick={()=>{
                triggerVibration('heavy')
            }}
            to={'/gusUp'}
            className="general_page_item_top_child_icon2_wrapper "
        >
            <div className='general_page_item_top_child_icon2_wrapper_childs mr-4'>
                {Array.from({length: parseInt(userData?.gasAmount > 5 ? 5 : userData?.gasAmount)}, (v, i) => i).map((item, index) => {
                    return (
                        <div
                            key={index}
                            className='general_page_item_top_child_icon2_child1'
                        >
                            <div className="general_page_item_top_child_icon2_child1_icon">
                                <PetrolRedIcon/>
                            </div>

                        </div>
                    )
                })}
                {Array.from({length: userData?.gasAmount ? 5 - parseInt(userData?.gasAmount > 5 ? 5 : userData?.gasAmount) : 5}, (v, i) => i).map((item, index) => {
                    return (
                        <div
                            key={index}
                            className='general_page_item_top_child_icon2_child1 general_page_item_top_child_icon2_child2 mr-4'>
                            <div className="general_page_item_top_child_icon2_child1_icon">
                                <PetrolWhiteIcon/>
                            </div>

                        </div>
                    )
                })}

                <button className='general_page_item_top_child_replenish_btn rel-gaz'>
                    <ReplenishIcon/>
                </button>
            </div>
        </Link>
    );
};

export default GazAmount;

import React from 'react';
import '../../assets/css/settings.css'
import useTranslation from "../../hooks/useTranslation";

const Switch = ({ isOn, handleToggle, OnIcon, OffIcon, translation }) => {

    return (
        <div className="switch" onClick={handleToggle}>
            <div className='switch-button'>
                <div className="switch-content">
                    {isOn ? (
                        <>
                            <span className="switch-text">{translation?.on}</span>
                            <div  className={`switch-icon ${isOn ? 'switch-on' : 'switch-off'}`}>
                                <OnIcon />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={`switch-icon ${isOn ? 'switch-on' : 'switch-off'}`}>
                                <OffIcon />
                            </div>
                            <span className="switch-text">{translation?.off}</span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Switch;

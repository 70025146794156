// import React, {useEffect, useState} from 'react';
// import {RoundIcon} from "../icons/RoundsIcon";
// import {ModalCloseIcon} from "../icons/ModalCloseIcon";
// import {TonKeeper} from "../icons/TonKeeper";
// import {TonSpace} from "../icons/TonSpace";
// import {BuyNfsIcon} from "../icons/BuyNfsIcon";
// import '../../assets/css/garage.css'
// import {useNavigate} from "react-router-dom";
// import {useTonConnectModal, useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
// import {useTelegram} from "../../TelegramContext";
// import useTranslation from "../../hooks/useTranslation";
//
// const P2pPayTypeModal = ({isActive, onClose, data}) => {
//     const navigate = useNavigate();
//     const { user, triggerVibration } = useTelegram();
//     const {translation} = useTranslation();
//
//     const { open } = useTonConnectModal();
//     const userFriendlyAddress = useTonAddress();
//     const [tonConnectUI] = useTonConnectUI();
//
//     const [payData,setPayData] = useState([]);
//
//
//     useEffect(() => {
//         if (data?.ton_messages) {
//             setPayData(data?.ton_messages)
//         }
//     }, [data]);
//
//     if (!isActive) {
//         return null
//     }
//
//
//     const handleTonSpace = async () => {
//
//         try {
//             triggerVibration('heavy')
//
//             let messages = [];
//             for (let i = 0; i < payData.length; i++) {
//                 messages.push({
//                     address:payData[i]?.ton_address,
//                     amount: payData[i]?.amount,
//                     payload: payData[i]?.payload,
//                 })
//             }
//
//             const myTransaction = {
//                 validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
//                 messages: messages
//             }
//
//             tonConnectUI.sendTransaction(myTransaction)
//         } catch (e) {
//             console.log(e, 'ERROR')
//             alert('')
//         }
//     }
//
//     const handleTonKeeper = async () => {
//         triggerVibration('heavy')
//         window.location = data?.tonkeeperLink;
//
//         // data?.tonkeeperLink
//     }
//
//
//     return (
//         <div className='buy_nfs_modal PayTypeModal'>
//             <div className='buy_nfs_modal_wrapper'>
//                 <button
//                     className='buy_nfs_modal_close_btn'
//                     onClick={() => {
//                         triggerVibration('heavy')
//                         onClose()
//                     }}
//                 >
//                     <ModalCloseIcon/>
//                 </button>
//                 <p className='buy_nfs_modal_title'>
//                     {translation?.choose_a_payment_method}
//                 </p>
//                 <p className='buy_nfs_modal_info'>
//                     {translation?.text18}
//                 </p>
//                 {/*<div className='buy_nfs_modal_icon'>*/}
//                 {/*    <BuyNfsIcon/>*/}
//                 {/*</div>*/}
//                 {/*<button*/}
//                 {/*    className='buy_nfs_modal_btn'*/}
//                 {/*    onClick={() => {*/}
//                 {/*        // onClose()*/}
//                 {/*        open()*/}
//                 {/*    }}*/}
//                 {/*>*/}
//                 {/*    <p className='buy_nfs_modal_btn_text'>Connect</p>*/}
//                 {/*</button>*/}
//
//
//                 <div className={'pay-type-wrapper'}>
//                     <div style={{opacity:0.2}}  className={'pay-type-left'}>
//                         <img src={require('../../assets/payType/Tonkeeper.png')} alt=""/>
//                         <button
//                             // onClick={handleTonKeeper}
//                         >
//                             <TonKeeper/>
//                             <span>TONKEEPER</span>
//                         </button>
//                     </div>
//                     <div  className={'pay-type-right'}>
//                         <img src={require('../../assets/payType/TonSpace.png')} alt=""/>
//                         <button onClick={handleTonSpace}>
//                             <TonSpace/>
//                             <span>TON SPACE</span>
//                         </button>
//                     </div>
//                 </div>
//
//
//             </div>
//
//         </div>
//     );
// };
//
// export default P2pPayTypeModal;


import React, { useEffect, useState } from 'react';
import { useTonConnectModal, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { ModalCloseIcon } from "../icons/ModalCloseIcon";
import { TonKeeper } from "../icons/TonKeeper";
import { TonSpace } from "../icons/TonSpace";
import '../../assets/css/garage.css';
import { useTelegram } from "../../TelegramContext";
import useTranslation from "../../hooks/useTranslation";

const P2pPayTypeModal = ({ isActive, onClose, data, fetchUnblockTransaction, orderId }) => {
    const { user, triggerVibration } = useTelegram();
    const { translation } = useTranslation();
    const { open } = useTonConnectModal();
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();
    const [payData, setPayData] = useState([]);

    useEffect(() => {
        if (data?.ton_messages) {
            setPayData(data?.ton_messages);
        }
    }, [data]);

    if (!isActive) {
        return null;
    }

    const handleTonSpace = async () => {
        try {
            triggerVibration('heavy');

            let messages = [];
            for (let i = 0; i < payData.length; i++) {
                messages.push({
                    address: payData[i]?.ton_address,
                    amount: payData[i]?.amount,
                    payload: payData[i]?.payload,
                });
            }

            const myTransaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: messages,
            };

            const result = await tonConnectUI.sendTransaction(myTransaction);

            // Подписка на изменение статуса
            tonConnectUI.onStatusChange((status) => {
                if (status === 'success') {
                    alert('Платеж успешно выполнен');
                    fetchUnblockTransaction(orderId);

                    // Действия после успешного платежа
                } else if (status === 'rejected') {
                    alert('Платеж был отменен');
                    fetchUnblockTransaction(orderId);
                    // Действия при отмене платежа
                }
            });

        } catch (e) {
            console.log(e, 'ERROR');
            alert('Ошибка выполнения транзакции');

            fetchUnblockTransaction(orderId);

        }
    };

    const handleTonKeeper = async () => {
        triggerVibration('heavy');
        window.location = data?.tonkeeperLink;
    };

    return (
        <div className='buy_nfs_modal PayTypeModal'>
            <div className='buy_nfs_modal_wrapper'>
                <button
                    className='buy_nfs_modal_close_btn'
                    onClick={() => {
                        triggerVibration('heavy');
                        onClose();
                    }}
                >
                    <ModalCloseIcon />
                </button>
                <p className='buy_nfs_modal_title'>
                    {translation?.choose_a_payment_method}
                </p>
                <p className='buy_nfs_modal_info'>
                    {translation?.text18}
                </p>

                <div className={'pay-type-wrapper'}>
                    <div style={{ opacity: 0.2 }} className={'pay-type-left'}>
                        <img src={require('../../assets/payType/Tonkeeper.png')} alt="Tonkeeper" />
                        <button>
                            <TonKeeper />
                            <span>TONKEEPER</span>
                        </button>
                    </div>
                    <div className={'pay-type-right'}>
                        <img src={require('../../assets/payType/TonSpace.png')} alt="TonSpace" />
                        <button onClick={handleTonSpace}>
                            <TonSpace />
                            <span>TON SPACE</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default P2pPayTypeModal;

export const InfoExhibitionIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={20}
            fill="none"
        >
            <path
                fill="url(#g)"
                d="M12.2.636c-1.69.186-2.561.705-3.236 1.897-.197.363-.362.695-.362.757 0 .062.653.125 1.638.166 3.774.135 5.153.799 6.304 3.028l.509.985h.55c.984 0 1.824.508 2.27 1.38.239.466.28.663.28 1.472 0 .798-.041 1.005-.27 1.462-.155.28-.342.58-.425.643-.114.093-.124.217-.03.643.103.497.113.518.445.518.342 0 .342 0 .342.508 0 .404.063.58.27.83.27.321.27.321 1.296.321.964 0 1.058-.02 1.296-.26l.26-.258-.032-3.142-.03-3.132-.322-.632-.322-.643.415-.052c.26-.031.519-.166.684-.322.239-.248.27-.352.27-1.005 0-.674-.02-.757-.29-.985-.373-.322-1.1-.343-1.66-.042a3.177 3.177 0 0 1-.425.207c-.02 0-.113-.217-.217-.497-.384-1.016-1.182-2.468-1.576-2.842-.488-.476-1.12-.756-2.002-.912-.923-.156-4.52-.218-5.63-.093Z"
            />
            <path
                fill="#fff"
                d="M6.29 5.25c-1.038.156-1.784.508-2.272 1.068-.29.332-.809 1.327-1.41 2.727l-.218.498-.342-.197c-.809-.477-1.721-.322-1.94.321-.248.706-.05 1.597.395 1.846.114.052.373.104.57.104h.374l-.301.59c-.456.903-.54 1.64-.508 4.46l.03 2.52.291.28c.27.28.332.29 1.265.29.923 0 .996-.02 1.255-.28.218-.218.29-.405.332-.85l.041-.581h10.971v.56c0 .446.052.632.249.86.218.26.332.29 1.027.322.974.052 1.472-.104 1.69-.518.134-.26.155-.747.124-3.236l-.03-2.924-.364-.736-.363-.736.467-.052c.684-.073.933-.415.933-1.265 0-.55-.041-.716-.249-.954-.228-.26-.321-.29-.881-.29-.467.01-.695.062-.923.227l-.3.218-.592-1.337c-.954-2.126-1.597-2.696-3.328-2.956-1.214-.186-4.77-.176-5.994.021Zm-1.266 7.725c1.016.26 1.4.519 1.4.975 0 .145-.114.384-.26.518-.238.25-.32.26-1.378.26-.986 0-1.172-.031-1.41-.218-.229-.176-.27-.29-.27-.757 0-.3.062-.612.134-.705.177-.228.509-.394.695-.342.083.02.57.145 1.089.27Zm10.152-.104c.238.187.27.29.27.799 0 .446-.053.633-.229.82-.207.217-.3.238-1.4.238-1.12 0-1.192-.01-1.43-.26-.146-.134-.26-.363-.26-.497 0-.477.311-.695 1.4-1.006 1.265-.363 1.307-.363 1.649-.094Z"
            />
            <defs>
                <linearGradient
                    id="g"
                    x1={8.602}
                    x2={24}
                    y1={7.908}
                    y2={7.908}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>
    )

}




import React from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import useTranslation from "../../hooks/useTranslation";

const NoGasModal = ({isActive, onClose, onCloseBuyNfs, onSuccessClose,  carData, redirectToWallet}) => {
    const {translation} = useTranslation();

    const { user, webApp, triggerVibration } = useTelegram();

    if (!isActive) {
        return null
    }

    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        onClose()
                    }}
                >
                    <ModalCloseIcon3/>
                </button>
                <p className='car_dealership_modal_title NoGasModal'>
                    {translation?.you_dont_have_enough} <br/> <span style={{color:'#A6E076'}}>NFS</span> {translation?.to_buy_this_car}
                </p>

                <NoGasSvg style={{margin: '10px auto ', display: 'block'}}/>
                <p className={'no-gas-text'}>
                    {translation?.you_can_earn_NFS_in_the_game_or_buy_it_in_the_store}
                </p>
                <div className='car_dealership_modal_buttons_wrapper'>

                    <button
                        onClick={()=>{

                        }}
                        className='car_dealership_modal_apply_button'
                    >

                        <div
                            onClick={() => {
                                onClose()
                                triggerVibration('heavy')
                            }}
                            className='car_dealership_modal_apply_button_child'>
                            <p className='car_dealership_modal_apply_button_text'>OK</p>
                        </div>

                    </button>
                    <button
                        className='car_dealership_modal_no_button'
                        onClick={() => {
                            triggerVibration('heavy');
                            redirectToWallet();
                        }}
                    >
                        <div className="car_dealership_modal_no_button_child no-gas-by-gas">
                            <p className='car_dealership_modal_no_button_text'>{translation?.buy_nfs}</p>
                        </div>
                    </button>
                </div>
            </div>

        </div>

    );
};

export default NoGasModal;

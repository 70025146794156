export const OnIcon2 = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={19}
            fill="none"
        >
            <path
                fill="#fff"
                d="M5.63 1.019c-.953.25-1.732 1.025-1.973 1.963-.096.37-.096.525-.096 6.717 0 6.165.005 6.347.096 6.708.125.491.332.847.717 1.237.39.385.746.592 1.246.722.366.091.51.096 4.418.082l4.037-.015.284-.11a2.672 2.672 0 0 0 1-.626 2.7 2.7 0 0 0 .708-1.059l.125-.322.015-4.475c.01-3.041 0-4.533-.034-4.649-.221-.745-1.304-.851-1.684-.163-.068.13-.077.577-.101 4.547l-.024 4.403-.116.183c-.062.101-.202.24-.308.313l-.197.13H6.025l-.207-.145c-.111-.082-.25-.23-.303-.337-.101-.192-.101-.197-.101-6.424 0-6.226 0-6.23.1-6.423a1.11 1.11 0 0 1 .304-.337l.207-.145 3.748-.014c2.656-.01 3.811.005 3.956.043.25.068.534.347.606.597.029.106.053.515.053.905 0 .914.077 1.126.49 1.337.361.183.9.077 1.141-.226.183-.23.207-.433.188-1.42-.02-.851-.034-.962-.145-1.255a2.648 2.648 0 0 0-.74-1.093c-.338-.317-.843-.587-1.261-.673-.174-.039-1.516-.053-4.21-.048-3.297 0-4 .014-4.221.072ZM1.05 6.283a.981.981 0 0 0-.569.443l-.12.207v2.766c0 2.512.01 2.782.082 2.916.192.352.64.592.967.52.24-.048.5-.197.601-.341.203-.284.207-.4.193-3.225L2.189 6.87l-.144-.207c-.226-.317-.669-.486-.996-.38ZM18.218 6.293c-.25.067-.524.35-.602.62-.043.16-.052.886-.043 2.883.015 2.603.02 2.675.116 2.863a.96.96 0 0 0 .808.476c.332 0 .62-.163.79-.452l.13-.217V6.933l-.121-.207a.957.957 0 0 0-1.078-.433Z"
            />
            <path
                fill="#fff"
                d="M9.36 14.001c-.309.14-.554.525-.554.867.005.36.36.813.698.875.091.015.327.024.52.01.404-.02.606-.125.808-.414.091-.14.115-.23.115-.49 0-.275-.019-.352-.14-.52-.197-.29-.452-.395-.923-.395-.212.005-.443.034-.525.067Z"
            />
        </svg>
    )

}

import React, {useEffect, useState} from "react";
import Loader from "react-js-loader";
import {LoaderLogoIcon} from "../icons/LoaderLogoIcon";
import SliderSubscribeModal from "../SliderSubscribeModal";
import LoaderComponent from "../Loader";

const SliderComponent = ({onClose}) => {

    const [activeSlide, setActiveSlide] = useState(0)
    const [loading, setLoading] = useState(false);

    const [slides, setSlides] = useState([
        {
            img: require('../../assets/images/slider/1.webp')
        },
        {
            img: require('../../assets/images/slider/2.webp')
        },
        {
            img: require('../../assets/images/slider/3.webp')
        },
        {
            img: require('../../assets/images/slider/4.webp')
        },
        {
            img: require('../../assets/images/slider/5.webp')
        },
        {
            img: require('../../assets/images/slider/6.webp')
        },
        {
            img: require('../../assets/images/slider/7.webp')
        },
    ]);

    useEffect(() => {
        setLoading(true)
        setTimeout(()=>{
            setLoading(false);
        }, 500)
    }, [activeSlide]);

    const PrintTopText = () => {

        switch (activeSlide) {
            case 0:

                return (
                    <>
                        <div className={'slider-wrapper-top'}>

                            <div className={'slider-wrapper-top-container'}>
                                <img src={require('../../assets/images/logo4.png')} alt=""/>

                                <p className={'slider-wrapper-top-container-text'}>
                                    <span className={'green-text'}> Welcome to the game! </span>
                                    Dive into the race, <br/> explore your garage, visit the shop, and more.
                                </p>
                            </div>

                        </div>
                        <div className={'slider-wrapper-center'}>

                            <img src={require('../../assets/images/slider/1.png')} alt=""/>

                        </div>

                    </>


                )

                break;
            case 1:
                return (

                    <>
                        <div className={'slider-wrapper-top'}>
                            <div className={'slider-wrapper-top-container'}>
                                <h1>Car Upgrade Interface</h1>

                                <p className={'slider-wrapper-top-container-text'}>

                                    Boost your cars to Stage 4 in the garage for better race <br/> odds and <span
                                    className={'green-text'}>to earn passive income at the Exhibition.</span>
                                </p>
                            </div>
                        </div>

                        <div className={'slider-wrapper-center'}>

                            <img src={require('../../assets/images/slider/2.png')} alt=""/>

                        </div>
                    </>

                )

                break;
            case 2:
                return (
                    <>
                        <div className={'slider-wrapper-top'}>
                            <div className={'slider-wrapper-top-container'}>
                                <h1>Choose Your Race Mode:</h1>

                                <p className={'slider-wrapper-top-container-text'}>
                                    Hit Play to select a race mode. <br/> Each mode corresponds to specific <span
                                    className={'green-text'}> car <br/> classes and potential earnings. </span>
                                </p>
                            </div>
                        </div>
                        <div className={'slider-wrapper-center'}>

                            <img src={require('../../assets/images/slider/3.png')} alt=""/>

                        </div>

                    </>
                )
                break;
            case 3:
                return (
                    <>
                        <div className={'slider-wrapper-top'}>
                            <div className={'slider-wrapper-top-container'}>
                                <h1>Fuel, Profile</h1>

                                <p className={'slider-wrapper-top-container-text'}>
                                    <span className={'green-text'}>Monitor your fuel to race.</span> If you're out,
                                    wait <br/> for a refill or use NFS for an instant top-up.
                                </p>
                            </div>
                        </div>

                        <div className={'slider-wrapper-center'}>
                            <img src={require('../../assets/images/slider/4.png')} alt=""/>
                        </div>
                    </>

                )
                break;
            case 4:
                return (
                    <>
                        <div className={'slider-wrapper-top'}>
                            <div className={'slider-wrapper-top-container'}>
                                <h1>Dealership</h1>

                                <p className={'slider-wrapper-top-container-text'}>
                                    Buy new cars at the dealership. Once all are <br/> bought, <span
                                    className={'green-text'}>they’re only available on the P2P market.</span>
                                </p>
                            </div>
                        </div>

                        <div className={'slider-wrapper-center'}>
                            <img src={require('../../assets/images/slider/5.png')} alt=""/>
                        </div>
                    </>
                )
                break;
            case 5:
                return (
                    <>
                        <div className={'slider-wrapper-top'}>
                            <div className={'slider-wrapper-top-container'}>
                                <h1>P2P Market</h1>

                                <p className={'slider-wrapper-top-container-text'}>
                                    Trade cars with players on the P2P market. <br/><span className={'green-text'}>Set your own prices and discover unique deals.</span>
                                </p>
                            </div>
                        </div>

                        <div className={'slider-wrapper-center'}>
                            <img src={require('../../assets/images/slider/6.png')} alt=""/>
                        </div>
                    </>
                )
                break;
            case 6:
                return (
                    <>
                        <div className={'slider-wrapper-top'}>
                            <div className={'slider-wrapper-top-container'}>
                                <h1>Wheel of Fortune, Daily Rewards</h1>

                                <p className={'slider-wrapper-top-container-text'}>
                                    Spin the Wheel of Fortune for prizes,<br/> earn NFS tokens with daily visits.
                                </p>
                            </div>
                        </div>

                        <div className={'slider-wrapper-center'}>
                            <img src={require('../../assets/images/slider/7.png')} alt=""/>
                        </div>
                    </>
                )
                break;
        }
    }

    const handlePrev = () => {
        setActiveSlide(prevState => prevState == 0 ? 0 : prevState - 1)
    }

    const handleNext = () => {
        setActiveSlide(prevState => prevState + 1)
    }

    return (
        <div className={'slider-wrapper'}>

            {loading &&
                <LoaderComponent/>
            }

            {activeSlide < 7 ?
                <>

                    <PrintTopText/>



                    <div className={'slider-wrapper-bottom'}>

                        <div className={'slider-pagination'}>

                            {slides.map((item, index) => {
                                return (
                                    <div key={index} className={`slider-pagination-item ${activeSlide == index && 'active'}`}>

                                    </div>
                                )
                            })}

                        </div>


                        <div className={'next-slide-wrapper'}>
                            {/*<button onClick={handlePrev} className={'next-slide'}>*/}
                            {/*    PREV*/}
                            {/*</button>*/}
                            {/*<button onClick={handleNext} className={'next-slide'}>*/}
                            {/*    NEXT*/}
                            {/*</button>*/}


                            <button
                                style={{marginRight: 10, fontStyle:'italic'}}
                                className='car_dealership_modal_no_button'
                                onClick={() => {
                                    handlePrev()

                                }}
                            >
                                <div className="car_dealership_modal_no_button_child no-gas-by-gas">
                                    <p className='car_dealership_modal_no_button_text'>PREV</p>
                                </div>
                            </button>

                            <button
                                style={{fontStyle:'italic'}}
                                onClick={() => {
                                    handleNext()
                                }}
                                className='car_dealership_modal_apply_button'
                            >

                                <div
                                    onClick={() => {

                                    }}
                                    className='car_dealership_modal_apply_button_child'>
                                    <p className='car_dealership_modal_apply_button_text'>NEXT</p>
                                </div>

                            </button>
                        </div>

                    </div>
                </>

                :

                <SliderSubscribeModal
                    onClose={() => {
                        localStorage.setItem('slider', 'true')
                        window.location.href = 'https://t.me/needforspeed_ton'
                        onClose()
                    }}
                />
            }


        </div>
    );
};
export default SliderComponent;

import {useState, useEffect, useRef} from 'react';
import { getDealerCars} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useDealerCars = () => {

    const { user, triggerVibration, webApp  } = useTelegram();

    const [dealerCars, setDealerCars] = useState(null);
    const [loadingDealerCars, setLoadingDealerCars] = useState(true);
    const [errorDealerCars, setErrorDealerCars] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchDealerCars();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchDealerCars()
            }
        }

    }, [webApp?.initData]);

    const fetchDealerCars = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getDealerCars({initData:init_data} );

        console.log(data, '----d-d-d-d-d-')
        if (error) {
            console.log('ERRROR!', error);
            setErrorDealerCars(error);
            setLoadingDealerCars(false);

        } else {

            // setTimeout(()=>{
                setDealerCars(data);
                setLoadingDealerCars(false);
            // }, 1000)

            console.log(data, 'data')

        }

    };


    return { dealerCars, loadingDealerCars, errorDealerCars, fetchDealerCars };
};

export default useDealerCars;

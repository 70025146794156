import React from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import useTranslation from "../../hooks/useTranslation";
import NoSlotIcon from "../icons/NoSlotIcon";

const NoGasModal = ({isActive, onClose, onCloseWithRedirect}) => {
    const {translation} = useTranslation();

    const { user, webApp, triggerVibration } = useTelegram();

    if (!isActive) {
        return null
    }

    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        onClose()
                    }}
                >
                    <ModalCloseIcon3/>
                </button>
                <p style={{fontSize:23, fontWeight: "700", maxWidth:230}} className='car_dealership_modal_title NoGasModal'>
                    {translation?.not_slot_modal}
                </p>


               <div style={{display: "flex", justifyContent:'center', margin: '29px auto',}}>
                   <NoSlotIcon style={{ display: 'block'}}/>
               </div>
                <div className='car_dealership_modal_buttons_wrapper'>

                    <button
                        style={{width: "100%"}}
                        onClick={() => {
                            onCloseWithRedirect()
                            triggerVibration('heavy')
                        }}
                        className='car_dealership_modal_apply_button'
                    >

                        <div className='car_dealership_modal_apply_button_child'>
                            <p className='car_dealership_modal_apply_button_text'>{translation?.continue}</p>
                        </div>

                    </button>

                </div>
            </div>

        </div>

    );
};

export default NoGasModal;

import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/car_market.css'
import {ModalCloseIcon} from "../../components/icons/ModalCloseIcon";
import {CarMarketSellCarIcon} from "../../components/icons/carMarketSellCarIcon";
import {useNavigate, Link} from "react-router-dom";


function CarMarketSellCarPage () {
    const { user } = useTelegram();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [activeStage, setActiveStage] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [showBuyCarModal, setShowBuyCarModal] = useState(false);





    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getHeightClassForFreeCar = () => {
        if (windowHeight <= 580) {
            return 'general_page_car_img1';
        }
    };

    useEffect(() => {
        console.log(user, 'user')
    }, []);


    const navigate = useNavigate();


    return (
        <>
            <div className="car_market_page_wrapper" id='car_market_sell_car_page'>
                <div className="car_market_main_wrapper">
                    <div className="car_market_main_wrapper">
                        <div className="car_market_main_img">
                            <img src={require('../../assets/images/car_market_auto_img.png')} alt=""/>
                        </div>
                        <div className='car_market_buy_car_modal_wrapper'>
                            <button
                                className='car_market_buy_car_modal_close_btn'
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                <ModalCloseIcon/>
                            </button>
                            <div className='car_market_buy_car_modal_title_box'>
                                <p className='car_market_buy_car_modal_title'>car market</p>
                            </div>
                            <div className='car_market_buy_car_modal_wrapper_body'>
                                <div className='car_market_buy_car_modal_info_box'>
                                    <p className='car_market_buy_car_modal_info'>sorting</p>
                                </div>
                                <div className='car_market_buy_car_modal_items_wrapper'>
                                    <div className="car_market_buy_car_modal_item">
                                        <div className="car_market_buy_car_modal_item_img">
                                            <img src={require('../../assets/images/car_dealership_img2.png')} alt=""/>
                                        </div>
                                        <p className='car_market_buy_car_modal_item_title'>
                                            Nissan GT-R
                                        </p>
                                        <div className='car_market_buy_car_modal_item_child'>
                                            <div className='car_market_buy_car_modal_item_child_info_box1'>
                                                <p className='car_market_buy_car_modal_item_child_info1'>200.000 NFS</p>
                                            </div>

                                            <div className='car_market_buy_car_modal_item_child_info_box2'>
                                                <div className='car_market_buy_car_modal_item_child_info_box2_child1'>
                                                    <p className='car_market_buy_car_modal_item_child_info_box2_child1_info'>STAGE 1</p>
                                                </div>
                                                <div className='car_market_buy_car_modal_item_child_info_box2_child2'>
                                                    <p className='car_market_buy_car_modal_item_child_info_box2_child2_info'>200 HP</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='car_market_buy_car_modal_wrapper_footer'>
                                <div className='car_market_buy_car_modal_wrapper_footer_icon_input_box'>
                                    <div className='car_market_buy_car_modal_wrapper_footer_input_icon'>
                                        <CarMarketSellCarIcon/>
                                    </div>
                                    <div className='car_market_buy_car_modal_wrapper_footer_input_info_box'>
                                        <p className='car_market_buy_car_modal_wrapper_footer_input_info1'>800 000</p>
                                    </div>

                                    <p className='car_market_buy_car_modal_wrapper_footer_input_info'>
                                        Какой та текст: 5%
                                    </p>

                                </div>
                                <button className='car_market_buy_car_modal_wrapper_footer_btn'>
                                    sell
                                </button>
                            </div>


                        </div>

                        <div className="car_market_footer">
                            <div className='cars_market_buttons_main_wrapper'>
                                <div className='cars_market_buttons_main_wrapper_child'>
                                    <Link
                                        to={'/carMarketQuickSellCar'}
                                        className={`cars_market_buttons_main_wrapper_child_item ${activeButton === 1 ? 'active_stage1' : 'cars_stages_buttons_tab'}`}
                                    >
                                        <p className="cars_market_buttons_main_wrapper_child_item_title">
                                            quick sell
                                        </p>
                                    </Link>
                                    <Link
                                        to={'/carMarketBuyCar'}
                                        className={`cars_market_buttons_main_wrapper_child_item ${activeButton === 2 ? 'active_stage1' : ''}`}
                                    >
                                        <p className="cars_market_buttons_main_wrapper_child_item_title">
                                            Buy a car
                                        </p>
                                    </Link>
                                    <button
                                        className='active_stage4'
                                    >
                                        <p className='cars_market_buttons_main_wrapper_child_item_title2'>
                                            sell a car
                                        </p>
                                    </button>
                                </div>
                                <div className='cars_market_buttons_main_wrapper_info_box'>
                                    <p className="cars_market_buttons_main_wrapper_info">
                                        Buying and selling cars from other players
                                    </p>
                                </div>

                            </div>
                            <Link
                                to={'/carMarket'}
                                className='car_market_footer_back_btn'
                            >
                                back to home
                            </Link>
                        </div>
                    </div>
                </div>


            </div>
        </>

    );
}

export default CarMarketSellCarPage;


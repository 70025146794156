export const SupportIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={22}
            fill="none"
        >
            <path
                fill="#fff"
                d="M10.913.079c-3.45.56-6.373 2.817-7.91 6.115-.29.62-.646 1.643-.765 2.21-.04.178-.079.383-.099.462-.033.151-.026.151.587.151h.62l.106-.448c.171-.673.594-1.67.99-2.342 1.352-2.29 3.535-3.84 6.194-4.413.963-.205 2.692-.205 3.655 0 2.803.607 5.1 2.335 6.418 4.835.343.66.654 1.458.792 2.019l.08.35h.62c.613 0 .62 0 .587-.152-.02-.08-.06-.284-.1-.462-.25-1.168-.87-2.593-1.629-3.74-.633-.95-1.933-2.276-2.837-2.89a10.87 10.87 0 0 0-3.7-1.603c-.851-.178-2.751-.23-3.609-.092Z"
            />
            <path
                fill="#fff"
                d="M11.276 5.224c-1.128.25-2.032.746-2.89 1.577-1.035.996-1.61 2.223-1.761 3.74-.106 1.01.25 2.468.838 3.444l.323.534-.37 1.524c-.197.838-.362 1.577-.362 1.643 0 .323.336.633.692.633.093 0 .634-.35 1.412-.93l1.26-.924.58.152c.819.218 2.184.218 2.95 0 2.137-.607 3.707-2.243 4.228-4.407.172-.692.152-1.886-.033-2.632a5.945 5.945 0 0 0-4.097-4.255c-.646-.191-2.13-.244-2.77-.099Zm-.72 5.482c.628.6-.085 1.63-.877 1.254-.567-.27-.593-1.089-.04-1.405.238-.139.687-.066.918.151Zm2.31-.125c.435.257.495.864.125 1.24-.171.165-.25.198-.528.198-.277 0-.356-.033-.527-.198-.654-.66.132-1.708.93-1.24Zm2.46.013c.436.29.502.792.146 1.181-.205.218-.258.244-.561.244-.29 0-.363-.026-.548-.218-.191-.184-.217-.257-.217-.547 0-.304.026-.356.244-.56.29-.265.626-.298.936-.1ZM1.611 9.743c-.64.132-1.081.449-1.385.996l-.198.356-.02 2.217c-.02 2.111-.013 2.23.12 2.586.157.422.527.831.936 1.042.218.112.435.159.897.178l.607.027V9.677l-.343.007a5.236 5.236 0 0 0-.614.06ZM3.294 13.398v3.734h.382c.653 0 .607.283.607-3.701 0-2.824-.02-3.49-.092-3.589-.066-.086-.198-.132-.495-.152l-.402-.026v3.734ZM20.907 9.75c-.085.04-.184.099-.21.145-.027.046-.054 1.643-.054 3.549 0 3.984-.052 3.688.614 3.688h.376V9.677l-.277.007c-.158 0-.356.033-.449.066ZM22.359 13.404v3.74l.613-.026c.455-.02.673-.066.89-.178a2.06 2.06 0 0 0 .938-1.042c.131-.356.138-.475.118-2.586l-.02-2.216-.178-.337c-.35-.673-.91-.996-1.8-1.062l-.561-.04v3.747ZM21.6 18.3c-.099.547-.488 1.028-1.023 1.266-.277.125-.428.139-2.031.139h-1.729l-.066-.159c-.092-.244-.468-.646-.706-.739-.158-.072-.62-.092-1.834-.092-1.477 0-1.642.013-1.873.132-.956.488-.963 1.854-.013 2.355.284.145.343.152 1.867.152 1.273 0 1.623-.02 1.82-.1.31-.131.522-.33.68-.646l.125-.244h1.537c1.65 0 2.144-.053 2.58-.264.66-.316 1.194-1.035 1.273-1.715l.04-.33h-.297c-.297 0-.304.007-.35.244Z"
            />
        </svg>
    )

}

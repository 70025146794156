export const RoundIcon = ({}) => {

    return (
        <svg width="54" height="14" viewBox="0 0 54 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.3" cx="7.40219" cy="6.60214" r="5.135" stroke="url(#paint0_linear_490_6749)" stroke-width="2.93429"/>
            <circle opacity="0.6" cx="27.2086" cy="6.60214" r="5.135" stroke="url(#paint1_linear_490_6749)" stroke-width="2.93429"/>
            <circle cx="47.015" cy="6.60214" r="5.135" stroke="url(#paint2_linear_490_6749)" stroke-width="2.93429"/>
            <defs>
                <linearGradient id="paint0_linear_490_6749" x1="0.800049" y1="6.60214" x2="14.0043" y2="6.60214" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6AA539"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint1_linear_490_6749" x1="20.6064" y1="6.60214" x2="33.8107" y2="6.60214" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6AA539"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint2_linear_490_6749" x1="40.4128" y1="6.60214" x2="53.6171" y2="6.60214" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6AA539"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
            </defs>
        </svg>

    )

}

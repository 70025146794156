import React, {useEffect, useState,} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/roulette.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {SpinIcon} from "../../components/icons/SpinIcon";
import {SpinIcon2} from "../../components/icons/spinIcon2";
import {AddPetrolIcon} from "../../components/icons/AddPetrol";
import {ChangeIcon} from "../../components/icons/ChangeIcon";
import {CloseBalanceIcon} from "../../components/icons/CloseBalanceIcon";
import DraggeableForm from "../../components/DraggeableForm/draggeableForm";
import GazAmount from "../../components/GazAmount";
import useUserData from "../../hooks/useUserData";
import useSpinList from "../../hooks/useSpinList";
import useVictoriousSpin from "../../hooks/useVictoriousSpin";
import Loader from "../../components/Loader";
import { v4 as uuidv4 } from "uuid";
import {InputNfsIcon} from "../../components/icons/InputNfsIcon";
import {convertNfsToTon, convertSpinToNfs} from "../../utils/format";
import useNFSPackages from "../../hooks/useNFSPackages";
import useBuySpin from "../../hooks/useBuySpin";
import useGetRefsLink from "../../hooks/useGetRefsLink";
import SpinModal from "../../components/SpinModal";
import useTranslation from "../../hooks/useTranslation";

function RoulettePage () {

    const { user, triggerVibration} = useTelegram();
    const {translation} = useTranslation();

    const { nfsBalance, userData, loading, error , fetchUserData} = useUserData();
    const { spinList, loadingSpinList, errorSpinList } = useSpinList();
    const { victoriousSpin, loadingVictoriousSpin, errorVictoriousSpin, fetchVictoriousSpin } = useVictoriousSpin();
    const { NFSPackages, loadingNFSPackages, errorNFSPackages, fetchNFSPackages } = useNFSPackages();
    const { buySpinAnswer, loadingBuySpin, errorBuySpin, fetchBuySpin } = useBuySpin();
    const { refsLink } = useGetRefsLink();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [showBuySpinModal, setShowBuySpinModal] = useState(false);

    const [spinsValue, setSpinsValue] = useState('');
    const [nfsValue, setNfsValue] = useState('0');

    const [clickRuletka, setClickRuletka ] = useState('');

    const [showSpinModal, setShowSpinModal ] = useState(false);

    useEffect(() => {

      // alert(victoriousSpin?.award)

        if (victoriousSpin?.award) {
            triggerVibration('heavy')
            setClickRuletka(uuidv4)
        }

    }, [victoriousSpin]);

    useEffect(() => {

        if (buySpinAnswer) {
            setShowBuySpinModal(false);
            fetchUserData();
            // fetchVictoriousSpin()
        }

    }, [buySpinAnswer]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const shareRefLink = async () => {
        // const userId = window.Telegram.WebApp.initDataUnsafe.user?.id || '844536969';

        // const botUrl = `https://t.me/Catscoin_bot?start=RefId_${userId}`;
        const botUrl = userData?.referralLink;

        const text = `Присоединяйтесь`;
        const message = `${text}`;
        const encodedMessage = encodeURIComponent(message);

        const link = document.createElement('a');
        link.href = `https://t.me/share/url?url=${botUrl}&text=${encodedMessage}`;
        link.target = '_blank';
        link.style.display = 'block';

        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);

    }

    const navigate = useNavigate();


    return (
        <>
            {loading && loadingVictoriousSpin && loadingSpinList &&
                <Loader/>
            }

            <div className="roulette_page_wrapper" id='roulette_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <div className="general_page_item">
                        <div
                            className="general_page_item_logo"
                        >
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/'}
                                className="general_page_item_logo_box"
                            >
                                <LogoIcon/>
                            </Link>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="roulette_page_main_wrapper">
                    <div className='roulette_page_main_wrapper_wheel_img_car_img_main_wrapper'>
                        {/*<div className='roulette_page_main_wrapper_wheel_img'>*/}
                        {/*    <img src={require('../../assets/images/wheel_img.png')} alt=""/>*/}
                        {/*</div>*/}

                        {!loading && !loadingSpinList &&
                            <DraggeableForm
                                clickRuletka={clickRuletka}
                                spinList={spinList}
                                victoriousSpin={victoriousSpin}
                                updateVictoriousSpin={() => {
                                    setShowSpinModal(true)
                                    fetchUserData();
                                }}
                                fetchUserData={fetchUserData}
                            />
                        }

                        <div className='roulette_page_main_wrapper_car_img'>
                            <img src={require('../../assets/images/roulette_car_img.png')} alt=""/>
                        </div>
                    </div>

                    <div className='roulette_page_footer'>
                        <button
                            className='roulette_page_footer_spins_info_box'
                            onClick={() => {
                                triggerVibration('heavy')
                                setShowBuySpinModal(true)
                            }}
                        >

                            <p className='roulette_page_footer_spins_info_box_title'>{translation?.you_have_spins}</p>
                            <div className='roulette_page_footer_spins_info_btn_icon_box'>
                                <p className='roulette_page_footer_spins_info'>{userData?.spinAmount}</p>
                                <SpinIcon/>
                                <button className='add_petrol_btn'>
                                    <AddPetrolIcon/>
                                </button>
                            </div>
                        </button>

                        <button
                            onClick={()=>{
                                fetchVictoriousSpin()

                                // if (victoriousSpin?.award) {
                                //     triggerVibration('heavy')
                                //     setClickRuletka(uuidv4)
                                // }
                            }}
                            className={`roulette_page_footer_spin_btn active`}
                        >
                            {translation?.SPIN}
                        </button>

                        <button
                            className='roulette_page_footer_back_home_btn'
                            onClick={() => {
                                triggerVibration('heavy')
                                navigate(-1)
                            }}
                        >
                            {translation?.back_to_home}
                        </button>
                    </div>
                </div>
                {showBuySpinModal &&
                    <div className='buy_spin_modal'>
                        <div className="buy_spin_modal_wrapper">
                            <div className="buy_spin_modal_info_box">
                                <p className='buy_spin_modal_info_box_title'>
                                    {translation?.text1}
                                </p>
                                <p className='buy_spin_modal_info_box_text'>
                                    {translation?.text2}
                                </p>
                            </div>
                            <div className='buy_spin_modal_invitation_link_box'>
                                <div className='buy_spin_modal_invitation_link_box_info_parent'>
                                    <p className='buy_spin_modal_invitation_link_box_info'>
                                        {translation?.text3}
                                    </p>
                                </div>
                                <div className='buy_spin_modal_invitation_link_box_input_field_search_btn_wrapper'>
                                    <input disabled={true}
                                           type="text"
                                           className='buy_spin_modal_invitation_link_box_input_field'
                                           placeholder='t.me/links'
                                           value={refsLink?.referralLink}
                                    />
                                    <button
                                        onClick={ ()=>{
                                            triggerVibration('heavy')
                                            shareRefLink(refsLink?.referralLink)
                                        }}
                                        className='buy_spin_modal_invitation_link_box_input_field_search_btn'
                                    >
                                        <ChangeIcon/>
                                    </button>
                                </div>
                            </div>
                            <div className="buy_spin_modal_box">
                                <button
                                    className='buy_spin_modal_box_close_btn'
                                    onClick={() => {
                                        triggerVibration('heavy')
                                        setShowBuySpinModal(false)
                                    }}
                                >
                                    <CloseBalanceIcon/>
                                </button>
                                <p className='buy_spin_modal_box_title'>
                                    {translation?.you_can_buy_spin}
                                </p>
                                <p className='buy_spin_modal_box_info'>{NFSPackages?.spinCost} {translation?.NFS_for_1_spin}</p>
                                <div className="buy_spin_modal_box_items_wrapper">
                                    <div className="buy_spin_modal_box_item">
                                        <div className="buy_spin_modal_box_item_icon_title_wrapper">
                                            <div className="buy_spin_modal_box_item_icon">
                                                <SpinIcon2/>
                                            </div>
                                            <p className="buy_spin_modal_box_item_title">
                                                {translation?.SPIN}
                                            </p>
                                        </div>
                                        <div className="buy_spin_modal_box_item_info_wrapper input-wrap_">
                                            {/*<p className='buy_spin_modal_box_item_info'>1</p>*/}
                                            <input
                                                type="number"
                                                placeholder={'0'}
                                                value={spinsValue}
                                                onChange={(e) => {
                                                    setSpinsValue(e.target.value)
                                                    let convertSpinToNfs_ = convertSpinToNfs(e.target.value, NFSPackages?.spinCost);
                                                    setNfsValue(convertSpinToNfs_);
                                                    console.log(NFSPackages, 'NFSPackages')
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="buy_spin_modal_box_item">
                                        <div className="buy_spin_modal_box_item_icon_title_wrapper">

                                            <p className="buy_spin_modal_box_item_title">
                                                <div className="wallet_input_field_icon">
                                                    <InputNfsIcon/>
                                                </div>
                                            </p>
                                        </div>
                                        <div className="buy_spin_modal_box_item_info_wrapper ">
                                            <p className='buy_spin_modal_box_item_info nfs-green-color' style={{color: 'green'}}>{nfsValue}</p>
                                            {/*<input type="text"/>*/}
                                        </div>

                                    </div>
                                </div>
                                <button
                                    onClick={()=>{
                                        triggerVibration('heavy')
                                        fetchBuySpin(spinsValue)
                                    }}
                                    className='buy_spin_button'
                                >
                                    {translation?.buy_spin}
                                </button>
                            </div>
                        </div>
                    </div>
                }

            </div>

            {victoriousSpin && victoriousSpin?.award &&
                <SpinModal
                    isActive={showSpinModal}
                    onClose={()=>{
                        setShowSpinModal(false)
                        // fetchVictoriousSpin();
                        fetchUserData()
                    }}
                    victoriousSpin={victoriousSpin}
                />
            }

        </>

    );
}

export default RoulettePage;


import React from "react";
import Loader from "react-js-loader";
import {LoaderLogoIcon} from "../icons/LoaderLogoIcon";

 const LoaderComponent = () => {
    return (

        <div className='loader_parent'>
            <div className="loader_parent_wrapper">
                <div className='loader_parent_logo'>
                    <LoaderLogoIcon/>
                </div>
                <div className="loader_box">
                    <div className="loader_box_child"></div>
                </div>
            </div>
        </div>
    );
 };
export default LoaderComponent;

import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/friends.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {ParticipantsIcon} from "../../components/icons/ParticipantsIcon";
import {ParticipantsIcon2} from "../../components/icons/Participants2Icon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useGetFriends from "../../hooks/useGetFriends";
import LoaderComponent from "../../components/Loader";
import {InputNfsIcon2} from "../../components/icons/InputNfsIcon2";
import useTranslation from "../../hooks/useTranslation";


function FriendsPage () {
    const {translation} = useTranslation();

    const { user, triggerVibration } = useTelegram();
    const {nfsBalance, userData, loading, error } = useUserData();
    const { friends, loadingFriends, errorFriends, fetchFriends } = useGetFriends();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [partnersList, setPartnersList] = useState([
        {
            id: 1,
            partners_quantity: 12000,
            line_number: 1,
            line_percentage: '10%'
        },
        {
            id: 2,
            partners_quantity: 12000,
            line_number: 2,
            line_percentage: '3%'
        },
        {
            id: 3,
            partners_quantity: 12000,
            line_number: 3,
            line_percentage: '2%'
        },
        {
            id: 4,
            partners_quantity: 12000,
            line_number: 4,
            line_percentage: '1%'
        },
        {
            id: 5,
            partners_quantity: 12000,
            line_number: 5,
            line_percentage: '1%'
        },
        {
            id: 6,
            partners_quantity: 12000,
            line_number: 6,
            line_percentage: '1%'
        },
        {
            id: 7,
            partners_quantity: 12000,
            line_number: 7,
            line_percentage: '1%'
        },
        {
            id: 8,
            partners_quantity: 12000,
            line_number: 8,
            line_percentage: '1%'
        },

    ]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const navigate = useNavigate();

    const shareRefLink = async () => {
        triggerVibration('heavy')

        if (userData?.referralLink) {
            // const userId = window.Telegram.WebApp.initDataUnsafe.user?.id || '844536969';
            // const botUrl = `https://t.me/Catscoin_bot?start=RefId_${userId}`;

            const botUrl = userData?.referralLink;
            const text = `
    🚗 Ready to race? Join me in Need For Speed TON for the ultimate car racing experience! 🏁

🌟 Get a head start with 10,000 $NFS coins when you join!
🚗 Special Bonus: The first 10,000 players will receive an AirDrop of the iconic Toyota Corolla AE86!
🎉 Plus, unlock exclusive cars and gear as we race to the top!

Let’s rev up our engines and race to victory! 🏎💨`;
            const message = `${text}`;
            const encodedMessage = encodeURIComponent(message);

            const link = document.createElement('a');
            link.href = `https://t.me/share/url?url=${botUrl}&text=${encodedMessage}`;
            link.target = '_blank';
            link.style.display = 'block';


            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);
        }


    }

    return (
        <>

            {(loading || loadingFriends ) && (
                <LoaderComponent/>
            )}

            <div className="friends_page_wrapper" id='friends_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>

                            <GazAmount userData={userData}/>
                        </div>

                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item"
                    >
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>
                    </Link>

                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="friends_page_items_wrapper">
                    <div className="friends_page_item1">
                        <button
                            className='friends_page_close_btn'
                            onClick={() => {
                                navigate('/')
                                triggerVibration('heavy')
                            }}
                        >
                            <CloseIcon/>
                        </button>
                        <div className='friends_page_img_name_wrapper'>
                            <div className='friends_page_img_box'>

                                <img src={ `${process.env.REACT_APP_IMG_DOMAIN}/${userData?.avatarPath}`} alt=""/>
                            </div>
                            <h1 className='friends_page_img_name'>{userData?.name}</h1>
                        </div>
                        <div className='friends_page_participants_info_icon_wrapper'>
                            <p className='friends_page_participants_info_title'>{translation?.participants}:</p>
                            <div className='friends_page_participants_info_icon_wrapper_child'>
                                <ParticipantsIcon/>
                                <p className='friends_page_participants_info_icon_wrapper_info'>{friends?.usersAmount}</p>
                            </div>
                        </div>
                        <div className='friends_page_reward_info_wrapper'>
                            <p className='friends_page_reward_info_wrapper_title'>{translation?.reward}:</p>
                            <div className='friends_page_reward_info_wrapper2'>
                                <div className='friends_page_reward_info_img'>
                                    {/*<img src={require('../../assets/images/reward_img.png')} alt=""/>*/}
                                    <InputNfsIcon2/>

                                </div>
                                <p className='friends_page_reward_info'>
                                    {friends?.retrieved}

                                </p>
                            </div>
                        </div>
                        <button onClick={shareRefLink} className='invite_friends_btn'>
                            {translation?.invite_frens}
                            {/*<img src={require('../../assets/images/invite_friends_img.png')} alt=""/>*/}
                        </button>
                    </div>
                    <div className="friends_page_item2">
                        <div className='friends_page_partners_details_items_wrapper'>
                            {friends && friends?.lines.length == 0 &&
                                <p className={'friends-not-found'}>{translation?.data_not_found}</p>
                            }

                            {friends?.lines.length > 0 && friends?.lines.map((item, index) => {
                                return (
                                    <div key={index} className='friends_page_partners_details_item'>
                                        <div className="friends_page_partners_details_item_child1">
                                            <p className='friends_page_partners_details_item_child1_info1'>{item.lineNumber}</p>
                                            <p className='friends_page_partners_details_item_child1_title'>{translation?.line}</p>
                                            {/*<p className='friends_page_partners_details_item_child1_info2'>[{item.line_percentage}]</p>*/}
                                        </div>
                                        <div className="friends_page_partners_details_item_child2">
                                            <p className='friends_page_partners_details_item_child2_title'>{translation?.partners}:</p>
                                            <div className='friends_page_partners_details_item_child2_icon'>
                                                <ParticipantsIcon2/>
                                            </div>

                                            <p className='friends_page_partners_details_item_child2_info'>{item.partners}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>


            </div>
        </>

    );
}

export default FriendsPage;



import React, { useEffect, useRef } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTelegram } from '../src/TelegramContext/index';
import LoaderComponent from "./components/Loader";
import {getAccount} from "./utils/userApi";
import {stringToBase64} from "./utils/format";

import {TonConnectUIProvider} from '@tonconnect/ui-react';

import {AudioProvider, useAudio} from '../src/context/AudioContext';

import General from './pages/GeneralPage/general';
import Garage from './pages/GaragePage/garage';
import Profile from './pages/ProfilePage/profile';
import Friends from './pages/FriendsPage/friends';
import CarDealerShip from "./pages/CarDealershipPage/CarDealerShipPage";
import InfoAboutProject from "./pages/InfoAboutProjectPage/infoAboutProject";
import DailyReward from "./pages/DailyRewardPage/dailyReward";
import RatingPage from "./pages/RatingPage/rating";
import WalletPage from "./pages/WalletPage/wallet";
import BalanceNfs from "./pages/BalanceNfsPage/balanceNfs";

import P2PPage from "./pages/P2PPage";
import P2PSalePage from "./pages/P2PSalePage";
import P2PBuyPage from "./pages/P2PBuyPage";
import P2PHistory from "./pages/P2PHistory";

import Game from "./pages/GamePage/game";
import GameFight from "./pages/GameFightPage/gameFight";
import Roulette from "./pages/RoulettePage/roulette";
import CarMarket from "./pages/CarMarketPage/carMarket";
import CarMarketBuyCar from "./pages/CarMarketBuyCarPage/carMarketBuyCar";
import CarMarketSellCar from "./pages/CarMarketSellCarPage/carMarketSellCar";
import CarMarketQuickSellCar from "./pages/CarMarketQuickSellPage/carMarketQuickSell";
import CarExhibition from "./pages/CarExhibitionPage/carExhibition";
import GusUp from "./pages/GusUpPage/gusUp";
import Missions from "./pages/MissionsPage/missions";
import Settings from "./pages/SettingsPage/settings";
import StartPage from "./pages/StartPage";
import CarListPage from "./pages/CarListPage";
import ExhibitionCarListPage from "./pages/ExhibitionCarListPage";


function App() {


    const { user, webApp } = useTelegram();
    const [isLogin, setIsLogin] = React.useState(false);
    const [banned, setBanned] = React.useState(false);
    const [loader, setLoader] = React.useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useRef(false);


    useEffect(() => {
        console.log(location.pathname)
        navigate("/start");
    }, []);

    useEffect(() => {
        // DEV
        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                console.log(user, 'user------')
                checkUser();
                isMounted.current = true;
            }
        } else {
            // PROD
            if (webApp?.initData && webApp?.initData != '') {

                if (webApp?.initData) {
                    checkUser();
                }
            }
        }


    }, [webApp?.initData]);


    const getDeviceType = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return "mobile";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "mobile";
        }
        return "desktop";
    };

    const checkUser = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        console.log(init_data, 'init_data')
        const {error, data} = await getAccount({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            return ;
        }

        console.log(data, 'data')

        setTimeout(()=>{
            setLoader(false)
        }, 1000)
    };



    if (loader) {
        return (
          <LoaderComponent/>
        )
    }

    return (
        // <TonConnectUIProvider manifestUrl="https://nfs-ton.com/tonconnect-manifest.json">
        <TonConnectUIProvider manifestUrl="https://dev.nfs-ton.com/tonconnect-manifest.json">
            <AudioProvider>
                <Routes>
                    <Route path="/start" element={<StartPage />} />
                    <Route path="/" element={<General />} />
                    <Route path="/garage" element={<Garage />} />
                    <Route path="/carlist" element={<CarListPage />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/friends" element={<Friends />} />
                    <Route path="/carDealerShip" element={<CarDealerShip />} />
                    <Route path="/infoAboutProject" element={<InfoAboutProject />} />
                    <Route path="/dailyReward" element={<DailyReward />} />
                    <Route path="/rating" element={<RatingPage />} />
                    <Route path="/wallet" element={<WalletPage />} />
                    <Route path="/balanceNfs" element={<BalanceNfs />} />
                    <Route path="/game" element={<Game />} />
                    <Route path="/gameFight" element={<GameFight />} />
                    <Route path="/roulette" element={<Roulette />} />
                    <Route path="/carMarket" element={<CarMarket />} />balanceNfs
                    <Route path="/carMarketBuyCar" element={<CarMarketBuyCar />} />
                    <Route path="/carMarketSellCar" element={<CarMarketSellCar />} />
                    <Route path="/carMarketQuickSellCar" element={<CarMarketQuickSellCar />} />
                    <Route path="/carExhibition" element={<CarExhibition />} />
                    <Route path="/exhibitionCarListPage" element={<ExhibitionCarListPage />} />
                    <Route path="/gusUp" element={<GusUp />} />
                    <Route path="/missions" element={<Missions />} />
                    <Route path="/settings" element={<Settings />} />

                    <Route path="/p2p" element={<P2PPage />} />
                    <Route path="/P2PSalePage" element={<P2PSalePage />} />
                    <Route path="/P2PBuyPage" element={<P2PBuyPage />} />
                    <Route path="/P2PHistory" element={<P2PHistory />} />

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </AudioProvider>

        </TonConnectUIProvider>


    );
}

export default App;

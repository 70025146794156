import React, {useEffect} from 'react';
import {RoundIcon} from "../icons/RoundsIcon";
import {ModalCloseIcon} from "../icons/ModalCloseIcon";
import '../../assets/css/garage.css'
import useUpStage from "../../hooks/useUpStage";
import useTranslation from "../../hooks/useTranslation";

const UpgradeStageModal = ({isActive, onClose, stageData, upStage}) => {

    const {upStageAnswer, loadingUpStage, errorUpStage, fetchUpStage} = useUpStage(false);
    const {translation} = useTranslation();

    useEffect(()=>{

        console.log(stageData, 'stageData-stageData-stageData')
    }, [stageData])


    if (!isActive) {
        return null
    }

    const handleFetchUpStage = async () => {
        upStage(stageData?.carId, stageData?.next_stage)
    }

    return (
        <div className='upgrade_modal'>
            <div className='upgrade_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        onClose()
                    }}
                >
                    <ModalCloseIcon/>
                </button>
                <p className='upgrade_modal_title'>
                    {translation?.text5} {stageData?.price} {translation?.text6}
                </p>
                <div className='upgrade_modal_info_icon_wrapper'>
                    <p className='upgrade_modal_info'>Stage {stageData?.current_stage}</p>
                    <RoundIcon/>
                    <p className='upgrade_modal_info'>Stage {stageData?.next_stage}</p>

                </div>
                <div className='upgrade_modal_buttons_wrapper'>
                    <button onClick={handleFetchUpStage} className='upgrade_modal_apply_button'>
                        <div className='upgrade_modal_apply_button_child'>
                            <p className='upgrade_modal_apply_button_text'>{translation?.apply}</p>
                        </div>

                    </button>
                    <button
                        className='upgrade_modal_no_button'
                        onClick={() => onClose()}
                    >
                        <div className="upgrade_modal_no_button_child">
                            <p className='upgrade_modal_no_button_text'>{translation?.no}</p>
                        </div>
                    </button>
                </div>
            </div>

        </div>
    );
};

export default UpgradeStageModal;

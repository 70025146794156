import {useState, useEffect, useRef} from 'react';
import {buySpin, getAccount, getGarageInfo} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useGarageInfo = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [garageInfo, setGarageInfo] = useState(null);
    const [loadingGarageInfo, setLoadingGarageInfo] = useState(true);
    const [errorGarageInfo, setErrorGarageInfo] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchGetGarageInfo();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                if (!isMounted.current) {
                    fetchGetGarageInfo()
                    isMounted.current = true;
                }
            }
        }


    }, [webApp?.initData]);


    const fetchGetGarageInfo = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await getGarageInfo({
            initData:init_data
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorGarageInfo(error);
            setLoadingGarageInfo(false);

        } else {
            setGarageInfo(data)
            setErrorGarageInfo(null);
            setLoadingGarageInfo(false);
            console.log(data, 'data')

        }

    };


    return {garageInfo,  loadingGarageInfo, errorGarageInfo, fetchGetGarageInfo };
};

export default useGarageInfo;

import React, { createContext, useState, useEffect } from 'react';
import audioFile1 from '../assets/audio/audio1.mp3';
import audioFile2 from '../assets/audio/audio2.m4a';
import audioFile3 from '../assets/audio/audio3.mp3';
import audioFile4 from '../assets/audio/audio4.mp3';
import audioFile5 from '../assets/audio/audio5.mp3';
import audioFile6 from '../assets/audio/audio6.m4a';

// Массив с URL песен
// const songs = [
//     audioFile1,
//     // audioFile2,
//     audioFile3,
//     audioFile4,
//     // audioFile5,
//     // audioFile6,
// ];


const songs_ = [
    audioFile1,
    audioFile3,
    audioFile4,
];


const AudioContext = createContext();

export const AudioProvider = ({ children }) => {

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            // Получаем случайный индекс от 0 до i
            const j = Math.floor(Math.random() * (i + 1));
            // Обмен элементами местами
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const songs = shuffleArray(songs_)

    const [currentSongIndex, setCurrentSongIndex] = useState(0); // Индекс текущего трека
    const [audio, setAudio] = useState(null);
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        if (audio) {
            audio.pause();  // Останавливаем предыдущий трек
            audio.currentTime = 0;
        }

        const newAudio = new Audio(songs[currentSongIndex]);
        setAudio(newAudio);

        const handleEnded = () => {
            const nextSongIndex = (currentSongIndex + 1) % songs.length;
            setCurrentSongIndex(nextSongIndex);  // Переход на следующий трек
        };

        newAudio.addEventListener('ended', handleEnded);

        if (playing) {
            newAudio.play().catch((error) => {
                console.error('Ошибка при воспроизведении аудио:', error);
            });
        }

        return () => {
            newAudio.removeEventListener('ended', handleEnded);
        };
    }, [currentSongIndex]);

    useEffect(() => {
        if (audio) {
            if (playing) {
                audio.play().catch((error) => {
                    console.error('Ошибка при воспроизведении аудио:', error);
                });
            } else {
                audio.pause();
            }
        }
    }, [audio, playing]);

    const togglePlay = () => {
        setPlaying(!playing);
    };


    return (
        <AudioContext.Provider value={{ playing, setPlaying, audio }}>
            {children}
        </AudioContext.Provider>
    );
};

export const useAudio = () => {
    return React.useContext(AudioContext);
};

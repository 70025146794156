export const ProfileIcon = ({}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={20}
            fill="none"
        >
            <path fill="url(#i)" d="M5 4h11v11H5z" />
            <path
                fill="#fff"
                d="M6.29.062a3.033 3.033 0 0 0-1.507.811c-.341.333-.452.507-1.777 2.747C-.07 8.824.121 8.467.021 9.161c-.058.403.01.97.158 1.37.13.337 4.133 7.11 4.373 7.401.413.488.975.84 1.599.994.355.089.547.094 4.627.094 4.052 0 4.273-.005 4.618-.09a3.024 3.024 0 0 0 1.575-.96c.298-.357 4.287-7.102 4.412-7.468a2.97 2.97 0 0 0 0-1.997c-.12-.356-4.114-7.116-4.388-7.435A3.325 3.325 0 0 0 15.483.1c-.245-.08-.547-.09-4.56-.099-3.659-.004-4.345 0-4.633.061Zm5.079 4.402c1.147.29 1.906 1.383 1.762 2.532-.072.567-.418 1.176-.85 1.514-.509.393-1.248.586-1.848.487C8.815 8.73 7.946 7.1 8.666 5.678c.298-.595.85-1.04 1.493-1.21.341-.088.87-.093 1.21-.004Zm1.57 5.405c.845.272 1.392.797 1.718 1.64.115.296.12.38.144 1.402l.02 1.093H6.784l.02-1.092c.019-.788.043-1.144.096-1.299.34-1.008 1.128-1.674 2.17-1.828.134-.024.988-.033 1.9-.028 1.546.009 1.676.018 1.969.112Z"
            />
            <defs>
                <linearGradient
                    id="i"
                    x1={5}
                    x2={16}
                    y1={9.5}
                    y2={9.5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>


    )

}

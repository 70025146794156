import {useState, useEffect, useRef} from 'react';
import {getAccount, getDailyReward} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useGetDailyReward = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [dailyRewardInfo, setDailyRewardInfo] = useState(null);
    const [loadingDailyRewardInfo, setLoadingDailyRewardInfo] = useState(true);
    const [errorDailyRewardInfo, setErrorDailyRewardInfo] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        // if (process.env.REACT_APP_DEBUG == 'true') {
        //     if (!isMounted.current) {
        //         fetchGetDailyRewardInfo();
        //         isMounted.current = true;
        //     }
        // } else {
        //
        //     if (webApp?.initData && webApp?.initData != '') {
        //         fetchGetDailyRewardInfo()
        //     }
        // }


    }, [webApp?.initData]);

    const fetchGetDailyRewardInfo = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await getDailyReward({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setErrorDailyRewardInfo(error);
            setLoadingDailyRewardInfo(false);

        } else {

            setDailyRewardInfo(data);
            setLoadingDailyRewardInfo(false);
            console.log(data, 'data2222')

        }

    };


    return { dailyRewardInfo, loadingDailyRewardInfo, errorDailyRewardInfo, fetchGetDailyRewardInfo };
};

export default useGetDailyReward;

export const PetrolRedIcon = ({}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={11}
            height={16}
            fill="none"
        >
            <path
                fill="url(#m)"
                d="M5.662.06c-.06.041-.526.493-1.034 1l-.922.926H2.002c-1.52 0-1.712.008-1.799.06C-.01 2.19 0 1.75 0 8.89c0 6.016.004 6.58.06 6.678.147.24-.248.226 5.358.226 4.642 0 5.094-.004 5.192-.06.24-.143.225.094.225-3.902V8.205l-.083-.105c-.109-.14-.285-.207-.553-.207-.917 0-2.129-.564-2.866-1.34-.512-.537-.794-1.08-.9-1.722l-.03-.192.967-.979c1.031-1.038 1.057-1.072.982-1.339-.026-.094-.312-.406-1.125-1.215L6.14.019 5.96.004c-.14-.012-.211.003-.298.056Zm.098 7.98.147.146v2.668c0 2.652 0 2.671-.08 2.773-.165.21-.153.21-1.895.21s-1.731 0-1.897-.21c-.079-.102-.079-.12-.079-2.773V8.186l.147-.146.147-.147h3.363l.147.147Z"
            />
            <path fill="url(#r)" d="M2.972 10.865v1.957H4.93V8.909H2.972v1.956Z" />
            <defs>
                <linearGradient
                    id="m"
                    x1={0.124}
                    x2={10.929}
                    y1={2.907}
                    y2={3.069}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED5E5E" />
                    <stop offset={0.438} stopColor="#FF7272" />
                    <stop offset={1} stopColor="#F15C5C" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={2.995}
                    x2={4.946}
                    y1={9.629}
                    y2={9.65}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED5E5E" />
                    <stop offset={0.438} stopColor="#FF7272" />
                    <stop offset={1} stopColor="#F15C5C" />
                </linearGradient>
            </defs>
        </svg>

    )

}

import {useState, useEffect, useRef} from 'react';
import {getAccount, getDailyVisits} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useGetDailyVisits = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [dailyVisitsInfo, setDailyVisitsInfo] = useState(null);
    const [loadingDailyVisitsInfo, setLoadingDailyVisitsInfo] = useState(true);
    const [errorDailyVisitsInfo, setErrorDailyVisitsInfo] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchGetDailyVisitsInfo();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchGetDailyVisitsInfo()
            }
        }


    }, [webApp?.initData]);

    const fetchGetDailyVisitsInfo = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        const {error, data} = await getDailyVisits({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setErrorDailyVisitsInfo(error);
            setLoadingDailyVisitsInfo(false);

        } else {

            setDailyVisitsInfo(data);
            setLoadingDailyVisitsInfo(false);
            console.log(data, 'data2222')

        }

    };


    return { dailyVisitsInfo, loadingDailyVisitsInfo, errorDailyVisitsInfo, fetchGetDailyVisitsInfo };
};

export default useGetDailyVisits;

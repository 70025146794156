import {useState, useEffect, useRef} from 'react';
import { upStage} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useUpStage = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [upStageAnswer, setUpStageAnswer] = useState(null);
    const [loadingUpStage, setLoadingUpStage] = useState(true);
    const [errorUpStage, setErrorUpStage] = useState(null);

    const isMounted = useRef(false);

    useEffect(() => {
        // if (process.env.REACT_APP_DEBUG == 'true') {
        //     if (!isMounted.current) {
        //         fetchUserData();
        //         isMounted.current = true;
        //     }
        // } else {
        //
        //     if (webApp?.initData && webApp?.initData != '') {
        //         fetchUserData()
        //     }
        // }

    }, [webApp?.initData]);

    const fetchUpStage = async (carId, stageNumber) => {
        setLoadingUpStage(true);

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await upStage({
            initData:init_data,
            carId:carId,
            stageNumber: stageNumber
        });

        if (error) {
            console.log('ERRROR!', error);
            setErrorUpStage(error);
            setLoadingUpStage(false);

        } else {
            setUpStageAnswer(data)
            setErrorUpStage(null);
            setLoadingUpStage(false);
            console.log(data, 'data')
        }

    };


    return {upStageAnswer,  loadingUpStage, errorUpStage, fetchUpStage };
};

export default useUpStage;

import React, {useEffect, useState,} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/profile.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {StarsIcon} from "../../components/icons/StarsIcon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useGetProfStat from "../../hooks/useGetProfStat";
import LoaderComponent from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";


function ProfilePage () {
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const { user, triggerVibration } = useTelegram();
    const {nfsBalance, userData, loading, error, fetchUserData } = useUserData();
    const { profStat, loadingProfStat, errorProfStat, fetchProfStat } = useGetProfStat();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);



    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const navigate = useNavigate();


    return (
        <>
            {( loading || loadingProfStat ) && (
                <LoaderComponent/>
            )}
            <div className="general_page_wrapper" id='profile_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item"
                    >
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>

                    </Link>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"

                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="profile_page_items_wrapper">
                    <div className="profile_page_general_statistics_item">
                        <button
                            className='profile_page_close_btn'
                            onClick={() => {
                                triggerVibration('heavy')
                                navigate('/')
                            }}
                        >
                            <CloseIcon/>
                        </button>
                        <p className='profile_page_general_statistics_item_title'>{translation?.general_statistics}</p>
                        <div className="profile_page_general_statistics_item_line"></div>
                        <div className="profile_page_general_statistics_item_number_of_victories_box">
                            <p className="profile_page_general_statistics_item_number_of_victories_box_title">
                                {translation?.number_of_victories}
                            </p>
                            <div className="profile_page_general_statistics_item_number_of_victories_box_icon">
                                <StarsIcon/>
                            </div>
                        </div>
                        <div className='profile_page_general_statistics_item_achievements_wrapper'>
                            <div className='profile_page_general_statistics_item_achievements_title_box'>
                                <p className='profile_page_general_statistics_item_achievements_title'>{translation?.achievements}</p>
                            </div>
                            <div className="profile_page_general_statistics_item_achievements_items_wrapper">
                                <div className="profile_page_general_statistics_item_achievements_item">
                                    <p className="profile_page_general_statistics_item_achievements_item_title">{translation?.win_percentage}</p>
                                    <div className='profile_page_general_statistics_item_achievements_item_info_percentage_block_wrapper'>
                                        <div className='profile_page_general_statistics_item_achievements_item_info_percentage_block'>
                                            <div style={{width:`${ profStat?.totalRaces > 0 ? (parseInt(profStat?.raceWins) / parseInt(profStat?.totalRaces)) * 100 : 0}%`}} className='profile_page_general_statistics_item_achievements_item_info_percentage_block_child'></div>
                                        </div>
                                        <p className="profile_page_general_statistics_item_achievements_item_info">
                                            {profStat?.totalRaces > 0 ? ((parseInt(profStat?.raceWins) / parseInt(profStat?.totalRaces)) * 100).toFixed(0) : 0}%
                                            {/*{ (parseInt(profStat?.raceWins) / parseInt(profStat?.totalRaces)) * 100 }%*/}
                                        </p>
                                    </div>

                                </div>
                                <div className="profile_page_general_statistics_item_achievements_item">
                                    <p className="profile_page_general_statistics_item_achievements_item_title">{translation?.TON_balance}:</p>
                                    <p className="profile_page_general_statistics_item_achievements_item_info">{profStat?.tonBalance}</p>
                                </div>
                                <div className="profile_page_general_statistics_item_achievements_item">
                                    <p className="profile_page_general_statistics_item_achievements_item_title">NFS {translation?.balance2}:</p>
                                    <p className="profile_page_general_statistics_item_achievements_item_info">{profStat?.nfsBalance}</p>
                                </div>
                                <div className="profile_page_general_statistics_item_achievements_item">
                                    <p className="profile_page_general_statistics_item_achievements_item_title">{translation?.total_nfs_earned}:</p>
                                    <p className="profile_page_general_statistics_item_achievements_item_info">{profStat?.nfsEarned}</p>
                                </div>
                                <div className="profile_page_general_statistics_item_achievements_item">
                                    <p className="profile_page_general_statistics_item_achievements_item_title">{translation?.game_Rating}:</p>
                                    <p className="profile_page_general_statistics_item_achievements_item_info">--</p>
                                </div>
                                <div className="profile_page_general_statistics_item_achievements_item">
                                    <p className="profile_page_general_statistics_item_achievements_item_title">{translation?.spent_on_cars}:</p>
                                    <p className="profile_page_general_statistics_item_achievements_item_info">{profStat?.spentOnCar}</p>
                                </div>
                                <div className="profile_page_general_statistics_item_achievements_item">
                                    <p className="profile_page_general_statistics_item_achievements_item_title">{translation?.total_races_wins}:</p>
                                    <p className="profile_page_general_statistics_item_achievements_item_info">{profStat?.totalRaces}/{profStat?.raceWins}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='profile_page_favorite_car_item'>
                        <p className='profile_page_favorite_car_item_title'>{translation?.favorite_car}</p>
                        <div className="profile_page_favorite_car_item_line"></div>
                        <div className="profile_page_favorite_car_item_details_items_wrapper">
                            <div className="profile_page_favorite_car_item_details_item">
                                <p className="profile_page_favorite_car_item_details_item_title">{translation?.power}:</p>
                                <div className='profile_page_favorite_car_item_details_item_info_percentages_wrapper'>
                                    <p className="profile_page_favorite_car_item_details_item_info">{profStat?.favoriteCar?.horsePower} hp</p>
                                    <div className="profile_page_favorite_car_item_details_item_percentages_block1">
                                        <div style={{width: "50%"}} className="profile_page_favorite_car_item_details_item_percentages_block_child1"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile_page_favorite_car_item_details_item">
                                <p className="profile_page_favorite_car_item_details_item_title">{translation?.tuning}</p>
                                <div className='profile_page_favorite_car_item_details_item_info_percentages_wrapper'>
                                    <p className="profile_page_favorite_car_item_details_item_info">Stage {profStat?.favoriteCar?.stage}</p>
                                    <div className="profile_page_favorite_car_item_details_item_percentages_block2">
                                        <div style={{width: `${profStat?.favoriteCar?.stage == 0 ? '0%' : profStat?.favoriteCar?.stage == 1 ? '25%' :  profStat?.favoriteCar?.stage == 2 ? '50%' :  profStat?.favoriteCar?.stage == 3 ? '75%' :  profStat?.favoriteCar?.stage == 4 ? '100%' : '0%'  }`}} className="profile_page_favorite_car_item_details_item_percentages_block_child2"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile_page_favorite_car_item_details_item">
                                <p className="profile_page_favorite_car_item_details_item_title profile_page_favorite_car_item_details_item_title2">
                                    {translation?.the_most_popular_mode}:
                                   <span>Ring race</span>
                                </p>
                                <div className='profile_page_favorite_car_item_details_item_info_percentages_wrapper'>
                                    <p className="profile_page_favorite_car_item_details_item_info">100%</p>
                                    <div className="profile_page_favorite_car_item_details_item_percentages_block3">
                                        <div style={{width: '100%'}} className="profile_page_favorite_car_item_details_item_percentages_block_child3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='profile_page_favorite_car_item_img fav-car-wrapper'>
                            <img src={require('../../assets/images/profile/fav_auto.png')} alt=""/>
                            <img
                                className={'fav-car'}
                                src={`${process.env.REACT_APP_IMG_DOMAIN}/${profStat?.favoriteCar?.imagePath}`}
                                alt=""
                            />

                        </div>
                    </div>
                </div>


            </div>
        </>

    );
}

export default ProfilePage;


import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

const TelegramContext = createContext({});

export const useTelegram = () => useContext(TelegramContext);

export const TelegramProvider = ({ children }) => {
    const [webApp, setWebApp] = useState(null);

    useEffect(() => {
        const app = window.Telegram?.WebApp;
        if (app) {
            app.ready();
            app.expand(); // Разворачивает веб-приложение на весь экран
            setWebApp(app);
            // alert(JSON.stringify(app))
        }
    }, []);

    const triggerVibration = (type = 'medium', check = true) => {
        if (webApp && webApp.HapticFeedback) {

            if (check) {
                let vibration_ = localStorage.getItem('vibration');
                if (vibration_) {
                    vibration_ = JSON.parse(vibration_);

                    console.log(vibration_, 'vibration_')
                    console.log(typeof vibration_, 'TYPE vibration_')

                    if (vibration_) {
                        webApp.HapticFeedback.impactOccurred(type);
                    }
                }
            } else {
                webApp.HapticFeedback.impactOccurred(type);
            }



        } else {
            console.log("Haptic feedback is not supported on this device.");
        }
    };


    const value = useMemo(() => {
        return webApp
            ? {
                webApp,
                unsafeData: webApp.initDataUnsafe,
                user: webApp.initDataUnsafe.user,
                triggerVibration, // Добавляем метод вибрации в контекст
            }
            : {id: '222222'};
    }, [webApp]);

    return (
        <TelegramContext.Provider value={value}>
            {children}
        </TelegramContext.Provider>
    );
};

export const PresentationIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.75 12.5C1.16421 12.5 1.5 12.8358 1.5 13.25V15.25C1.5 15.3881 1.61193 15.5 1.75 15.5H13.75C13.8881 15.5 14 15.3881 14 15.25V13.25C14 12.8358 14.3358 12.5 14.75 12.5C15.1642 12.5 15.5 12.8358 15.5 13.25V15.25C15.5 16.2165 14.7165 17 13.75 17H1.75C0.783502 17 0 16.2165 0 15.25V13.25C0 12.8358 0.335786 12.5 0.75 12.5Z"
                  fill="white"/>
            <path
                d="M6.48795 0C5.97356 0 5.54447 0.393122 5.49955 0.905549C5.34591 2.65826 5.31881 4.41959 5.41838 6.17584C5.17139 6.18949 4.9245 6.20531 4.67773 6.2233L3.18822 6.33187C2.61392 6.37374 2.29288 7.0141 2.60293 7.49932C3.66497 9.16141 5.03343 10.6063 6.63538 11.7571L7.23212 12.1858C7.54162 12.4081 7.95849 12.4081 8.26799 12.1858L8.86473 11.7571C10.4667 10.6063 11.8351 9.16141 12.8972 7.49932C13.2072 7.0141 12.8862 6.37374 12.3119 6.33188L10.8224 6.2233C10.5756 6.20531 10.3287 6.18949 10.0817 6.17584C10.1813 4.41959 10.1542 2.65826 10.0006 0.905544C9.95564 0.39312 9.52655 0 9.01216 0H6.48795Z"
                fill="white"/>
        </svg>
    )

}

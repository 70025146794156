import React, {useEffect, useState} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import Roulette from "../Roulette/Roulette";
import '../../assets/css/roulette.css';
import useUserData from "../../hooks/useUserData";
import useSpinList from "../../hooks/useSpinList";

const FormularioTexto = ({spinList, victoriousSpin, updateVictoriousSpin, clickRuletka, fetchUserData}) => {


    // const [inputList, setInputList] = useState([
    //     { id: uuidv4(), text: "Auto 1" },
    //     { id: uuidv4(), text: "Auto 2" },
    //     { id: uuidv4(), text: "Auto 3" },
    //     { id: uuidv4(), text: "Auto 4" },
    //     { id: uuidv4(), text: "Auto 5" },
    //     { id: uuidv4(), text: "Auto 6" },
    //     { id: uuidv4(), text: "Auto 7" },
    //     { id: uuidv4(), text: "Auto 8" },
    //     { id: uuidv4(), text: "Auto 9" },
    //     { id: uuidv4(), text: "Auto 10" },
    //     { id: uuidv4(), text: "Auto 11" }
    // ]);


    const [specialItems, setSpecialItems] = useState([]);
    const [inputList, setInputList] = useState(spinList);

    useEffect(()=>{
        if (victoriousSpin) {
            setSpecialItems([victoriousSpin?.award]);
            console.log(clickRuletka, 'clickRuletka')
            // handleAddClick()
        }


    }, [victoriousSpin?.award])


    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(inputList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setInputList(items);
    }

    const handleGiftReceived = (prize) => {
        console.log("Gift received:", prize);
        updateVictoriousSpin();
        // fetchUserData()
    };

    // Ensure the special items exist in the data list
    // const specialItems = [victoriousSpin?.award];

    return (
        <div className="main-form">
            <Roulette
                data={inputList}
                specialItems={specialItems}
                onGiftReceived={handleGiftReceived}
                clickRuletka={clickRuletka}
            />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                {/* Drag and drop functionality can be added here */}
            </DragDropContext>
            {/*<button*/}
            {/*    onClick={handleAddClick}*/}
            {/*    style={{ marginLeft: "2.1rem" }}*/}
            {/*    className="button"*/}
            {/*>*/}
            {/*    dqqw*/}
            {/*    /!* Add icon here if needed *!/*/}
            {/*</button>*/}
        </div>
    );
};

export default FormularioTexto;

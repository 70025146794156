import {useState, useEffect, useRef} from 'react';
import {getAccount, getFriends} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useGetFriends = () => {

    const { user, triggerVibration, webApp  } = useTelegram();
    const [friends, setFriends] = useState(null);
    const [loadingFriends, setLoadingFriends] = useState(true);
    const [errorFriends, setErrorFriends] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchFriends();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchFriends()
            }
        }


    }, [webApp?.initData]);

    const fetchFriends = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getFriends({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setErrorFriends(error);
            setLoadingFriends(false);

        } else {

            setFriends(data);
            setLoadingFriends(false);

            console.log(data, 'data')

        }

    };


    return { friends, loadingFriends, errorFriends, fetchFriends };
};

export default useGetFriends;

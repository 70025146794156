export const RatingIcon = ({}) => {

    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={22}
            fill="none"
        >
            <path
                fill="#fff"
                d="M3.07 1.345V2.69l.848.129c1.621.245 2.143.282 4.28.282 2.131-.006 2.666-.043 4.164-.313l.534-.098V0H3.071v1.345Z"
            />
            <path
                fill="url(#h)"
                d="M0 8.64c.006 6.24.012 6.915.098 6.805.05-.068.369-.516.713-1.001.59-.841.62-.878.804-.86.166.019.276.117.719.676l.522.657.018-2.469.012-2.469-.147-.368c-.326-.817-.344-1.05-.344-4.606V1.72H0v6.92Z"
            />
            <path
                fill="url(#j)"
                d="M13.56 5.202c-.025 3.789-.013 3.623-.381 4.532-.086.215-.098.552-.086 2.714l.018 2.475.51-.651c.276-.35.547-.657.596-.675.24-.093.387.042 1.044.988.362.522.67.952.681.952.013 0 .025-3.107.025-6.909V1.72h-2.388l-.02 3.483Z"
            />
            <path
                fill="#fff"
                d="M3.083 5.908c.024 2.29.037 2.512.16 2.954.417 1.51 1.578 2.794 3.027 3.34 2.34.879 4.999-.178 6.074-2.4.503-1.05.51-1.075.534-3.9l.03-2.481-.208.043c-2.469.503-6.233.528-8.954.067a9.813 9.813 0 0 0-.602-.092c-.067 0-.08.436-.061 2.469ZM6.878 14.125v.982H9.09v-1.972l-.227.044c-.307.061-1.462.061-1.75.006l-.234-.05v.99ZM5.68 15.918c-.03.104-.337 1.652-.337 1.72 0 .024 1.191.048 2.64.048 2.076 0 2.641-.018 2.641-.08-.006-.036-.08-.448-.172-.902l-.172-.83-2.284-.018c-1.8-.012-2.29 0-2.315.062ZM3.69 18.638c-.104.117-.233.301-.282.406-.178.337-.337 1.105-.337 1.602v.48h9.825v-.449c0-.829-.233-1.596-.614-2.026l-.196-.228H3.88l-.19.215Z"
            />
            <defs>
                <linearGradient
                    id="h"
                    x1={0}
                    x2={2.886}
                    y1={8.587}
                    y2={8.587}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={13.089}
                    x2={15.967}
                    y1={8.628}
                    y2={8.628}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>


    )

}

export const InfoFrensIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={20}
            fill="none"
        >
            <path
                fill="url(#j)"
                d="M10.189.189c-.09.11-.456.7-.811 1.31-.356.611-.655 1.134-.689 1.156-.022.033-.433-.111-.91-.311-.923-.378-1.256-.389-1.456-.022-.066.144.011.821.289 2.243.3 1.655.422 2.077.61 2.233.212.178.556.2 3.433.2s3.222-.022 3.433-.2c.188-.156.31-.578.61-2.233.278-1.422.356-2.1.29-2.243-.2-.367-.534-.356-1.456.022-.477.2-.888.344-.91.31-.034-.021-.334-.544-.69-1.154-.8-1.378-.9-1.5-1.277-1.5-.166 0-.377.089-.466.189Z"
            />
            <path
                fill="#fff"
                d="M9.844 8.508c-1.222.39-2.032 1.767-1.755 3 .467 2.077 2.91 2.81 4.399 1.321.667-.655.922-1.5.722-2.354-.333-1.522-1.9-2.433-3.366-1.967ZM3.046 9.786c-.755.233-1.166.822-1.166 1.655 0 1.055.61 1.666 1.644 1.666 1.033 0 1.733-.666 1.733-1.655 0-1.166-1.122-2.01-2.21-1.666ZM17.242 9.786c-.822.244-1.377 1.244-1.144 2.066.233.833.8 1.255 1.666 1.255.689 0 1.111-.222 1.455-.733.267-.41.29-1.388.034-1.81-.378-.645-1.289-1-2.01-.778ZM2.047 14.207c-1.156.2-1.9 1.033-2.022 2.288-.056.533-.033.622.222.878.289.289.311.289 2 .289h1.71l.067-.7c.078-.844.3-1.4.855-2.144l.433-.567-.51-.077c-.634-.09-2.167-.067-2.755.033ZM16.354 14.185l-.367.044.433.578c.567.755.789 1.31.867 2.155l.066.7h1.71c1.69 0 1.712 0 2-.29.256-.255.278-.344.222-.877-.066-.655-.366-1.322-.766-1.677-.566-.522-.955-.622-2.41-.655-.767-.023-1.567-.011-1.755.022ZM7.656 15.095c-1.322.423-1.8 1.167-1.822 2.822-.022 1.644-.41 1.522 4.821 1.522 5.232 0 4.843.122 4.82-1.522-.01-1.489-.332-2.1-1.376-2.655-.345-.189-.7-.211-3.277-.233-1.589-.011-3.01.022-3.166.066Z"
            />
            <defs>
                <linearGradient
                    id="j"
                    x1={6.301}
                    x2={15.01}
                    y1={3.499}
                    y2={3.499}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>

    )

}

import React from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import useTranslation from "../../hooks/useTranslation";

const SpaceListModal = ({isActive, onClose, onBy}) => {
    const {translation} = useTranslation();

    const { user, webApp, triggerVibration } = useTelegram();

    if (!isActive) {
        return null
    }

    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        onClose()
                        triggerVibration('heavy');

                    }}
                >
                    <ModalCloseIcon3/>
                </button>
                <p className='space-modal-title' style={{maxWidth: 276, marginBottom:27}}>
                    {translation?.сar_spots}
                </p>

                <div className={'space-items-wrapper'}>

                    <div className={'space-item'} onClick={onBy}>
                        <div className={'space-item-left'}>
                            <img src={require('../../assets/car1.png')} alt=""/>
                        </div>

                        <div className={'space-item-right'}>
                            <span>1 {translation?.count}</span>
                            <span>100 000 NFS</span>
                        </div>
                    </div>

                    <div className={'space-item lock'}>
                        <img src={require('../../assets/lock.png')} alt=""/>
                    </div>

                    <div className={'space-item lock'}>
                        <img src={require('../../assets/lock.png')} alt=""/>
                    </div>

                </div>


            </div>

        </div>

    );
};

export default SpaceListModal;

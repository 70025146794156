import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/car_dealership.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {BlockCar} from "../../components/icons/BlockCar";
import {ParticipantsIcon} from "../../components/icons/ParticipantsIcon";
import {ParticipantsIcon2} from "../../components/icons/Participants2Icon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import CarDealerShipModal from "../../components/CarDealerShipModal";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import useDealerCars from "../../hooks/useDealerCars";
import NoNfsModal from "../../components/NoNfsModal";
import useTranslation from "../../hooks/useTranslation";
import DontHaveSlotModal from "../../components/DontHaveSlotModal";
import useGarageInfo from "../../hooks/useGarageInfo";
// import Loader from "../../components/Loader";

function CarDealerShipPage () {


    const { user, triggerVibration } = useTelegram();
    const {translation} = useTranslation();

    const { nfsBalance, userData, loading, error } = useUserData();
    const { dealerCars, loadingDealerCars, errorDealerCars, fetchDealerCars } = useDealerCars();
    const {garageInfo, loadingGarageInfo, errorGarageInfo, fetchGetGarageInfo} = useGarageInfo();

    const [showNoNfsModal, setShowNoNfsModal] = useState(false);
    const [showDontHaveSlotModal, setShowDontHaveSlotModal] = useState(false);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [carsList, setCarsList] = useState([
        {
            id: 1,
            car_img: require('../../assets/images/car_dealership_img2.png'),
            car_name: 'Nissan GT-R',
            car_hp_info: '200 HP',
            car_nfs_info: '200. 000 NFS',
            purchased: true
        },
        {
            id: 2,
            car_img: require('../../assets/images/car_dealership_img3.png'),
            car_name: 'Toyota Celica',
            car_hp_info: '110 HP',
            car_nfs_info: '200. 000 NFS',
            purchased: false
        },
        {
            id: 3,
            car_img: require('../../assets/images/car_dealership_img6.png'),
            car_name: 'Lancer Evo',
            car_hp_info: '205 HP',
            car_nfs_info: '200. 000 NFS',
            purchased: false
        },
        {
            id: 4,
            car_img: require('../../assets/images/car_dealership_img4.png'),
            car_name: 'Nissan z350',
            car_hp_info: '305 HP',
            car_nfs_info: '200. 000 NFS',
            purchased: false
        },
        {
            id: 5,
            car_img: require('../../assets/images/car_dealership_img5.png'),
            car_name: 'Dodge Challenger',
            car_hp_info: '500 HP',
            car_nfs_info: '200. 000 NFS',
            purchased: false
        },
    ]);

    const [showCarBuyModal, setShowCarBuyModal] = useState(false);
    const [carInfo, setCarInfo] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const getSpotLeft = () => {
        return garageInfo?.slotAmount - garageInfo?.cars?.length;
    }


    const navigate = useNavigate();
    const openModal = (item) => {

        const spotsLeft = getSpotLeft()

        if (spotsLeft == 0) {
            setShowDontHaveSlotModal(true);
            return false;
        }

        console.log(spotsLeft, 'spotsLeft')

        setShowCarBuyModal(true)
        setCarInfo({
            car_name: item.name,
            carTypeId: item?.typeId
        })
    }

    return (
        <>
            {loading &&
                <LoaderComponent/>
            }
            <div className="car_dealership_page_wrapper" id='car_dealership_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>

                            <GazAmount userData={userData}/>
                        </div>

                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_logo" style={{background:'black'}}>

                            <Link to={'/'} className="general_page_item_logo_box">
                                <LogoIcon/>
                            </Link>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>

                        </div>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="car_dealership_page_items_wrapper blur-bg" style={{height: 'calc(100% - 50px)'}}>
                    <div className="car_dealership_page_item" style={{height: '100%'}}>
                        <div className='car_dealership_title_wrapper'>
                            <div className='car_dealership_title_wrapper_child'>
                                <h1 className='car_dealership_title'>{translation?.car_dealership}</h1>
                            </div>

                        </div>
                        <button
                            className='car_dealership_page_close_btn'
                            onClick={() => {
                                triggerVibration('heavy')
                                navigate('/')
                            }}
                        >
                            <CloseIcon/>
                        </button>
                        <div className='car_dealership_details_items_wrapper' style={{overflow: 'scroll', height: '100%'}}>
                            {dealerCars && dealerCars?.cars.map((item, index) => {
                                return (
                                    <div
                                        key={index} className={`${item.purchased ? 'car_dealership_details_item_purchased' : 'car_dealership_details_item'}`}
                                    >
                                        <div className="car_dealership_details_item_img   new-image-type-wrapper">
                                            <img className={'new-image-type-wrapper-bg'} src={require('../../assets/images/profile/fav_auto.png')} alt=""/>

                                            <img className={'new-image-type-img-absolute'} src={`${process.env.REACT_APP_IMG_DOMAIN}${item?.imagePath}`} alt=""/>

                                            <div className={'auto-count-diler'}>
                                                {item?.availableAmount}  left
                                            </div>
                                        </div>
                                        <div className='car_dealership_details_item_info_main_box'>
                                        <div className="car_dealership_details_item_name_hp_info_wrapper">
                                                <h1 className="car_dealership_details_item_name">{item.name}</h1>
                                                <div className='car_dealership_details_item_hp_info_box'>
                                                    <div className='car_dealership_details_item_hp_info_box_child'>
                                                        <p className='car_dealership_details_item_hp_info'>{item.horsePower} HP</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='car_dealership_details_item_btn_nfs_info_wrapper'>
                                                { !item?.available &&

                                                    <button className='car_dealership_details_item_btn block-auto' disabled={true}>
                                                        <BlockCar/>
                                                    </button>
                                                }


                                                {item?.purchased === true && item?.available &&
                                                    <button
                                                        className='car_dealership_details_item_btn ' disabled={true}>
                                                        {translation?.rurchased}
                                                    </button>
                                                }

                                                {item?.purchased === false && item?.available &&
                                                    <button
                                                        className='car_dealership_details_item_btn '
                                                        onClick={() => {
                                                            triggerVibration('heavy')
                                                            openModal(item)
                                                        }}
                                                    >
                                                        {translation?.buy}
                                                    </button>
                                                }

                                                {/*{item.purchased === false &&*/}

                                                {item?.discount ?
                                                    <>
                                                        <div className='car_dealership_details_item_nfs_info_box'>
                                                            <p className='car_dealership_details_item_nfs_info'> {item?.discount} NFS</p>
                                                        </div>
                                                        <div
                                                            className='car_dealership_details_item_nfs_info_box sell-wrapper'>
                                                            <p className='car_dealership_details_item_nfs_info'> {item?.nfsPrice} NFS</p>
                                                        </div>
                                                    </>

                                                    :

                                                    <div className='car_dealership_details_item_nfs_info_box'>
                                                        <p className='car_dealership_details_item_nfs_info'> {item?.nfsPrice} NFS</p>
                                                    </div>
                                                }

                                                {/*}*/}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>

                    <div className="car_dealership_page_footer">
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/'}
                            className="car_dealership_page_footer_back_btn"
                            // onClick={() => {
                            //     navigate(-1)
                            // }}
                        >
                            {/*<img src={require('../../assets/images/play_button2.png')} alt=""/>*/}
                            {translation?.back_to_home}
                        </Link>
                    </div>

                    <CarDealerShipModal
                        isActive={showCarBuyModal}
                        onClose={(error = false) => {
                            if (error) {
                                setShowNoNfsModal(true)
                            }
                            setShowCarBuyModal(false)
                        }}
                        onSuccessClose={() => {
                            fetchDealerCars()
                            setShowCarBuyModal(false)
                        }}
                        carData={carInfo}
                    />


                    <NoNfsModal
                        redirectToWallet={()=>{
                            setShowCarBuyModal(false);
                            setShowNoNfsModal(false);
                            navigate('/wallet')
                        }}
                        isActive={showNoNfsModal}
                        onClose={() => {
                            setShowNoNfsModal(false)
                            // navigate('/')
                        }}
                        onCloseBuyNfs={() => {
                            setShowNoNfsModal(false)
                            navigate('/gusUp')
                        }}
                        // carData={carInfo}
                    />


                    <DontHaveSlotModal
                        isActive={showDontHaveSlotModal}
                        onClose={() => {
                            setShowDontHaveSlotModal(false);
                            // navigate('/')
                        }}
                        onCloseWithRedirect={() => {
                            setShowDontHaveSlotModal(false);
                            navigate('/garage?openSlot=true')
                        }}

                    />
                </div>


            </div>
        </>

    );
}

export default CarDealerShipPage;


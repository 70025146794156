export const P2PIcon = ({}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            fill="none"
        >
            <path
                fill="url(#z)"
                d="M13.385.07c-.032.038-.07.314-.075.61l-.017.536-.416.038c-1.653.15-2.977 1.048-3.745 2.54-.243.486-.297.723-.178.842.097.098.178.087 1.275-.183 1.924-.47 3.005-.72 3.048-.692.022.016.043.265.043.551 0 .568.022.627.2.681.087.027.238-.043.622-.308.275-.19 1.053-.713 1.734-1.173 1.335-.897 1.41-.978 1.168-1.215C16.784 2.053 13.677 0 13.564 0a.257.257 0 0 0-.179.07Z"
            />
            <path
                fill="#fff"
                d="M4.344 5.571c-.697.103-1.42.487-1.864.99-.302.34-.643 1.031-.719 1.458a3.086 3.086 0 0 0 1.097 2.983c.557.454 1.184.67 1.924.67 1.427 0 2.62-.924 2.972-2.297A3.05 3.05 0 0 0 6.528 6.09a3.001 3.001 0 0 0-1.351-.524c-.411-.06-.411-.06-.833.005ZM18.935 5.566c-.957.146-1.865.783-2.275 1.588-.692 1.351-.368 2.908.794 3.853.551.449 1.178.665 1.918.665a3.066 3.066 0 0 0 3.032-3.599c-.276-1.6-1.87-2.75-3.47-2.507ZM2.097 12.796c-.346.124-.67.346-.849.578-.075.097-.389.681-.691 1.297L0 15.79v.524c0 .421.022.567.119.773.173.383.556.761.95.94l.336.156H8.16l.335-.156c.394-.179.778-.557.95-.94.098-.206.12-.357.12-.773V15.8l-.579-1.167c-.637-1.297-.805-1.518-1.334-1.767l-.303-.14-2.486-.011c-2.28-.016-2.507-.005-2.766.081ZM16.687 12.796c-.346.124-.67.346-.854.584-.076.097-.384.686-.692 1.302L14.59 15.8v.52c0 .41.022.561.119.767.173.383.556.761.95.94l.336.156h6.755l.335-.156c.394-.179.778-.557.95-.94.098-.206.12-.352.12-.773v-.519l-.568-1.151c-.632-1.28-.821-1.53-1.34-1.772l-.308-.146-2.486-.011c-2.28-.016-2.507-.005-2.766.081Z"
            />
            <path
                fill="url(#x)"
                d="M10.283 20.248c-2.285 1.534-2.42 1.637-2.42 1.761 0 .065.021.146.043.179.06.086 3.258 2.242 3.431 2.312.276.108.33.011.362-.621l.027-.562.19-.01c1.21-.087 2.198-.498 2.982-1.249a4.813 4.813 0 0 0 1.07-1.491c.232-.503.205-.735-.081-.735-.065 0-.746.156-1.519.345-1.329.335-2.572.627-2.636.627-.017 0-.033-.254-.033-.562 0-.524-.01-.573-.119-.648a.472.472 0 0 0-.162-.087c-.021 0-.53.33-1.135.74Z"
            />
            <defs>
                <linearGradient
                    id="z"
                    x1={8.893}
                    x2={17.152}
                    y1={2.499}
                    y2={2.499}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={7.862}
                    x2={16.124}
                    y1={22.023}
                    y2={22.023}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6AA539" />
                    <stop offset={1} stopColor="#A6E076" />
                </linearGradient>
            </defs>
        </svg>


    )

}

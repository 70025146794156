import React, {useEffect, useState} from 'react';
import {RoundIcon} from "../icons/RoundsIcon";
import {ModalCloseIcon} from "../icons/ModalCloseIcon";
import '../../assets/css/balance_nfs.css'
import {CloseBalanceIcon} from "../icons/CloseBalanceIcon";
import {BalanceMainIcon} from "../icons/BalanceMainIcon";
import {BalancePetrol} from "../icons/BalancePetrol";
import {NfsPrizIcon} from "../icons/NfsPrizIcon";
import {FreePrizIcon} from "../icons/FreePrizIcon";
import {Stage1PrizIcon} from "../icons/Stage1PrizIcon";
import {Stage2PrizIcon} from "../icons/Stage2PrizIcon";
import {Stage3PrizIcon} from "../icons/Stage3PrizIcon";
import {Stage4PrizIcon} from "../icons/Stage4PrizIcon";
import {useTelegram} from "../../TelegramContext";
import useTranslation from "../../hooks/useTranslation";

const SpinModal = ({isActive, onClose, victoriousSpin}) => {
    const { user, triggerVibration} = useTelegram();
    const {translation} = useTranslation();

    const [victoriousSpinData, setVictoriousSpinData] = useState(null);

    useEffect(() => {
        console.log(victoriousSpin, 'victoriousSpinvictoriousSpin')
        setVictoriousSpinData(victoriousSpin);

    }, [isActive, victoriousSpin]);

    if (!isActive) {
        return null
    }

    const RenderIcon = () => {

        if(victoriousSpinData?.award?.includes('NFS')) {
            return (
                <NfsPrizIcon/>
            )
        } else if(victoriousSpinData?.award?.includes('free spin')) {
            return (
                <FreePrizIcon/>
            )
        } else if(victoriousSpinData?.award?.includes('stage 1')) {
            return (
                <Stage1PrizIcon/>
            )
        } else if(victoriousSpinData?.award?.includes('stage 2')) {
            return (
                <Stage2PrizIcon/>
            )
        } else if(victoriousSpinData?.award?.includes('stage 3')) {
            return (
                <Stage3PrizIcon/>
            )
        } else if(victoriousSpinData?.award?.includes('stage 4')) {
            return (
               <Stage4PrizIcon/>
            )
        }

    }

    if (victoriousSpinData?.carInfo?.id) {
        return (
            <div className='buy_package_modal'>
                <div className='buy_package_modal_wrapper auto '>
                    <button
                        className='buy_package_modal_close_btn'
                        onClick={() => {
                            triggerVibration('heavy')
                            onClose()
                        }}
                    >
                        <ModalCloseIcon/>
                    </button>

                    <p className='buy_package_modal_title spin-modal'>
                        {translation?.car_priz_modal}
                    </p>
                    <div className='buy_package_modal_buttons_wrapper spin-modal'>

                        <div className={'spin-modal-priz-icon auto'}>
                            <img src={`${process.env.REACT_APP_IMG_DOMAIN}${victoriousSpinData?.carInfo?.imagePath}`}/>
                        </div>


                        <div className={'spin-modal-priz-text'} style={{textAlign:'center'}}>
                            {victoriousSpinData?.carInfo?.name}
                        </div>


                    </div>
                </div>


            </div>

        );

    }
    else {
        return (
            <div className='buy_package_modal'>
                <div className='buy_package_modal_wrapper'>
                    <button
                        className='buy_package_modal_close_btn'
                        onClick={() => {
                            triggerVibration('heavy')
                            onClose()
                        }}
                    >
                        <ModalCloseIcon/>
                    </button>

                    <p className='buy_package_modal_title spin-modal'>
                        {translation?.text21}
                    </p>
                    <div className='buy_package_modal_buttons_wrapper spin-modal'>

                        <div className={'spin-modal-priz-icon'}>
                            <RenderIcon/>
                        </div>


                        <div className={'spin-modal-priz-text'}>
                            {victoriousSpinData?.award}
                        </div>


                    </div>
                </div>


            </div>

        );
    }

};

export default SpinModal;

export const CloseIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={21}
            fill="none"
        >
            <g filter="url(#a)">
                <circle cx={10.5} cy={10.5} r={10.5} fill="#EBEBEB" fillOpacity={0.4}/>
            </g>
            <path stroke="#F8FFF3" strokeWidth={2} d="m8 8 5 5M13 8l-5 5"/>
            <defs>
                <filter
                    id="a"
                    width={29}
                    height={29}
                    x={-4}
                    y={-4}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation={2}/>
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_490_9072"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_490_9072"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset/>
                    <feGaussianBlur stdDeviation={3.189}/>
                    <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic"/>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
                    <feBlend in2="shape" result="effect2_innerShadow_490_9072"/>
                </filter>
            </defs>
        </svg>
    )
}

export const OffIcon2 = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={19}
            fill="none"
        >
            <path
                fill="#fff"
                d="M10.907.404c-.088.044-1.492 1.027-3.123 2.18L4.818 4.69v9.966l2.966 2.106c1.627 1.153 3.04 2.136 3.14 2.18a.64.64 0 0 0 .869-.408c.03-.11.04-3.067.035-8.962l-.013-8.8-.092-.118c-.215-.29-.531-.386-.816-.25ZM.853 4.716c-.18.057-.412.298-.465.483-.057.21-.061 8.742 0 8.944.057.201.285.434.483.487.087.026.79.043 1.614.043H3.94v-10l-1.478.004c-.816 0-1.54.022-1.61.04Z"
            />
        </svg>
    )

}

import React, {useEffect, useState,} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/game.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseBalanceIcon} from "../../components/icons/CloseBalanceIcon";
import {LockIcon2} from "../../components/icons/LockIcon2";
import {LockIcon3} from "../../components/icons/LockIcon3";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useTranslation from "../../hooks/useTranslation";


function GamePage () {
    const { user, webApp } = useTelegram();
    const { nfsBalance, userData, loading, error, fetchUserData } = useUserData();
    const {translation} = useTranslation();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [gameList, setGameList] = useState([
        {
            id: 1,
            game_img: require('../../assets/images/game_list_img1.png'),
            game_title: 'drift',
            game_info: 'Drift races focus on maintaining control while drifting behind other racers for extra points',
            isLocked: true,
            lock_icon: ''
        },
        {
            id: 2,
            game_img: '',
            game_title: 'Ring race',
            game_info: '',
            isLocked: false,
            lock_icon: <LockIcon2/>
        },
        {
            id: 3,
            game_img: '',
            game_title: 'Off-road',
            game_info: '',
            isLocked: false,
            lock_icon: <LockIcon2/>
        },
    ]);



    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const navigate = useNavigate();


    return (
        <>
            <div className="game_page_wrapper" id='game_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>

                        </div>

                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_logo">
                            <Link to={'/'} className="general_page_item_logo_box">
                                <LogoIcon/>
                            </Link>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="game_page_main_wrapper">
                    <div className="game_page_main_wrapper_child">
                          <button
                              className='game_page_main_wrapper_child_close_btn'
                              onClick={() => {
                                  navigate(-1)
                              }}
                          >
                              <CloseBalanceIcon/>
                          </button>
                           <div className='game_page_main_wrapper_child_title_box'>
                               <p className='game_page_main_wrapper_child_title'>{translation?.game_mode}</p>
                           </div>
                          <Link
                              to={'/missions'}
                              className="game_page_main_wrapper_child_info_box db_"
                          >
                              <h1 className='game_page_main_wrapper_child_info_box_title'>{translation?.missions}</h1>
                              <p className='game_page_main_wrapper_child_info_box_info'>
                                  {translation?.text24}
                              </p>
                          </Link>
                           <div className='game_page_main_wrapper_child_items_wrappr'>
                               {gameList.map((item, index) => {
                                   return (
                                       <div key={index} className='game_page_main_wrapper_child_item_parent'>
                                           {item?.isLocked === true  &&
                                               <Link
                                                   to={'/gameFight'}
                                                   className="game_page_main_wrapper_child_item_unlocked db_"
                                               >
                                                    <div className="game_page_main_wrapper_child_item_unlocked_img">
                                                        <img src={item?.game_img} alt=""/>
                                                    </div>
                                                   <div className="game_page_main_wrapper_child_item_unlocked_title_info_wrapper">
                                                       <p className="game_page_main_wrapper_child_item_unlocked_title">
                                                           {item?.game_title}
                                                       </p>
                                                       <p className="game_page_main_wrapper_child_item_unlocked_info">
                                                           {item?.id == 1 ?

                                                               <>{translation?.text25}</>
                                                            :
                                                               <>{item.game_info}</>


                                                           }
                                                       </p>
                                                   </div>
                                               </Link>
                                           }
                                               {item?.isLocked === false  &&
                                                   <div className="game_page_main_wrapper_child_item_locked">
                                                        <p className="game_page_main_wrapper_child_item_locked_title">
                                                            {item.game_title}
                                                        </p>
                                                       <div className='game_page_main_wrapper_child_item_locked_icon'>
                                                           <LockIcon3/>
                                                       </div>
                                                   </div>
                                               }

                                       </div>

                                   )
                               })}
                           </div>
                           <div className='game_page_main_wrapper_child_footer'>
                               <div className='game_page_main_wrapper_child_footer_img'>
                                   <img src={require('../../assets/images/crown_img.png')} alt=""/>
                               </div>
                               <button className='game_page_main_wrapper_child_play_online_btn'>
                                   <p className='game_page_main_wrapper_child_play_online_btn_text'>{translation?.play_online}</p>
                                   <div className='game_page_main_wrapper_child_play_online_btn_icon'>
                                       <LockIcon3/>
                                   </div>
                               </button>
                           </div>
                    </div>
                </div>

            </div>
        </>

    );
}

export default GamePage;


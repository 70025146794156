export const OffIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={21}
            fill="none"
        >
            <path
                fill="#fff"
                d="M2.79 1.054C1.737 1.33.877 2.184.611 3.22.506 3.63.506 3.8.506 10.632c0 6.8.006 7.003.106 7.4.138.542.367.935.791 1.365.43.425.823.653 1.376.797.403.1.562.106 4.873.09l4.455-.016.313-.122a2.95 2.95 0 0 0 1.104-.69 2.98 2.98 0 0 0 .78-1.168l.139-.356.016-4.938c.01-3.355 0-5-.037-5.128-.245-.823-1.44-.94-1.859-.18-.074.143-.085.636-.111 5.016l-.027 4.858-.127.202a1.394 1.394 0 0 1-.34.345l-.217.143H3.225l-.229-.159a1.29 1.29 0 0 1-.334-.371c-.112-.213-.112-.218-.112-7.088s0-6.876.112-7.088c.058-.117.212-.287.334-.372l.229-.159 4.135-.016c2.931-.01 4.205.005 4.365.048.276.074.589.382.668.658.032.117.059.568.059.998 0 1.01.085 1.243.541 1.476.399.202.993.085 1.259-.25.201-.254.228-.477.207-1.565-.021-.94-.037-1.062-.16-1.386a2.923 2.923 0 0 0-.817-1.205c-.372-.35-.93-.648-1.391-.744C11.9.985 10.419.97 7.445.974c-3.636 0-4.412.016-4.656.08Z"
            />
            <path
                fill="#fff"
                d="M6.904 15.378c-.34.154-.61.579-.61.956.005.398.397.897.769.966.1.016.361.027.573.01.446-.02.67-.137.892-.456.101-.154.128-.255.128-.541 0-.303-.021-.388-.154-.574-.218-.318-.5-.435-1.02-.435-.233.005-.488.037-.578.074Z"
            />
        </svg>
    )

}

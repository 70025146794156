import React from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import GazbuySvg from "../../assets/svg/GazbuySvg";
import useTranslation from "../../hooks/useTranslation";

const GasPurchasedErrorModal = ({isActive, onClose, onCloseBuyGas, onSuccessClose,  carData}) => {

    const { user, webApp, triggerVibration } = useTelegram();
    const {translation} = useTranslation();

    if (!isActive) {
        return null
    }

    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>
                <button
                    className='modal_close_btn'
                    onClick={() => {
                        triggerVibration('heavy')
                        onClose()
                    }}
                >
                    <ModalCloseIcon3/>
                </button>
                <p className='car_dealership_modal_title NoGasModal' style={{maxWidth: 127, marginBottom: 20}}>
                    {translation?.text9} <span>gas</span> {translation?.text10}
                </p>

                <div className='car_dealership_modal_buttons_wrapper'>

                    <button
                        style={{width: '100%'}}
                        onClick={()=>{

                        }}
                        className='car_dealership_modal_apply_button'
                    >

                        <div
                            onClick={() => {
                                triggerVibration('heavy')
                                onClose()
                            }}
                            className='car_dealership_modal_apply_button_child'>
                            <p className='car_dealership_modal_apply_button_text'>{translation?.continue}</p>
                        </div>

                    </button>

                </div>
            </div>

        </div>

    );
};

export default GasPurchasedErrorModal;

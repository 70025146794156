import React, {useEffect, useState,} from 'react';
import {Link, Route, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/daily_reward.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {DailyRewardListIcon} from "../../components/icons/DailyRewardListIcon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useGetDailyVisits from "../../hooks/useGetDailyVisits";
import useGetDailyReward from "../../hooks/useGetDailyReward";
import useTranslation from "../../hooks/useTranslation";
import RewardModal from "../../components/RewardModal";


function DailyRewardPage () {
    const { user, triggerVibration } = useTelegram();
    const {translation} = useTranslation();

    const {nfsBalance, userData, loading, error } = useUserData();
    const { dailyVisitsInfo, loadingDailyVisitsInfo, errorDailyVisitsInfo, fetchGetDailyVisitsInfo } = useGetDailyVisits();
    const { dailyRewardInfo, loadingDailyRewardInfo, errorDailyRewardInfo, fetchGetDailyRewardInfo } = useGetDailyReward();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isShowRewardModal, setIsShowRewardModal] = useState(false);

    const [dailyRewardList, setDailyRewardList] = useState([
        {
            id:1,
            day_number: 'DAY 1',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '1000 NFS',
            received: true,
            final_day: false,
        },
        {
            id: 2,
            day_number: 'DAY 2',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '2000 NFS',
            received: true,
            final_day: false,
        },
        {
            id:3,
            day_number: 'DAY 3',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '3000 NFS',
            received: false,
            final_day: false,
        },
        {
            id:4,
            day_number: 'DAY 4',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '4000 NFS',
            received: false,
            final_day: false,
        },
        {
            id:5,
            day_number: 'DAY 5',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '5000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 6,
            day_number: 'DAY 6',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: ' 6000 NFS',
            received: false,
            final_day: false,
        },
        {
            id:7,
            day_number: 'DAY 7',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '7000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 8,
            day_number: 'DAY 8',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '8000 NFS',
            received: false,
            final_day: false,
        },
        {
            id:9,
            day_number: 'DAY 9',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '9000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 10,
            day_number: 'DAY 10',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '10 000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 11,
            day_number: 'DAY 11',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '11 000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 12,
            day_number: 'DAY 12',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '12 000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 13,
            day_number: 'DAY 13',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '13 000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 14,
            day_number: 'DAY 14',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '14 000 NFS',
            received: false,
            final_day: false,
        },
        {
            id: 15,
            day_number: 'DAY 15',
            day_icon: <DailyRewardListIcon/>,
            day_nfs_info: '15 000 NFS',
            received: false,
            final_day: true,
        },

    ]);



    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        if (dailyRewardInfo) {
            setIsShowRewardModal(true)
            fetchGetDailyVisitsInfo();
        }
        console.log(user, 'user')
    }, [dailyRewardInfo]);

    const navigate = useNavigate();




    const printName = (name) => {
        switch (name) {
            case 'DAY 1':
                return `${translation?.day} 1`
                break
            case 'DAY 2':
                return `${translation?.day} 2`
                break
            case 'DAY 3':
                return `${translation?.day} 3`
                break
            case 'DAY 4':
                return `${translation?.day} 4`
                break

            case 'DAY 5':
                return `${translation?.day} 5`
                break

            case 'DAY 6':
                return `${translation?.day} 6`
                break
            case 'DAY 7':
                return `${translation?.day} 7`
                break
            case 'DAY 8':
                return `${translation?.day} 8`
                break

            case 'DAY 9':
                return `${translation?.day} 9`
                break;
            case 'DAY 10':
                return `${translation?.day} 10`
                break;
            case 'DAY 11':
                return `${translation?.day} 11`
                break;
            case 'DAY 12':
                return `${translation?.day} 12`
                break;
            case 'DAY 13':
                return `${translation?.day} 13`
                break;
            case 'DAY 14':
                return `${translation?.day} 14`
                break;
            case 'DAY 15':
                return `${translation?.day} 15`
                break;

        }
    }

    return (
        <>
            <div className="dailyReward_page_wrapper" id='dailyReward_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>

                            <GazAmount userData={userData}/>

                        </div>

                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item"
                    >
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </Link>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="daily_reward_main_items_wrapper">
                    <div className='daily_reward_item1'>
                        <h1 className='daily_reward_item1_title'>{translation?.daily_reward}</h1>
                        <p className='daily_reward_item1_info'>
                            {translation?.daily_reward_desc}
                        </p>
                    </div>
                    <div className="daily_reward_main_item2">
                        {dailyRewardList.map((item, index) => {
                            let className = 'daily_reward_main_item_child_wrapper';
                            if (index+1 <= dailyVisitsInfo?.days  ) {
                                className = 'daily_reward_main_item_child_wrapper_received';
                            }

                            if (item.final_day) {
                                className = 'daily_reward_main_item_child_wrapper_final';
                            }
                            return (
                                <button className='daily_reward_main_item_child' key={index}>
                                    <div
                                        // className={
                                        // item.received
                                        //     ? 'daily_reward_main_item_child_wrapper_received'
                                        //     : item.final_day
                                        //         ? 'daily_reward_main_item_child_wrapper_final'
                                        //         : 'daily_reward_main_item_child_wrapper'
                                        // }
                                        className={className}
                                    >
                                        <p className='daily_reward_main_item_child_info1 '>
                                            {/*{item.day_number}*/}
                                            {printName(item.day_number)}
                                        </p>
                                        <div className='daily_reward_main_item_child_icon'>
                                            {item.day_icon}
                                        </div>
                                        <p className='daily_reward_main_item_child_info2'>{item.day_nfs_info}</p>
                                    </div>

                                </button>
                            )
                        })}
                    </div>
                    <div
                        className="daily_reward_footer"
                        onClick={() => {
                            triggerVibration('heavy')
                            if (dailyVisitsInfo?.visited_today) {
                                navigate(-1)
                            } else {
                                fetchGetDailyRewardInfo()
                            }
                        }}
                    >
                        {dailyVisitsInfo?.visited_today ?
                            <button className='daily_reward_footer_btn'>
                                {translation?.come_back_tomorrow}
                                {/*<img src={require('../../assets/images/daily_reward_btn_img.png')} alt=""/>*/}
                            </button>
                            :
                            <button className='daily_reward_footer_btn green-reward'>
                                {translation?.get_reward}
                                {/*<img src={require('../../assets/images/daily_reward_btn_img.png')} alt=""/>*/}
                            </button>
                        }


                    </div>
                </div>


            </div>

            <RewardModal
                onClose={()=>{setIsShowRewardModal(false)}}
                isActive={isShowRewardModal}
            />
        </>

    );
}

export default DailyRewardPage;


export const BalancePetrol = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={7}
            height={11}
            fill="none"
        >
            <path
                fill="url(#o)"
                d="M3.658.039c-.039.026-.34.318-.668.646l-.596.598h-1.1c-.983 0-1.107.005-1.163.039-.138.092-.13-.192-.13 4.421 0 3.886.001 4.251.038 4.314.095.156-.16.146 3.461.146 3 0 3.29-.002 3.354-.039.156-.092.146.06.146-2.52V5.3l-.054-.068c-.07-.09-.184-.134-.357-.134-.593 0-1.376-.365-1.852-.865-.33-.348-.513-.698-.58-1.113l-.02-.124.624-.632c.666-.671.683-.693.635-.866-.017-.06-.202-.262-.727-.785L3.967.012 3.85.002c-.09-.007-.136.003-.192.037Zm.063 5.155.095.095v1.723c0 1.713 0 1.725-.051 1.791-.107.136-.1.136-1.225.136s-1.118 0-1.225-.136c-.051-.066-.051-.078-.051-1.791V5.289l.095-.095.095-.095h2.172l.095.095Z"
            />
            <path fill="url(#f)" d="M1.92 7.02v1.263h1.264V5.755H1.92V7.02Z" />
            <defs>
                <linearGradient
                    id="o"
                    x1={0.08}
                    x2={7.06}
                    y1={1.878}
                    y2={1.982}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED5E5E" />
                    <stop offset={0.438} stopColor="#FF7272" />
                    <stop offset={1} stopColor="#F15C5C" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={1.934}
                    x2={3.195}
                    y1={6.221}
                    y2={6.234}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED5E5E" />
                    <stop offset={0.438} stopColor="#FF7272" />
                    <stop offset={1} stopColor="#F15C5C" />
                </linearGradient>
            </defs>
        </svg>
    )
}

import React from 'react';
import {ModalCloseIcon3} from "../icons/ModalCloseIcon3";
import '../../assets/css/car_dealership.css'
import {byDealerCar, getDealerCars} from "../../utils/userApi";
import {stringToBase64} from "../../utils/format";
import {useTelegram} from "../../TelegramContext";
import NoGasSvg from "../../assets/svg/NoGasSvg";
import useTranslation from "../../hooks/useTranslation";

const SliderSubscribeModal = ({onClose}) => {

    const { user, webApp } = useTelegram();
    const {translation} = useTranslation();


    return (
        <div className='car_dealership_modal'>
            <div className='car_dealership_modal_wrapper'>

                <p className='car_dealership_modal_title NoGasModal subscribe-telegram-title'>
                    {translation?.before_you_play}
                </p>

                <p className={'subscribe-telegram-subtitle'} style={{fontSize: 19}}>
                    {translation?.text19} <br/> {translation?.text20}
                </p>

                <img className={'subscribe-telegram-img'} src={require('../../assets/images/slider/tg.png')} alt=""/>

                <div className='car_dealership_modal_buttons_wrapper'>

                    <button
                        style={{width: '100%'}}

                        onClick={()=>{

                        }}
                        className='car_dealership_modal_apply_button'
                    >

                        <div
                            style={{fontStyle: 'italic'}}
                            onClick={() => {
                                onClose()
                            }}
                            className='car_dealership_modal_apply_button_child'>
                            <p className='car_dealership_modal_apply_button_text'>{translation?.subscribe}</p>
                        </div>

                    </button>

                </div>
            </div>

        </div>

    );
};

export default SliderSubscribeModal;

import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/gus_up.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {ParticipantsIcon} from "../../components/icons/ParticipantsIcon";
import {ParticipantsIcon2} from "../../components/icons/Participants2Icon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useNFSPackages from "../../hooks/useNFSPackages";
import {InputNfsIcon} from "../../components/icons/InputNfsIcon";
import {convertNfsToTon, stringToBase64} from "../../utils/format";
import {byDealerCar, byGaz} from "../../utils/userApi";
import LoaderComponent from "../../components/Loader";
import GasPurchasedModal from "../../components/GasPurchasedModal";
import GasPurchasedErrorModal from "../../components/GasPurchasedErrorModal";
import useTranslation from "../../hooks/useTranslation";


function GusUpPage () {
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const { user, webApp, triggerVibration } = useTelegram();
    const {nfsBalance, userData, loading, error, fetchUserData } = useUserData();
    const { NFSPackages, loadingNFSPackages, errorNFSPackages, fetchNFSPackages } = useNFSPackages();

    const [gazValue, setGazValue] = useState('');
    const [convertedNfs, setConvertedNfs] = useState('0');
    const [showGasPurchasedModal, setShowGasPurchasedModal] = useState(false);
    const [showGasPurchasedErrorModal, setShowGasPurchasedErrorModal] = useState(false);

    useEffect(() => {
        // setGazValue(1);
        // setConvertedNfs(parseFloat(NFSPackages?.gasCost * 1));
    }, [NFSPackages]);

    const handleByGas = async () => {
        triggerVibration('heavy')
        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        const {error, data} = await byGaz({initData:init_data, amount: gazValue} );

        console.log(data, '----d-d-d-d-d-')

        if (error) {
            setShowGasPurchasedErrorModal(true)

            console.log('ERRROR!', error);

        } else {
            // navigate('/')
            fetchUserData()
            setShowGasPurchasedModal(true)
            console.log(data, 'data')
        }
    }

    const navigate = useNavigate();


    return (
        <>
            {( loading || loadingNFSPackages ) && (
                <LoaderComponent/>
            )}
            <div className="gus_up_page_wrapper" id='gus_up_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item"
                    >
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </Link>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className='gus_up_page_main_items_wrapper'>
                    <div className="gus_up_page_main_item1">
                        <h1 className='gus_up_page_main_title'>{translation?.gus_up}</h1>
                        <p className='gus_up_page_main_info'>
                            {translation?.gus_up_desc}
                        </p>
                    </div>
                    <div className="gus_up_page_main_item2">
                        <button
                            className='gus_up_page_main_item2_close_btn'
                            onClick={() => {
                                navigate('/')
                                triggerVibration('heavy')
                            }}
                        >
                            <CloseIcon/>
                        </button>
                        <h1 className='gus_up_page_main_item2_title'>{translation?.gus_up_title}</h1>
                        <p className='gus_up_page_main_item2_info'>
                            {NFSPackages?.gasCost} {translation?.gus_up_subtitle}
                        </p>
                        <div className='gus_up_page_details_items_wrapper'>
                            <div className='gus_up_page_details_item'>
                                <div className="gus_up_page_details_item_icon_title_wrapper">
                                    <div className="gus_up_page_details_item_icon">
                                        <PetrolRedIcon/>
                                    </div>
                                    <p className="gus_up_page_details_item_title">gas</p>
                                </div>
                                <div className="gus_up_page_details_item_info_box">
                                    {/*<p className="gus_up_page_details_item_info">1</p>*/}
                                    <input
                                        maxLength={5}
                                        type={'number'}
                                        placeholder={'0'}
                                        value={gazValue}
                                        className='wallet_input_field_info1 input'
                                        onChange={(e) => {

                                            const onlyNumbers = e.target.value.replace(/\D/g, '');
                                            console.log(onlyNumbers, 'onlyNumbers')
                                            setGazValue(onlyNumbers)
                                            setConvertedNfs(parseFloat(NFSPackages?.gasCost * onlyNumbers));

                                        }}
                                    />
                                </div>

                            </div>
                            <div className='gus_up_page_details_item'>
                                <div className="gus_up_page_details_item_icon_title_wrapper">
                                    <div className="gus_up_page_details_item_icon2">
                                        <InputNfsIcon/>

                                        {/*<img src={require('../../assets/images/top_buy_spin_img.png')} alt=""/>*/}
                                    </div>
                                    {/*<p className="gus_up_page_details_item_title">ton</p>*/}
                                </div>
                                <div className="gus_up_page_details_item_info_box">
                                    <p className="gus_up_page_details_item_info2">{convertedNfs}</p>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={(userData?.gasAmount == 5 || gazValue == 0 || gazValue == '')}
                            onClick={handleByGas}
                            className={`gus_up_page_buy_gas_btn ${(userData?.gasAmount == 5 || gazValue == 0 || gazValue == '') && 'disable'}`}
                        >
                            {translation?.buy_gas}
                        </button>
                    </div>
                </div>


            </div>


            <GasPurchasedModal
                isActive={showGasPurchasedModal}
                onClose={() => {
                    setShowGasPurchasedModal(false)
                    setGazValue('')
                    setConvertedNfs('0')
                    // navigate('/')
                }}
                onCloseBuyGas={() => {
                    setShowGasPurchasedModal(false)
                    // navigate('/gusUp')
                }}
                // carData={carInfo}
            />
            <GasPurchasedErrorModal
                isActive={showGasPurchasedErrorModal}
                onClose={() => {
                    setShowGasPurchasedErrorModal(false)

                    setGazValue('')
                    setConvertedNfs('0')
                    // navigate('/')
                }}
                onCloseBuyGas={() => {
                    setShowGasPurchasedErrorModal(false)
                    // navigate('/gusUp')
                }}
                // carData={carInfo}
            />
        </>

    );
}

export default GusUpPage;


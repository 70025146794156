import React from "react";

export const TonKeeper = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="131"
            height="57"
            fill="none"
            viewBox="0 0 131 57"
        >
            <g filter="url(#filter0_i_3338_1641)">
                <rect
                    width="131"
                    height="57"
                    fill="url(#paint0_linear_3338_1641)"
                    rx="7.161"
                ></rect>
            </g>
            <defs>
                <filter
                    id="filter0_i_3338_1641"
                    width="131"
                    height="57"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3.189"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_3338_1641"></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear_3338_1641"
                    x1="0"
                    x2="131"
                    y1="28.5"
                    y2="28.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101D2F"></stop>
                    <stop offset="0.5" stopColor="#0F1620"></stop>
                    <stop offset="1" stopColor="#101D2F"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}


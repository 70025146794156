export const NfsPrizIcon = () => {

    return (
        <svg width="172" height="105" viewBox="0 0 172 105" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1398_3199)">
                <path
                    d="M51.9364 37.9688C29.9618 76.2882 30.2342 75.8342 31.5962 74.9261C32.3227 74.5629 38.4066 70.5675 45.1261 66.1181L57.3846 58.1273L66.4651 42.055L75.6363 25.9826L87.2592 25.7102C99.9718 25.4378 99.427 25.2562 97.3385 30.4321C96.8845 31.4309 100.88 29.2516 107.872 24.6206C114.046 20.5344 119.495 16.8114 119.949 16.3574C120.403 15.9942 119.676 14.6321 118.405 13.3609L116.044 10.9999H91.7086H67.3731L51.9364 37.9688Z"
                    fill="url(#paint0_linear_1398_3199)"/>
                <path
                    d="M104.603 33.9734C86.6237 46.0503 69.6433 57.4917 66.9192 59.3077L61.9249 62.7583H80.2674H98.6098L107.327 47.5032C115.953 32.2481 116.044 32.1573 126.85 22.2596C136.566 13.5424 138.382 11.7263 137.565 11.9987C137.383 11.9987 122.582 21.8964 104.603 33.9734Z"
                    fill="url(#paint1_linear_1398_3199)"/>
            </g>
            <g opacity="0.7">
                <path
                    d="M114.538 56.5586C104.687 73.736 104.809 73.5324 105.42 73.1254C105.745 72.9626 108.473 71.1716 111.485 69.1771L116.98 65.5951L121.05 58.3903L125.161 51.1856L130.372 51.0635C136.07 50.9414 135.826 50.86 134.89 53.1802C134.686 53.6279 136.477 52.651 139.612 50.5751C142.379 48.7434 144.822 47.0745 145.025 46.871C145.229 46.7081 144.903 46.0976 144.333 45.5277L143.275 44.4694H132.366H121.457L114.538 56.5586Z"
                    fill="url(#paint2_linear_1398_3199)"/>
                <path
                    d="M138.146 54.7685C130.087 60.1822 122.475 65.311 121.254 66.1251L119.015 67.6719H127.237H135.46L139.367 60.8335C143.234 53.9951 143.275 53.9544 148.119 49.5176C152.474 45.61 153.288 44.7959 152.922 44.918C152.841 44.918 146.206 49.3548 138.146 54.7685Z"
                    fill="url(#paint3_linear_1398_3199)"/>
            </g>
            <g opacity="0.7">
                <path
                    d="M9.35007 44.6055C-0.50043 61.7828 -0.378316 61.5793 0.232253 61.1723C0.557889 61.0095 3.28509 59.2185 6.29723 57.2239L11.7923 53.6419L15.8628 46.4372L19.974 39.2325L25.1841 39.1104C30.8828 38.9883 30.6386 38.9069 29.7024 41.227C29.4988 41.6748 31.2898 40.6979 34.4241 38.6219C37.192 36.7902 39.6343 35.1214 39.8378 34.9178C40.0413 34.755 39.7157 34.1444 39.1458 33.5746L38.0875 32.5163H27.1787H16.2698L9.35007 44.6055Z"
                    fill="url(#paint4_linear_1398_3199)"/>
                <path
                    d="M32.9587 42.8154C24.8992 48.2291 17.2874 53.3579 16.0663 54.172L13.8276 55.7188H22.0499H30.2722L34.1798 48.8804C38.0468 42.042 38.0875 42.0013 42.9313 37.5645C47.2867 33.6569 48.1008 32.8428 47.7345 32.9649C47.653 32.9649 41.0182 37.4017 32.9587 42.8154Z"
                    fill="url(#paint5_linear_1398_3199)"/>
            </g>
            <defs>
                <filter id="filter0_d_1398_3199" x="25.8188" y="0.959375" width="145.867" height="103.382"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dx="14.3438" dy="9.5625"/>
                    <feGaussianBlur stdDeviation="9.80156"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1398_3199"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1398_3199" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_1398_3199" x1="31.0781" y1="43.0879" x2="120.085" y2="43.0879"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1398_3199" x1="61.9249" y1="37.3658" x2="137.739" y2="37.3658"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1398_3199" x1="105.188" y1="58.8533" x2="145.086" y2="58.8533"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1398_3199" x1="119.015" y1="56.2892" x2="153" y2="56.2892"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint4_linear_1398_3199" x1="0" y1="46.9002" x2="39.8987" y2="46.9002"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
                <linearGradient id="paint5_linear_1398_3199" x1="13.8276" y1="44.3361" x2="47.8125" y2="44.3361"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A6E076"/>
                    <stop offset="0.500415" stop-color="#7EC93F"/>
                    <stop offset="1" stop-color="#A6E076"/>
                </linearGradient>
            </defs>
        </svg>


    )

}

import React from "react";

export const TonSpace = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="131"
            height="57"
            fill="none"
            viewBox="0 0 131 57"
        >
            <g filter="url(#filter0_i_3338_1658)">
                <rect
                    width="131"
                    height="57"
                    fill="url(#paint0_linear_3338_1658)"
                    rx="7.161"
                    transform="matrix(-1 0 0 1 131 0)"
                ></rect>
            </g>
            <defs>
                <filter
                    id="filter0_i_3338_1658"
                    width="131"
                    height="57"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3.189"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_3338_1658"></feBlend>
                </filter>
                <linearGradient
                    id="paint0_linear_3338_1658"
                    x1="65.5"
                    x2="65.5"
                    y1="0"
                    y2="57"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#08C"></stop>
                    <stop offset="1" stopColor="#7BD3FF"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}


import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/gus_up.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {ParticipantsIcon} from "../../components/icons/ParticipantsIcon";
import {ParticipantsIcon2} from "../../components/icons/Participants2Icon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import useNFSPackages from "../../hooks/useNFSPackages";
import {InputNfsIcon} from "../../components/icons/InputNfsIcon";
import {convertNfsToTon, stringToBase64} from "../../utils/format";
import {byDealerCar, byGaz} from "../../utils/userApi";
import LoaderComponent from "../../components/Loader";
import GasPurchasedModal from "../../components/GasPurchasedModal";
import GasPurchasedErrorModal from "../../components/GasPurchasedErrorModal";
import useTranslation from "../../hooks/useTranslation";
import RedNfs from "../../components/icons/RedNfs";
import TonInputIcon from "../../components/icons/TonInputIcon";
import useP2POrders from "../../hooks/useP2POrders";
import P2PSellModal from "../../components/P2PSellModal";
import {useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import TonConnectModal from "../../components/TonConnectModal";


function P2PSalePage () {
    const navigate = useNavigate();

    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();

    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук
    const { user, webApp, triggerVibration } = useTelegram();
    const {nfsBalance, userData, loading, error, fetchUserData } = useUserData();


    const {p2pMainData, allOrders,  loadingAllOrders, errorAllOrders, fetchGetAllOrders,
        sellOrderData, loadingSellOrderData, errorSellOrderData, fetchSellOrder,
        p2PValidate, loadingP2PValidate, errorP2PValidate, fetchP2PValidate,

    } = useP2POrders();

    const [redNfs, setRedNfs] = useState('');
    const [convertedTon, setConvertedTon] = useState('');

    const [showSellModal, setShowSellModal] = useState(false);
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {

        if (sellOrderData) {
            setRedNfs('');
            setConvertedTon('0');
            navigate('/p2p')
        }

    }, [sellOrderData]);

    useEffect(() => {

        if (errorSellOrderData) {

        }

    }, [errorSellOrderData]);


    useEffect(() => {

        if (p2PValidate) {
            if (p2PValidate?.result) {
                setShowBtn(true)
                // fetchSellOrder(convertedTon, redNfs )
                // setShowSellModal(false);
            } else {
                setShowBtn(false);
                // setShowSellModal(false);
            }
            console.log(p2PValidate, 'p2PValidate')
        }

    }, [p2PValidate]);



    return (
        <>
            {( loading ) && (
                <LoaderComponent/>
            )}
            <div className="gus_up_page_wrapper sale-p2p-wrapper" id='gus_up_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item"
                    >
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </Link>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">

                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>

                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>

                        </div>
                    </div>

                </div>

                {userFriendlyAddress ?
                    <>
                        <div className='gus_up_page_main_items_wrapper sale_p2p_page_main_items_wrapper'>

                            <div className="gus_up_page_main_item2 p2p_sell_page_main_item2">

                                <div className={'p2p_sale_main_green_title'}>
                                    {translation?.sell}
                                </div>


                                <div className='gus_up_page_details_items_wrapper'>
                                    <div className='gus_up_page_details_item'>
                                        <div
                                            className="gus_up_page_details_item_icon_title_wrapper p2p_sale_page_details_item_icon_title_wrapper">
                                            <div className="gus_up_page_details_item_icon">
                                                <RedNfs/>
                                            </div>
                                            <p className="gus_up_page_details_item_title p2p_sale_page_details_item_title">
                                                <span>{translation?.you_have_coins}: </span>
                                                <span>{p2pMainData?.p2p_available_nfs_amount} NFS</span>
                                            </p>
                                        </div>
                                        <div className="gus_up_page_details_item_info_box">
                                            <input
                                                maxLength={5}
                                                type={'number'}
                                                placeholder={'0'}
                                                value={redNfs}
                                                className='wallet_input_field_info1 input'
                                                onChange={(e) => {
                                                    // const onlyNumbers = e.target.value.replace(/\D/g, '');
                                                    const onlyNumbers = e.target.value.replace(/[^\d.,]/g, '');

                                                    console.log(onlyNumbers, 'onlyNumbers')
                                                    setRedNfs(onlyNumbers)
                                                    fetchP2PValidate(onlyNumbers, convertedTon)

                                                }}
                                            />

                                        </div>

                                        <p className="gus_up_page_details_item_title p2p_sale_page_details_item_title bottom">
                                            <span>{translation?.minimum_sale_amount}: </span>
                                            <span>{p2pMainData?.min_nfs_amount} NFS</span>
                                        </p>
                                    </div>
                                    <div className='gus_up_page_details_item'>
                                        <div className="gus_up_page_details_item_icon_title_wrapper">
                                            <TonInputIcon/>
                                        </div>
                                        {/*<div className="gus_up_page_details_item_info_box">*/}
                                        {/*    <p className=" p2p_sale_ton">{convertedTon}</p>*/}
                                        {/*</div>*/}

                                        <div className="gus_up_page_details_item_info_box">
                                            <input
                                                maxLength={5}
                                                type={'number'}
                                                placeholder={'0'}
                                                value={convertedTon}
                                                className='wallet_input_field_info1 input p2p_sale_ton_input'
                                                onChange={(e) => {
                                                    // const onlyNumbers = e.target.value.replace(/\D/g, '');
                                                    const onlyNumbers = e.target.value.replace(/[^\d.,]/g, '');

                                                    setConvertedTon(onlyNumbers);
                                                }}
                                            />

                                        </div>
                                        <p className="gus_up_page_details_item_title p2p_sale_page_details_item_title bottom">

                                            <span>{translation?.sales_commission}: </span>
                                            <span>{p2pMainData?.commission}%</span>
                                        </p>
                                    </div>
                                </div>

                                <p className={'current_minimum_price'}>{translation?.current_minimum_price}: 1 NFS ~
                                    0.00001
                                    TON</p>

                                <div className={'current_minimum_price_bottom'}>
                                    <span>{translation?.price_of_you} ton/nfs </span>
                                    <span>{redNfs && convertedTon ? (convertedTon / redNfs).toFixed(6) : 0} ton/nfs</span>
                                </div>

                                <div className={'current_minimum_price_bottom2'} style={{marginTop: 20}}>


                                    <p>{translation?.withdrawal_amount_error} <span>200 000 NFS</span></p>
                                    <p>{translation?.price_per_nfs_error}</p>

                                </div>

                                {errorSellOrderData &&
                                    <div className={'error-msg'}>{errorSellOrderData?.msg}</div>
                                }

                            </div>
                        </div>
                        <div style={{padding: 10}}>
                            <div
                                onClick={() => {
                                    if (redNfs > 0) {
                                        triggerVibration('heavy')

                                        setShowSellModal(true);
                                    }
                                }}

                                className={`custom_btn custom_btn_green ${(redNfs == 0 || !showBtn) && 'disable'}`}
                            >
                                {translation?.sell}
                                {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                            </div>

                            <Link
                                onClick={() => {
                                    triggerVibration('heavy')
                                }}
                                to={'/p2p'}
                                className='custom_btn'
                            >
                                {translation?.go_back}
                                {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                            </Link>

                        </div>
                    </>
                    :

                    <TonConnectModal
                        isActive={true}
                        onClose={() => {

                        }}
                    />
                }


            </div>


            <P2PSellModal
                isActive={showSellModal}
                onClose={() => {
                    setShowSellModal(false);
                }}
                sellClose={() => {
                    console.log(redNfs, convertedTon)
                    // fetchSellOrder(convertedTon, redNfs )
                    fetchSellOrder(convertedTon, redNfs)
                    // fetchP2PValidate(redNfs)
                    setShowSellModal(false);
                }}
            />
        </>

    )
        ;
}

export default P2PSalePage;


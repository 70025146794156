
import {apiRequest} from './apiRequest';
import useGetDailyVisits from "../hooks/useGetDailyVisits";
import useGetProfStat from "../hooks/useGetProfStat";
const BACKEND_URL = process.env.REACT_APP_DOMAIN

export const getAccount = async (body, tgId ) => {

    const url = `${BACKEND_URL}/profile`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};


export const getMissionList = async (body, tgId ) => {

    const url = `${BACKEND_URL}/mission/list`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};


export const clickToMission = async (body, tgId ) => {

    const url = `${BACKEND_URL}/mission/complete`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};


export const getProfStat = async (body, tgId ) => {

    const url = `${BACKEND_URL}/profile/stat`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};


export const getFindRival = async (body, tgId ) => {

    const url = `${BACKEND_URL}/race/find`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getPlayResult = async (body, tgId ) => {

    const url = `${BACKEND_URL}/race/race`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getDailyVisits = async (body, tgId ) => {

    const url = `${BACKEND_URL}/daily_visit/days`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const getDailyReward = async (body, tgId ) => {

    const url = `${BACKEND_URL}/daily_visit`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const getFriends = async (body, tgId ) => {

    const url = `${BACKEND_URL}/frens/info`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getSettings = async (body, tgId ) => {

    const url = `${BACKEND_URL}/settings`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const updateSettings = async (body, tgId ) => {

    const url = `${BACKEND_URL}/settings`;
    const method = 'PATCH';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getSpinList = async (body, tgId ) => {

    const url = `${BACKEND_URL}/spin/awards`;
    const method = 'GET';
    const response = await apiRequest(url, method, null);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const getVictoriousSpin = async (body, tgId ) => {

    const url = `${BACKEND_URL}/spin`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const byDealerCar = async (body ) => {

    const url = `${BACKEND_URL}/car/buy`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const byGaz = async (body ) => {

    const url = `${BACKEND_URL}/buy/gas`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getNFSPackages = async (body, tgId ) => {

    const url = `${BACKEND_URL}/buy/costs`;
    const method = 'GET';
    const response = await apiRequest(url, method, null);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getDealerCars = async (body, tgId ) => {

    const url = `${BACKEND_URL}/car/list`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const updateTonWallet = async (body, tgId ) => {

    const url = `${BACKEND_URL}/wallet/address`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const buyNFS = async (body) => {

    const url = `${BACKEND_URL}/buy/nfs`;
    const method = 'POST';
    const response = await apiRequest(url, method, body);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getLanguages = async (body, tgId ) => {

    const url = `${BACKEND_URL}/languages/`;
    const method = 'GET';
    const response = await apiRequest(url, method, null, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getNFSTONCurrencies = async (body, tgId ) => {

    const url = `${BACKEND_URL}/wallet`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};


export const apiSelectCar = async (body, tgId ) => {

    const url = `${BACKEND_URL}/garage/car`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const buyPackage = async (body, tgId ) => {

    const url = `${BACKEND_URL}/buy/package`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const getP2pOrdersAll = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/orders/all `;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};

export const getP2pOrdersMy = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/orders/my `;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};

export const deleteP2pOrder = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/order`;
    const method = 'DELETE';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};

export const getTransactionData = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/transaction`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};

export const unblockTransaction = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/transaction`;
    const method = 'DELETE';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};

export const getOrderInfo = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/order/info `;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};

export const sellOrderApi = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/order`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};


export const getP2pHistory = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/history`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};
export const p2pValidate = async (body, tgId ) => {

    const url = `${BACKEND_URL}/p2p/validate`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }
    return {error: null, data: response.data};
};


export const buySpin = async (body, tgId ) => {

    const url = `${BACKEND_URL}/buy/spin`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const garageUpstageApi = async (body, tgId ) => {

    const url = `${BACKEND_URL}/garage/upstage`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const bySpace = async (body, tgId ) => {

    const url = `${BACKEND_URL}/buy/slot`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const upStage = async (body, tgId ) => {

    const url = `${BACKEND_URL}/garage/upstage`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const getGarageInfo = async (body, tgId ) => {

    const url = `${BACKEND_URL}/garage/info`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);
    const statusCode = response?.status;

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};
export const getExpoList = async (body, tgId ) => {
    const url = `${BACKEND_URL}/expo/list`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);
    const statusCode = response?.status;

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};
};

export const addCarToExpo = async (body, tgId ) => {
    const url = `${BACKEND_URL}/expo/start`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);
    const statusCode = response?.status;

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};
};

export const removeCarFromExpo = async (body, tgId ) => {
    const url = `${BACKEND_URL}/expo/end`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);
    const statusCode = response?.status;

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};
};


export const getChatLink = async (body, tgId ) => {

    const url = `${BACKEND_URL}/faq/chat`;
    const method = 'GET';
    const response = await apiRequest(url, method, null, tgId);
    const statusCode = response?.status;

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};

export const sendPresentation = async (body, tgId ) => {

    const url = `${BACKEND_URL}/faq/send`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);
    const statusCode = response?.status;

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};




export const getRefLink = async (body, tgId ) => {

    const url = `${BACKEND_URL}/frens/info`;
    const method = 'POST';
    const response = await apiRequest(url, method, body, tgId);

    if (response.error) {
        return {error: response.error.response, data: null};
    }

    return {error: null, data: response.data};

};



import {useState, useEffect, useRef} from 'react';
import {getAccount, getNFSTONCurrencies} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {stringToBase64} from "../utils/format";

const useNFSTONCurrencies = () => {

    const { webApp  } = useTelegram();
    const [NFSTONCurrencies, setNFSTONCurrencies] = useState(null);
    const [loadingNFSTONCurrencies, setLoadingNFSTONCurrencies] = useState(true);
    const [errorNFSTONCurrencies, setErrorNFSTONCurrencies] = useState(null);
    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchNFSTONCurrencies();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchNFSTONCurrencies()
            }
        }


    }, [webApp?.initData]);

    const fetchNFSTONCurrencies = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const {error, data} = await getNFSTONCurrencies({initData:init_data} );

        if (error) {
            console.log('ERRROR!', error);
            setErrorNFSTONCurrencies(error);
            setLoadingNFSTONCurrencies(false);

        } else {

            setNFSTONCurrencies(data);
            setLoadingNFSTONCurrencies(false);

            console.log(data, 'data')

        }

    };


    return { NFSTONCurrencies, loadingNFSTONCurrencies, errorNFSTONCurrencies, fetchNFSTONCurrencies };
};

export default useNFSTONCurrencies;

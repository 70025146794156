export async function apiRequest(url, method, body) {

    const headers = new Headers();
    headers.append("accept", "application/json");
    headers.append("Content-Type", "application/json");
    // headers.append("X-Auth-Token",  `${tgId}`);

    const options = {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
        redirect: 'follow',
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();

        if (!response.ok) {
            return {
                error: {
                    response: data
                }
            };
        }
        return {
            data
        };
    } catch (error) {
        return {
            error: {
                message: error.message || "Something went wrong",
                response: null // нет ответа от API
            }
        };
    }
}

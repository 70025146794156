export const CarsListIcon = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            fill="none"
        >
            <path fill="#fff" d="M3.899.663v.663h4.366V0H3.9v.663Z" />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M0 17.544V1.092H3.119v.339c0 .397.047.51.234.604.125.066.339.07 2.729.07s2.604-.004 2.729-.07c.187-.093.234-.207.234-.604v-.34H12.164v4.11a4.505 4.505 0 0 0-2.066 1.354.535.535 0 0 0-.082.069.383.383 0 0 0-.1.154A4.48 4.48 0 0 0 9 9.531a.395.395 0 0 0-.123-.15c-.109-.083-.12-.083-3.7-.09h-.003c-1.977-.004-3.646.007-3.708.027-.238.074-.347.292-.257.51.014.035.023.064.038.088.095.151.444.143 3.86.143 3.413 0 3.765.008 3.86-.144.016-.025.024-.055.039-.09a.385.385 0 0 0 .005-.012 4.503 4.503 0 0 0 3.153 3.985v3.746H0ZM9.033 4.121c.02-.125.004-.16-.117-.28l-.136-.137H1.435l-.137.136c-.12.121-.136.156-.117.28.016.079.07.188.125.243l.098.101H8.81l.097-.101a.552.552 0 0 0 .125-.242Zm1.942-.031c0-.133-.024-.18-.129-.273-.113-.102-.152-.113-.39-.113-.397.004-.553.117-.553.41 0 .264.25.4.666.362.265-.028.406-.16.406-.386Zm-1.97 2.932c.06-.149.028-.293-.097-.418l-.093-.093H1.4l-.094.093c-.125.125-.156.27-.097.418.015.037.023.068.038.095.089.146.437.135 3.86.135s3.772.01 3.86-.135c.016-.027.023-.058.039-.095Zm-.089 5.719a.358.358 0 0 0-.02-.542l-.124-.113h-7.33l-.124.113c-.172.152-.18.382-.02.538l.106.11H8.81l.105-.106Zm.09 2.663c.062-.152.023-.297-.105-.418l-.102-.093H1.415l-.101.093c-.129.121-.168.266-.105.418.015.037.023.069.038.095.089.146.437.135 3.86.135s3.772.01 3.86-.135c.016-.027.023-.058.039-.095Zm1.95 0c.062-.152.023-.297-.106-.418-.09-.081-.136-.093-.41-.093-.292 0-.315.008-.424.113-.148.152-.152.34-.012.507.097.117.113.12.421.12.355 0 .452-.042.53-.23Z"
                clipRule="evenodd"
            />
            <path
                fill="#fff"
                d="M13.45 6.007a12.08 12.08 0 0 1-.255.025c-.425.039-1.212.2-1.366.28-.085.043-.25.22-.356.38-.14.212-.262.47-.352.742l-.081.244-.073-.103-.074-.104-.188-.001c-.644-.002-.916.357-.442.582l.162.073c.038.013.036.017-.044.066-.154.096-.277.344-.335.675-.064.371-.06 1.023.009 1.787l.049.58c.018.216.04.276.12.324.042.024.125.027.675.027h.63l.04-.042c.04-.038.043-.053.043-.212v-.17h4.775v.16c0 .147.003.164.044.211l.044.053h.627c.548 0 .631-.003.673-.027.08-.048.102-.108.12-.324l.05-.58c.132-1.453.029-2.23-.325-2.459-.082-.052-.083-.056-.044-.07.29-.113.43-.241.396-.372-.043-.167-.319-.282-.677-.282l-.188.001-.075.104-.074.105-.073-.227a3.4 3.4 0 0 0-.357-.76c-.109-.161-.273-.338-.358-.382-.142-.072-.782-.211-1.273-.274-.2-.025-1.309-.049-1.448-.03Zm1.413.387c.337.038.537.068.86.13l.275.055.093.139c.135.205.286.482.387.72.124.284.124.289.075.279-.06-.014-.904-.075-1.343-.098-.5-.026-1.939-.024-2.421.001a37.37 37.37 0 0 0-1.22.085.984.984 0 0 1-.141.008c-.01-.008.062-.203.147-.387.095-.212.218-.434.335-.61l.092-.137.274-.054a9.724 9.724 0 0 1 1.258-.163c.2-.015 1.097.007 1.33.032ZM11.13 9.25c.455.06.535.075.604.112.16.086.186.245.076.43l-.054.091-.454.005c-.392.004-.46 0-.508-.023a.745.745 0 0 1-.35-.429c-.026-.108.007-.194.088-.23a.834.834 0 0 1 .064-.025c.005 0 .244.03.534.07Zm6.334-.044c.162.068.122.318-.085.527-.16.16-.16.16-.682.155l-.454-.005-.054-.091c-.113-.19-.083-.347.085-.434.076-.037.157-.054.579-.11.587-.076.538-.072.61-.042Zm-5.505 1.016a.134.134 0 0 1 .026.16c-.032.06-.109.069-.583.069-.492 0-.521-.005-.567-.087-.02-.037-.022-.052-.002-.1.012-.028.04-.06.058-.067.02-.009.259-.016.532-.016l.496-.001.04.042Zm5.146-.024c.06.03.091.11.06.166-.046.082-.075.087-.568.087-.474 0-.55-.009-.582-.068a.134.134 0 0 1 .025-.161l.04-.042h.492c.328 0 .504.006.533.018Z"
            />
        </svg>

    )

}

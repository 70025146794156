import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/rating.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {ParticipantsIcon} from "../../components/icons/ParticipantsIcon";
import {ParticipantsIcon2} from "../../components/icons/Participants2Icon";
import {CloseIcon} from "../../components/icons/CloseIcon";
import {RatingGrayIcon} from "../../components/icons/RatingGrayIcon";
import {RatingGray2Icon} from "../../components/icons/RatingGray2Icon";
import {PersonIcon} from "../../components/icons/PersonIcon";


function RatingPage () {
    const { user } = useTelegram();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [ratingList, setRatingList] = useState([
        {
            id: 1,
            gray_quantity: 1,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 2,
            gray_quantity: 2,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 3,
            gray_quantity: 3,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 4,
            gray_quantity: 4,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 5,
            gray_quantity: 5,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 6,
            gray_quantity: 6,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },

        {
            id: 7,
            gray_quantity: 7,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 8,
            gray_quantity: 8,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 9,
            gray_quantity: 9,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
        {
            id: 10,
            gray_quantity: 10,
            user_image: require('../../assets/images/rating_img2.png'),
            user_name: 'Sergey_Krasav4ik',
            participants_quantity: '12000',
            reward_quantity: '890 000',
        },
    ]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const navigate = useNavigate();
    const redirectToWalletPage = () => {
        window.location.href = '/wallet';
    };
    const redirectToGusUpPage = () => {
        window.location.href = '/gusUp';
    };
    const redirectToSettingsPage = () => {
        window.location.href = '/settings';
    };
    const redirectToBalancePage = () => {
        window.location.href = '/balanceNfs';
    };

    return (
        <>
            <div className="rating_page_wrapper" id='rating_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <button
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                                onClick={() => {
                                    redirectToSettingsPage()
                                }}
                            >
                                <SettingsIcon/>
                            </button>
                            <button
                                className="general_page_item_top_child_icon2_wrapper"
                                onClick={() => {
                                    redirectToGusUpPage()
                                }}
                            >
                                <div className='general_page_item_top_child_icon2_wrapper_childs'>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>

                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>

                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>
                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolRedIcon/>
                                        </div>
                                    </div>
                                    <div className='general_page_item_top_child_icon2_child1 general_page_item_top_child_icon2_child2'>
                                        <div className="general_page_item_top_child_icon2_child1_icon">
                                            <PetrolWhiteIcon/>
                                        </div>
                                        <button className='general_page_item_top_child_replenish_btn'>
                                            <ReplenishIcon/>
                                        </button>
                                    </div>

                                </div>
                            </button>

                        </div>

                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <button
                                className="general_page_item_top_child_title_icon_wrapper"
                                onClick={() => {
                                    redirectToBalancePage()
                                }}
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>1 000 000</p>
                            </button>
                            <button
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                                onClick={() => {
                                    redirectToWalletPage()
                                }}
                            >
                                <WalletIcon/>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="rating_page_items_wrapper">
                    <div className="rating_page_item1">
                        <button
                            className='rating_page_close_btn'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            <CloseIcon/>
                        </button>
                        <div className='rating_page_img_name_wrapper'>
                            <div className='rating_page_img_box'>
                                <img src={require('../../assets/images/friends_img1.png')} alt=""/>
                            </div>
                            <h1 className='rating_page_img_name'>sergey_krasav4ik</h1>
                        </div>
                        <div className='rating_page_participants_rating_info_main_wrapper'>
                            <div className='rating_page_participants_info_icon_wrapper'>
                                <p className='rating_page_participants_info_title'>Participants:</p>
                                <div className='rating_page_participants_info_icon_wrapper_child'>
                                    <ParticipantsIcon/>
                                    <p className='rating_page_participants_info_icon_wrapper_info'>430</p>
                                </div>
                            </div>
                            <div className='rating_page_rating_info_wrapper'>
                                <p className='rating_page_rating_info_wrapper_title'>Rating:</p>
                                <div className='rating_page_rating_info_wrapper_icon_text_box'>
                                    <RatingGrayIcon/>
                                    <p className='rating_page_rating_info_wrapper_text'>8</p>
                                </div>
                            </div>
                        </div>

                        <div className='rating_page_reward_info_wrapper'>
                            <p className='rating_page_reward_info_wrapper_title'>Reward:</p>
                            <div className='rating_page_reward_info_wrapper2'>
                                <div className='rating_page_reward_info_img'>
                                    <img src={require('../../assets/images/reward_img.png')} alt=""/>
                                </div>
                                <p className='rating_page_reward_info'>890 000</p>
                            </div>
                        </div>

                    </div>
                    <div className="rating_page_item2">
                        <div className='rating_page_partners_details_items_wrapper'>
                            {ratingList.map((item, index) => {
                                return (
                                  <div className='rating_page_partners_details_item' key={index}>
                                        <div className='rating_page_partners_details_item1'>
                                            <RatingGray2Icon/>
                                            <p className='rating_page_partners_details_item1_title'>{item.gray_quantity}</p>
                                        </div>
                                        <div className='rating_page_partners_details_item2'>
                                            <div className="rating_page_partners_details_item2_img_box">
                                                <img src={item.user_image} alt=""/>
                                            </div>
                                            <div className="rating_page_partners_details_item2_info_box">
                                                <p className='rating_page_partners_details_item2_user_name'>{item.user_name}</p>
                                                <div className='rating_page_partners_details_item2_participants_rewards_info_wrapper'>
                                                    <div className="rating_page_partners_details_item2_participants_info_wrapper">
                                                        <PersonIcon/>
                                                        <p className="rating_page_partners_details_item2_participants_info_wrapper_title">
                                                            {item.participants_quantity}
                                                        </p>
                                                    </div>
                                                    <div className="rating_page_partners_details_item2_rewards_info_wrapper">
                                                        <div className='rating_page_partners_details_item2_rewards_info_wrapper_img'>
                                                            <img src={require('../../assets/images/reward_img.png')} alt=""/>
                                                        </div>
                                                        <p className='rating_page_partners_details_item2_rewards_info_wrapper_title'>
                                                            {item.reward_quantity}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                                )
                            })}
                        </div>

                    </div>
                </div>


            </div>
        </>

    );
}

export default RatingPage;


export const WalletIcon = ({}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={12}
            fill="none"
        >
            <path
                fill="#fff"
                d="M.919.039a1.213 1.213 0 0 0-.855.785L0 1v9.854l.064.176c.13.353.377.606.718.746l.177.07 5.858.01c3.977.005 5.906-.004 6.007-.025a1.33 1.33 0 0 0 .825-.6c.149-.25.167-.405.167-1.384v-.904l-2.015-.01c-2.26-.012-2.151-.002-2.641-.243a2.138 2.138 0 0 1-.907-.907 1.903 1.903 0 0 1-.228-.958c0-.588.173-1.004.602-1.434.226-.228.32-.3.533-.404.493-.238.38-.229 2.641-.24l2.015-.01V1.82H7.73c-6.753 0-6.227.015-6.318-.198-.055-.134-.055-.24.006-.353.097-.192-.322-.18 6.327-.18a797.4 797.4 0 0 0 6.07-.012c0-.006-.026-.1-.06-.21a1.303 1.303 0 0 0-.73-.79l-.168-.068L6.97.002c-5.015-.006-5.913 0-6.05.037Z"
            />
            <path
                fill="#fff"
                d="M9.677 5.52c-.432.115-.758.437-.913.897-.055.161-.064.246-.055.499.01.262.025.332.104.502.115.244.316.472.532.6.326.194.296.191 2.773.191h2.246V5.471l-2.259.003c-1.89 0-2.288.009-2.428.045Zm.612.903c.134.088.21.243.21.432 0 .274-.189.472-.45.472a.474.474 0 0 1-.427-.7c.143-.268.438-.356.667-.204Z"
            />
        </svg>

    )

}
